<template>
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2616.8 331.8"
    style="enable-background:new 0 0 2616.8 331.8;"
    xml:space="preserve"
  >
    <g id="Calque_2">
      <g id="CITY">
        <path
          class="st0"
          d="M74,280.1c0-0.6,0.4-1,1-1h13.3v-24.4c0-0.6,0.4-1,1-1h0.1l47,5.1c0.5,0.1,0.9,0.5,0.9,1v3.2h5.9v-9.8
			c0-0.6,0.4-1,1-1h21.5v-6.6c0-0.6,0.4-1,1-1h9.3V186c0-0.6,0.4-1,1-1l0,0c0.6,0,1,0.4,1,1v58.6h24v-15.2c0-0.6,0.4-1,1-1
			s1,0.4,1,1v15.2h19.2c0.6,0,1,0.4,1,1v83.1c0,0.6-0.4,1-1,1h-40c-0.1,0-0.2,0-0.2,0h-17c-0.1,0-0.2,0-0.2,0h-3.9
			c-0.1,0-0.2,0-0.2,0H89.3c-0.1,0-0.2,0-0.2,0h-6.6c-0.1,0-0.2,0-0.2,0H75c-0.6,0-1-0.4-1-1V280.1z"
        />
        <path
          class="st0"
          d="M391.2,280.5c0-0.6-0.4-1-1-1h-13.3v-24.4c0-0.6-0.4-1-1-1h-0.1l-47,5.1c-0.5,0.1-0.9,0.5-0.9,1v3.2h-6v-9.8
			c0-0.6-0.4-1-1-1h-21.5V246c0-0.6-0.4-1-1-1h-9.3v-29c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1v29h-8.2v-15.2c0-0.6-0.4-1-1-1
			s-1,0.4-1,1V245h-4.1c-0.6,0-1,0.4-1,1v83c0,0.6,0.4,1,1,1h9.2c0.1,0,0.2,0,0.2,0h16.9c0.1,0,0.2,0,0.2,0h3.9c0.1,0,0.2,0,0.2,0
			h72.4c0.1,0,0.2,0,0.2,0h6.6c0.1,0,0.2,0,0.2,0h7.2c0.6,0,1-0.4,1-1L391.2,280.5L391.2,280.5z"
        />
        <path
          class="st0"
          d="M519.3,280.5c0-0.6-0.4-1-1-1H505v-24.4c0-0.6-0.4-1-1-1h-0.1l-47,5.1c-0.5,0.1-0.9,0.5-0.9,1v3.2h-6v-9.8
			c0-0.6-0.4-1-1-1h-21.5V246c0-0.6-0.4-1-1-1h-9.2v-29c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1v29H407v-15.2c0-0.6-0.4-1-1-1
			s-1,0.4-1,1V245h-4c-0.6,0-1,0.4-1,1v83c0,0.6,0.4,1,1,1h9.2c0.1,0,0.2,0,0.2,0h16.9c0.1,0,0.2,0,0.2,0h3.9c0.1,0,0.2,0,0.2,0H504
			c0.1,0,0.2,0,0.2,0h6.6c0.1,0,0.2,0,0.2,0h7.2c0.6,0,1-0.4,1-1L519.3,280.5L519.3,280.5z"
        />
        <path
          class="st0"
          d="M489.9,254.5c0-0.8-0.7-1.5-1.5-1.5h-20.2v-37.2c0-0.8-0.7-1.5-1.5-1.5c-0.1,0-0.1,0-0.2,0l-71.7,7.7
			c-0.8,0.1-1.4,0.7-1.4,1.5v4.8h-9.1v-14.9c0-0.8-0.7-1.5-1.5-1.5H350v-10c0-0.8-0.7-1.5-1.5-1.5h-14.1v-44.1
			c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v44.1h-12.6v-23.1c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v23.1
			h-6.2c-0.8,0-1.5,0.7-1.5,1.5v126.7c0,0.8,0.7,1.5,1.5,1.5h14c0.1,0,0.2,0,0.4,0h25.7c0.1,0,0.2,0,0.4,0h5.9c0.1,0,0.2,0,0.4,0
			h110.3c0.1,0,0.2,0,0.4,0h10.1c0.1,0,0.2,0,0.4,0h10.9c0.8,0,1.5-0.7,1.5-1.5L489.9,254.5L489.9,254.5z"
        />
        <rect x="431.1" y="237.7" class="st1" width="21.2" height="15.2" />
        <path
          class="st2"
          d="M409.5,236.5v-5.2h-51.8v5.2h51.6H409.5z M412.5,229.8V253H428v-16.8c0-0.8,0.7-1.5,1.5-1.5h24.2
			c0.8,0,1.5,0.7,1.5,1.5V253h9.8v-35.6l-68.7,7.4v3.5H411C411.8,228.3,412.5,228.9,412.5,229.8
			C412.6,229.8,412.6,229.8,412.5,229.8L412.5,229.8z M347,203.3h-36V327h4.7V213.4c0-0.8,0.7-1.5,1.5-1.5H347V203.3z M409.5,244.8
			v-5.2h-0.2h-51.6v5.2h51.6H409.5z M381.3,214.9h-23.6v13.4h23.5L381.3,214.9z M357.8,277.6h35.6v-5.2h-35.7L357.8,277.6z
			 M357.8,285.9h35.6v-5.2h-35.7L357.8,285.9z M357.8,269.4h35.6v-5.2h-35.7L357.8,269.4z M357.8,261.2h35.6V256h-35.7L357.8,261.2z
			 M477.5,261.2h9.4V256h-90.5v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-24.2v5.2h24.2
			c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-24.2v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-24.2v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0
			H396v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-24.2v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-24.2v5.2H420c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0
			h-24.2v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-24.2v5.5H486V322h-9.4
			c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h9.4v-5.2h-9.4c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h9.4v-5.2h-9.4c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0
			h9.4v-5.2h-9.4c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h9.4v-5.2H477c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h9.4v-5.4h-9.4c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0
			h9.4v-5.2h-9.4c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h9.4v-5.2h-9.4c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0L477.5,261.2z M466.6,321.8h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C466.9,321.9,466.8,321.9,466.6,321.8L466.6,321.8z
			 M466.6,313.6h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7C466.9,313.6,466.8,313.6,466.6,313.6L466.6,313.6z M466.6,305.4h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C466.9,305.4,466.8,305.4,466.6,305.4L466.6,305.4z M466.6,297.1h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C466.9,297.2,466.8,297.2,466.6,297.1L466.6,297.1z
			 M466.6,288.9h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7C466.9,288.9,466.8,288.9,466.6,288.9L466.6,288.9z M466.6,280.7h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C466.9,280.7,466.8,280.7,466.6,280.7L466.6,280.7z M466.6,272.5h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C466.9,272.5,466.8,272.5,466.6,272.5L466.6,272.5z
			 M466.6,264.2h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7C466.9,264.3,466.8,264.3,466.6,264.2L466.6,264.2z M409.5,247.8h-0.2h-51.6v5.2h51.8L409.5,247.8z
			 M357.8,318.8h35.6v-5.2h-35.7L357.8,318.8z M357.8,326.9h35.6v-5.1h-35.7L357.8,326.9z M318.8,326.9h36v-112h-36V326.9z
			 M323.9,220h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7C323.5,220,323.7,220,323.9,220L323.9,220z M323.9,228.2h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			C323.5,228.2,323.7,228.2,323.9,228.2L323.9,228.2z M323.9,236.5h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7C323.5,236.4,323.7,236.4,323.9,236.5L323.9,236.5z
			 M323.9,244.7h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7C323.5,244.7,323.7,244.7,323.9,244.7L323.9,244.7z M323.9,252.9h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			C323.5,252.9,323.7,252.9,323.9,252.9L323.9,252.9z M323.9,261.2h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7C323.5,261.1,323.7,261.1,323.9,261.2L323.9,261.2z
			 M323.9,269.4h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7C323.5,269.4,323.7,269.4,323.9,269.4L323.9,269.4z M323.9,277.6h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			C323.5,277.6,323.7,277.6,323.9,277.6L323.9,277.6z M323.9,285.8h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7C323.5,285.8,323.7,285.8,323.9,285.8L323.9,285.8z
			 M323.9,294.1h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7C323.5,294.1,323.7,294.1,323.9,294.1L323.9,294.1z M323.9,302.3h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			C323.5,302.3,323.7,302.3,323.9,302.3L323.9,302.3z M323.9,310.5h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7C323.5,310.5,323.7,310.5,323.9,310.5L323.9,310.5z
			 M323.9,318.8h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-25.8c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7C323.5,318.7,323.7,318.7,323.9,318.8L323.9,318.8z M357.8,310.5h35.6v-5.2h-35.7L357.8,310.5z
			 M357.8,302.3h35.6v-5.2h-35.7L357.8,302.3z M357.8,294.1h35.6v-5.2h-35.7L357.8,294.1z"
        />
        <path
          class="st0"
          d="M323.9,272.5h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C323.5,272.5,323.7,272.5,323.9,272.5z M323.9,264.2h25.7
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C323.5,264.3,323.7,264.3,323.9,264.2z M323.9,256h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0
			h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C323.5,256,323.7,256,323.9,256z M323.9,247.8h25.7
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C323.5,247.8,323.7,247.8,323.9,247.8z M323.9,280.7h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0
			h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C323.5,280.7,323.7,280.7,323.9,280.7z M323.9,321.8h25.7
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C323.5,321.9,323.7,321.9,323.9,321.8z M323.9,313.6h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0
			h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C323.5,313.6,323.7,313.6,323.9,313.6z M323.9,239.6h25.7
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C323.5,239.6,323.7,239.6,323.9,239.6z M323.9,305.4h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0
			h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C323.5,305.4,323.7,305.4,323.9,305.4z M323.9,297.2h25.7
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C323.5,297.2,323.7,297.2,323.9,297.2z M323.9,288.9h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0
			h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C323.5,289,323.7,289,323.9,288.9z M440.9,305.4h25.7
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C440.6,305.4,440.7,305.4,440.9,305.4L440.9,305.4z M323.9,231.3h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C323.5,231.4,323.7,231.4,323.9,231.3z M440.9,313.6
			h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7C440.6,313.6,440.7,313.6,440.9,313.6L440.9,313.6z M440.9,321.8h25.7c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C440.6,321.9,440.7,321.9,440.9,321.8L440.9,321.8z M440.9,288.9h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C440.6,289,440.7,289,440.9,288.9L440.9,288.9z
			 M440.9,297.2h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7C440.6,297.2,440.7,297.2,440.9,297.2L440.9,297.2z M440.9,280.7h25.7c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C440.6,280.7,440.7,280.7,440.9,280.7L440.9,280.7z M489.9,254.5c0-0.8-0.7-1.5-1.5-1.5h-20.2v-37.2c0-0.8-0.7-1.5-1.5-1.5
			c-0.1,0-0.1,0-0.2,0l-71.7,7.7c-0.8,0.1-1.4,0.7-1.4,1.5v4.8h-9.1v-14.9c0-0.8-0.7-1.5-1.5-1.5H350v-10c0-0.8-0.7-1.5-1.5-1.5
			h-14.1v-44.1c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v44.1h-12.6v-23.1c-0.1-0.8-0.9-1.4-1.7-1.3
			c-0.6,0.1-1.1,0.6-1.3,1.3v23.1h-6.2c-0.8,0-1.5,0.7-1.5,1.5v126.7c0,0.8,0.7,1.5,1.5,1.5h14c0.1,0,0.2,0,0.4,0h25.7
			c0.1,0,0.2,0,0.4,0h5.9c0.1,0,0.2,0,0.4,0h110.3c0.1,0,0.2,0,0.4,0h10.1c0.1,0,0.2,0,0.4,0h10.9c0.8,0,1.5-0.7,1.5-1.5
			L489.9,254.5L489.9,254.5z M315.7,213.4V327H311V203.3h36v8.5h-29.8C316.4,211.8,315.7,212.5,315.7,213.4
			C315.7,213.3,315.7,213.3,315.7,213.4z M354.7,327h-36V214.9h36V327z M396.4,224.8l68.7-7.4V253h-9.8v-16.8c0-0.8-0.7-1.5-1.5-1.5
			h-24.2c-0.8,0-1.5,0.7-1.5,1.5V253h-15.5v-23.2c0-0.8-0.7-1.5-1.5-1.5h-14.6L396.4,224.8L396.4,224.8z M452.2,237.8V253H431v-15.3
			L452.2,237.8z M357.8,214.9h23.5v13.4h-23.5V214.9z M357.8,231.3h51.8v5.2h-0.2h-51.6L357.8,231.3z M357.8,239.6h51.6h0.2v5.2
			h-0.2h-51.6V239.6z M357.8,247.8h51.6h0.2v5.2h-51.8L357.8,247.8z M393.4,327h-35.6v-5.1h35.6V327z M393.4,318.8h-35.6v-5.2h35.6
			V318.8z M393.4,310.6h-35.6v-5.2h35.6V310.6z M393.4,302.4h-35.6v-5.2h35.6V302.4z M393.4,294.1h-35.6v-5.2h35.6V294.1z
			 M393.4,285.9h-35.6v-5.2h35.6V285.9z M393.4,277.7h-35.6v-5.2h35.6V277.7z M393.4,269.4h-35.6v-5.2h35.6V269.4z M393.4,261.2
			h-35.6V256h35.6V261.2z M486.9,261.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.2h-9.4
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0
			h9.4v5.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.2H478c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0
			h9.4v5.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.5h-90.4V322h24.2
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-24.2v-5.2h24.2c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-24.2v-5.2h24.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0H397v-5.2h24.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-24.2v-5.2h24.2
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-24.2v-5.4H421c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-24.2v-5.2h24.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-24.2v-5.2h24.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-24.2V256h90.4
			L486.9,261.2L486.9,261.2z M323.9,223.1h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C323.5,223.1,323.7,223.1,323.9,223.1L323.9,223.1z M440.9,272.5h25.7
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C440.6,272.5,440.7,272.5,440.9,272.5L440.9,272.5z M440.9,264.3h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-25.7c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C440.6,264.3,440.7,264.3,440.9,264.3L440.9,264.3z"
        />
        <path
          class="st0"
          d="M301.1,200.3h-12.4v-69.2c0-0.8-0.7-1.5-1.5-1.5H224c-0.8,0-1.5,0.7-1.5,1.5l0,0v23.1h-32.4
			c-0.8,0-1.5,0.7-1.5,1.5l0,0v172.8c0,0.8,0.7,1.5,1.5,1.5h33.3c0.2,0.1,0.4,0.1,0.6,0.1h26.6c0.2,0,0.3,0,0.5-0.1h27.1
			c0.1,0,0.2,0,0.4,0h22.6c0.8,0,1.5-0.7,1.5-1.5V201.8C302.7,201,302,200.3,301.1,200.3C301.2,200.3,301.2,200.3,301.1,200.3z"
        />
        <path
          class="st2"
          d="M276.7,272.5H260v5.2h16.6L276.7,272.5z M276.7,264.2H260v5.2h16.6L276.7,264.2z M276.7,247.8H260v5.2h16.6
			L276.7,247.8z M276.7,239.6H260v5.2h16.6L276.7,239.6z M260,294.1h16.6V289H260V294.1z M276.6,132.6h-51.1v9.7h51.1V132.6z
			 M285.7,132.6h-6v161.5h6V132.6z M276.6,231.3H260v5.2h16.6V231.3z M251.2,154.2h25.4v-4.7h-51.1v4.5h25
			C250.8,154,251,154.1,251.2,154.2L251.2,154.2z M276.7,280.7H260v5.2h16.6L276.7,280.7z M276.7,256H260v5.2h16.6L276.7,256z
			 M257,157.2h-39.3v136.9H257V157.2z M250.6,289H224c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6
			c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C250.9,289,250.8,289,250.6,289z M250.6,280.7H224c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C250.9,280.7,250.8,280.7,250.6,280.7z M250.6,272.5H224c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0
			h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C250.9,272.5,250.8,272.5,250.6,272.5z M250.6,264.2H224
			c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C250.9,264.3,250.8,264.3,250.6,264.2z M250.6,256H224c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6
			c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C250.9,256,250.8,256,250.6,256z M250.6,247.8H224c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C250.9,247.8,250.8,247.8,250.6,247.8z M250.6,239.5H224c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0
			h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C250.9,239.5,250.8,239.5,250.6,239.5z M250.6,231.3H224
			c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C250.9,231.3,250.8,231.3,250.6,231.3z M223.1,223.2v-11.6c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v11.6
			c-0.1,0.8-0.9,1.4-1.7,1.3C223.7,224.3,223.2,223.8,223.1,223.2z M231.6,223.2v-11.6c0.1-0.8,0.9-1.4,1.7-1.3
			c0.6,0.1,1.1,0.6,1.3,1.3v11.6c-0.1,0.8-0.9,1.4-1.7,1.3C232.2,224.3,231.7,223.8,231.6,223.2z M240,223.2v-11.6
			c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v11.6c-0.1,0.8-0.9,1.4-1.7,1.3C240.7,224.3,240.1,223.8,240,223.2z
			 M248.5,223.2v-11.6c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v11.6c-0.1,0.8-0.9,1.4-1.7,1.3
			C249.1,224.3,248.6,223.8,248.5,223.2z M250.6,206.5H224c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0
			h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C250.9,206.6,250.8,206.6,250.6,206.5L250.6,206.5z M250.6,198.3H224
			c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C250.9,198.3,250.8,198.3,250.6,198.3L250.6,198.3z M250.6,190.1H224c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C250.9,190.1,250.8,190.1,250.6,190.1L250.6,190.1z
			 M250.6,181.8H224c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7C250.9,181.8,250.8,181.8,250.6,181.8L250.6,181.8z M250.6,173.6H224c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C250.9,173.6,250.8,173.6,250.6,173.6L250.6,173.6z M250.6,165.3H224c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C250.9,165.4,250.8,165.4,250.6,165.3L250.6,165.3z
			 M276.7,165.5H260v5.2h16.6L276.7,165.5z M276.7,223.1H260v5.2h16.6L276.7,223.1z M203,297.1v5.2h41.1c0.8,0,1.5,0.7,1.5,1.5V327
			h54v-29.9H203z M207.6,165.8v128.3h7V157.2h-22.9v7h14.4C206.9,164.2,207.6,164.9,207.6,165.8
			C207.6,165.7,207.6,165.7,207.6,165.8L207.6,165.8z M276.7,173.8H260v5.2h16.6L276.7,173.8z M276.7,206.7H260v5.2h16.6
			L276.7,206.7z M276.7,182H260v5.2h16.6L276.7,182z M276.7,214.9H260v5.2h16.6L276.7,214.9z M276.7,190.2H260v5.2h16.6L276.7,190.2
			z M276.7,198.4H260v5.2h16.6L276.7,198.4z"
        />
        <path
          class="st1"
          d="M288.8,203.3V294h10.8v-90.7H288.8z M200,295.6c0-0.8,0.7-1.5,1.5-1.5h3V167.3h-12.8v135h8.3V295.6z
			 M191.7,327h50.9v-21.7h-50.9L191.7,327L191.7,327z M225.6,146.4h51.1v-1.1h-51.1V146.4z M260,162.4h16.6v-5.2H260V162.4z"
        />
        <path
          class="st0"
          d="M301.1,200.3h-12.4v-69.2c0-0.8-0.7-1.5-1.5-1.5H224c-0.8,0-1.5,0.7-1.5,1.5l0,0v23.1h-32.4
			c-0.8,0-1.5,0.7-1.5,1.5l0,0v172.8c0,0.8,0.7,1.5,1.5,1.5h33.3c0.2,0.1,0.4,0.1,0.6,0.1h26.6c0.2,0,0.3,0,0.5-0.1h27.1
			c0.1,0,0.2,0,0.4,0h22.6c0.8,0,1.5-0.7,1.5-1.5V201.8C302.7,201,302,200.3,301.1,200.3C301.2,200.3,301.2,200.3,301.1,200.3z
			 M279.7,132.6h6v161.5h-6V132.6z M225.6,132.6h51.1v9.7h-51.1V132.6z M225.6,145.3h51.1v1.1h-51.1V145.3z M225.6,149.4h51.1v4.7
			h-25.4c-0.2-0.1-0.4-0.1-0.6-0.1h-25L225.6,149.4z M260,285.9v-5.2h16.6v5.2H260z M276.6,288.9v5.1H260v-5.1H276.6z M260,277.7
			v-5.2h16.6v5.2H260z M260,269.4v-5.2h16.6v5.2H260z M260,261.2V256h16.6v5.2H260z M260,253v-5.2h16.6v5.2H260z M260,244.7v-5.2
			h16.6v5.2H260z M260,236.5v-5.2h16.6v5.2H260z M260,228.3v-5.2h16.6v5.2H260z M260,220.1v-5.2h16.6v5.2H260z M260,211.8v-5.2h16.6
			v5.2H260z M260,203.6v-5.2h16.6v5.2H260z M260,195.4v-5.2h16.6v5.2H260z M260,187.1V182h16.6v5.2L260,187.1z M260,178.9v-5.2h16.6
			v5.2H260z M260,170.7v-5.2h16.6v5.2H260z M260,162.4v-5.2h16.6v5.2H260z M217.6,157.2H257v136.9h-39.3L217.6,157.2z M191.6,157.2
			h22.9v136.9h-7V165.8c0-0.8-0.7-1.5-1.5-1.5l0,0h-14.3L191.6,157.2z M191.6,167.3h12.8v126.8h-3c-0.8,0-1.5,0.7-1.5,1.5v6.7h-8.3
			V167.3z M242.6,327h-50.9v-21.7h50.9V327z M299.6,327h-54v-23.2c0-0.8-0.7-1.5-1.5-1.5H203v-5.2h96.6V327z M299.6,294.1h-10.8
			v-90.8h10.8V294.1z M250.6,203.5H224c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7C250.9,203.5,250.8,203.5,250.6,203.5z M251.6,223.2v-11.6
			c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v11.6c0.1,0.8,0.9,1.4,1.7,1.3C251,224.3,251.5,223.8,251.6,223.2L251.6,223.2
			z M250.6,195.3H224c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7C250.9,195.2,250.8,195.2,250.6,195.3z M243.1,223.2v-11.6c-0.1-0.8-0.9-1.4-1.7-1.3
			c-0.6,0.1-1.1,0.6-1.3,1.3v11.6c0.1,0.8,0.9,1.4,1.7,1.3C242.5,224.3,243,223.8,243.1,223.2z M250.6,162.3H224
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			C250.9,162.3,250.8,162.3,250.6,162.3L250.6,162.3z M234.6,223.2v-11.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v11.6
			c0.1,0.8,0.9,1.4,1.7,1.3C234,224.3,234.5,223.8,234.6,223.2z M250.6,187H224c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7C250.9,187,250.8,187,250.6,187L250.6,187z M250.6,170.5
			H224c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			C250.9,170.5,250.8,170.5,250.6,170.5L250.6,170.5z M250.6,178.8H224c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7C250.9,178.8,250.8,178.8,250.6,178.8L250.6,178.8z
			 M250.6,252.9H224c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7C250.9,252.9,250.8,252.9,250.6,252.9L250.6,252.9z M250.6,261.2H224c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			C250.9,261.2,250.8,261.2,250.6,261.2L250.6,261.2z M250.6,269.4H224c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7C250.9,269.4,250.8,269.4,250.6,269.4L250.6,269.4z
			 M250.6,277.7H224c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7C250.9,277.6,250.8,277.6,250.6,277.7L250.6,277.7z M250.6,285.9H224c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			C250.9,285.9,250.8,285.9,250.6,285.9L250.6,285.9z M250.6,228.2H224c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7C250.9,228.2,250.8,228.2,250.6,228.2L250.6,228.2z
			 M226.2,223.2v-11.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v11.6c0.1,0.8,0.9,1.4,1.7,1.3
			C225.5,224.3,226,223.8,226.2,223.2L226.2,223.2z M250.6,236.5H224c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7C250.9,236.5,250.8,236.5,250.6,236.5L250.6,236.5z
			 M250.6,244.7H224c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7C250.9,244.7,250.8,244.7,250.6,244.7L250.6,244.7z"
        />
        <path
          class="st0"
          d="M2359.3,280.1c0-0.6,0.4-1,1-1h13.3v-24.4c0-0.6,0.4-1,1-1h0.1l47,5.1c0.5,0.1,0.9,0.5,0.9,1v3.2h5.9v-9.8
			c0-0.6,0.4-1,1-1h21.5v-6.6c0-0.6,0.4-1,1-1h9.3V186c0-0.6,0.4-1,1-1l0,0c0.6,0,1,0.4,1,1v58.6h24v-15.2c0-0.6,0.4-1,1-1
			s1,0.4,1,1v15.2h19.2c0.6,0,1,0.4,1,1v83.1c0,0.6-0.4,1-1,1h-40c-0.1,0-0.2,0-0.2,0h-16.9c-0.1,0-0.2,0-0.2,0h-3.9
			c-0.1,0-0.2,0-0.2,0h-72.4c-0.1,0-0.2,0-0.2,0h-6.6c-0.1,0-0.2,0-0.2,0h-7.2c-0.6,0-1-0.4-1-1L2359.3,280.1L2359.3,280.1z"
        />
        <path
          class="st0"
          d="M2616.8,280.5c0-0.6-0.4-1-1-1h-13.3v-24.4c0-0.6-0.4-1-1-1h-0.1l-47,5.1c-0.5,0.1-0.9,0.5-0.9,1v3.2h-5.9
			v-9.8c0-0.6-0.4-1-1-1h-21.5V246c0-0.6-0.4-1-1-1h-9.3v-29c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1v29h-8.3v-15.2c0-0.6-0.4-1-1-1
			s-1,0.4-1,1V245h-4.1c-0.6,0-1,0.4-1,1v83c0,0.6,0.4,1,1,1h9.2c0.1,0,0.2,0,0.2,0h16.9c0.1,0,0.2,0,0.2,0h3.9c0.1,0,0.2,0,0.2,0
			h72.4c0.1,0,0.2,0,0.2,0h6.6c0.1,0,0.2,0,0.2,0h7.2c0.6,0,1-0.4,1-1L2616.8,280.5L2616.8,280.5z"
        />
        <rect x="2149.1" y="174" class="st3" width="119.1" height="37.1" />
        <rect x="2157.5" y="172.7" class="st3" width="101.9" height="4.6" />
        <rect x="2161.2" y="162.5" class="st3" width="95.3" height="19.4" />
        <rect x="2189.4" y="122.4" class="st3" width="37.9" height="47" />
        <ellipse class="st3" cx="2182.2" cy="161.8" rx="3.5" ry="3.9" />
        <ellipse class="st3" cx="2234.1" cy="161.8" rx="3.5" ry="3.9" />
        <ellipse class="st3" cx="2171.9" cy="157" rx="10.2" ry="18.8" />
        <ellipse class="st3" cx="2208.4" cy="122.8" rx="17.2" ry="18.8" />
        <ellipse class="st3" cx="2245.4" cy="157" rx="10.2" ry="18.8" />
        <path
          class="st0"
          d="M2218.1,206.2v3.9h-4.7v-3.9c0-6.4-9.7-6.4-9.7,0v3.9h-4.7v-3.9c0-6.5-9.7-6.5-9.7,0v5.2
			c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-5.2c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2l0,0v5.2c0,0.7,0.6,1.3,1.3,1.3l0,0h7.3
			c0.7,0,1.3-0.6,1.3-1.3l0,0v-5.2c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2v5.2c0,0.7,0.6,1.3,1.3,1.3l0,0h7.4c0.7,0,1.3-0.6,1.3-1.3l0,0
			v-5.2c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2v5.2c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-5.2C2227.8,199.8,2218.1,199.8,2218.1,206.2
			z M2270.6,194.2v-1.6c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3l0,0v-8.2c0.7,0,1.3-0.6,1.3-1.3c0-0.2-0.1-0.5-0.2-0.7l-3.5-5.8
			c-0.2-0.4-0.7-0.6-1.1-0.6h-6c-0.6-1.7-2.2-2.8-4-2.8v-4.9c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3l0,0
			c-0.2-19.2-5.5-24.6-10.4-25.3v-4.2c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3v-1.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3l0,0v1.5
			c-0.7,0-1.3,0.6-1.4,1.3c0,0.7,0.6,1.3,1.3,1.4h0.1v4.2c-5.7,1-10,8.7-10.4,18.6c-2,0.9-3.4,2.9-3.5,5.1h-1.1v-15.8
			c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3V131c0.7-0.1,1.3-0.7,1.2-1.4c-0.1-0.6-0.6-1.2-1.2-1.2l0,0c-0.6-13-9.1-24.2-19.2-25.1
			v-7.5h1.5c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3h-1.5v-4.3c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v4.3h-1.5
			c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h1.5v7.5c-10,0.9-18.6,12.1-19.2,25.1l0,0c-0.7,0.1-1.3,0.7-1.2,1.4
			c0.1,0.6,0.6,1.2,1.2,1.2v12c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3v15.8h-1.1c-0.1-2.2-1.5-4.2-3.5-5.1
			c-0.4-9.8-4.7-17.5-10.4-18.6v-4.2c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3v-1.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v1.5
			c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3v4.2c-4.9,0.8-10.2,6.1-10.4,25.3c-0.7,0-1.4,0.6-1.4,1.3s0.6,1.4,1.3,1.4l0,0v4.9
			c-1.8,0-3.4,1.1-3.9,2.8h-6c-0.5,0-0.9,0.2-1.1,0.6l-3.5,5.8c-0.4,0.6-0.2,1.4,0.5,1.8c0.2,0.1,0.4,0.2,0.7,0.2v8.2
			c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3v1.6c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3V211c0,0.7,0.7,1.3,1.4,1.3h29.4
			c0,0,2.7,0.4,2.7-1.3v-47h4.4v25h-1.5c-0.7,0-1.3,0.6-1.3,1.3l0,0v20.8c0,0.7,0.6,1.3,1.3,1.3l0,0h3.6c0.7,0,1.3-0.6,1.3-1.3l0,0
			v-12.5h41.4V211c0,0.7,0.6,1.3,1.3,1.3l0,0h3.6c0.7,0,1.3-0.6,1.3-1.3l0,0v-20.8c0-0.7-0.6-1.3-1.3-1.3l0,0h-1.5V164h4.4v47.1
			c0,1.7,2.7,1.3,2.7,1.3h29.4c0.7,0,1.3-0.6,1.3-1.3l0,0l0.1-14.2c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
			C2270.7,194.3,2270.7,194.3,2270.6,194.2L2270.6,194.2z M2155.7,209.8h-6.6V197h6.6V209.8z M2155.7,194.2h-6.6v-1.6h6.6V194.2z
			 M2155.7,190h-6.6v-8.2h6.6V190z M2155.7,179.1h-6.9l1.9-3.1h5V179.1z M2158.4,174.7c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v14
			c-1,0-1,1.3-1,1.4h-1.9L2158.4,174.7z M2161.7,209.7h-3.3v-12.8h3.3V209.7z M2161.7,194.2h-3.3v-1.6h3.3V194.2z M2173,209.8h-3
			v-11.4c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5V209.8z M2177.2,209.8h-1.6v-11.4c0-5.5-8.3-5.5-8.3,0v11.4h-3v-18.5h12.9
			L2177.2,209.8L2177.2,209.8z M2177.2,188.6h-12.7c0.7-4,4.6-6.6,8.5-5.9c1.6,0.3,3.1,1.2,4.2,2.4V188.6z M2177.2,181.6
			c-4.3-2.8-10-2-13.2,1.9v-6h13.2V181.6z M2177.2,174.9h-3v-5.4c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v5.4h-1.6v-5.4
			c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3l0,0v5.4h-3.3v-0.2c0-1.2-0.5-2.3-1.4-3.1v-5.9h14.6L2177.2,174.9L2177.2,174.9z
			 M2177.3,161.3c-1.1,0-1.4,0.5-1.4,1.6h-13.3c0.2-14.7,3.4-22.8,9.1-22.8c4.7,0,8.7,7.2,9,16.1
			C2178.7,157.2,2177.4,159.2,2177.3,161.3L2177.3,161.3z M2180,161.3c0.6-3.6,3.6-3.6,4.2,0H2180z M2185.1,209.8h-1v-18.1h1V209.8z
			 M2208.5,105.5c9.2,0,17.3,10.4,17.9,22.6h-35.7C2191.2,115.9,2199.3,105.5,2208.5,105.5L2208.5,105.5z M2226.4,130.7v6.5h-4.7
			v-6.5H2226.4z M2219,130.7v6.5h-3.3v-6.5H2219z M2213.1,130.7v6.5h-3.3v-6.5H2213.1z M2207.2,130.7v6.5h-3.3v-6.5H2207.2z
			 M2201.2,130.7v6.5h-3.2v-6.5H2201.2z M2190.6,130.7h4.7v6.5h-4.7V130.7z M2190.6,139.9h35.8v3h-35.8V139.9z M2190.6,145.5h35.8
			v17.9l-1.3-0.8V154c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v7.2l-1.3-0.8V154c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v4.9
			l-1.3-0.8v-4.9c0-0.5-0.3-0.9-0.7-1.1l-7.4-4.2c-0.4-0.2-0.9-0.2-1.3,0l-7.4,4.2c-0.4,0.2-0.7,0.7-0.7,1.1v4.9l-1.3,0.8V154
			c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3l0,0v6.4l-1.3,0.8V154c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v8.7l-1.3,0.8L2190.6,145.5
			L2190.6,145.5z M2203.6,179.4v12.4h-1.2v-21.5h12v21.5h-1.2v-12.4C2213.4,173,2203.7,173,2203.6,179.4L2203.6,179.4z
			 M2210.7,179.4v12.4h-4.4v-12.3c0-1.2,1-2.2,2.2-2.2C2209.7,177.2,2210.7,178.2,2210.7,179.4L2210.7,179.4L2210.7,179.4z
			 M2207,167.6v-9.4c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v9.4H2207L2207,167.6z M2212.6,167.6v-9.4c0-5.5-8.3-5.5-8.3,0v9.4h-1.9
			V154l6-3.5l6,3.5v13.6H2212.6L2212.6,167.6z M2186.9,164h1v1l-1,0.6V164z M2186.9,189v-20.4l12.9-7.4v6.4c-0.7,0-1.3,0.6-1.3,1.3
			s0.6,1.3,1.3,1.3v21.5h-0.8v-12.4c0-6.4-9.7-6.4-9.7,0v12.4h-1.5v-1.5C2187.8,189.8,2187.5,189.3,2186.9,189L2186.9,189z
			 M2196.2,179.3v12.4h-4.4v-12.3c0-1.2,1-2.2,2.2-2.2C2195.3,177.2,2196.3,178.2,2196.2,179.3L2196.2,179.3L2196.2,179.3z
			 M2229.2,196h-41.4v-1.6h41.4V196z M2220.7,191.8v-12.3c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2v12.4L2220.7,191.8z M2230.1,189.1
			c-0.5,0.2-0.8,0.7-0.8,1.2v1.5h-1.5v-12.3c0-6.4-9.7-6.4-9.7,0v12.4h-0.9v-21.5c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3l0,0v-6.4
			l12.9,7.4V189.1z M2230.1,165.5l-1-0.6V164h1V165.5z M2232.9,191.6v18.2h-1v-18.1L2232.9,191.6z M2232.8,161.4
			c0.6-3.5,3.6-3.6,4.2,0H2232.8z M2239.7,161.4c-0.1-2.2-1.4-4.1-3.4-5.1c0.4-8.8,4.4-16.1,9-16.1c5.7,0,8.9,8.1,9.1,22.8h-13.3
			C2241.1,161.9,2240.7,161.4,2239.7,161.4L2239.7,161.4z M2239.8,185.2c2.6-3.1,7.2-3.4,10.3-0.8c1.3,1.1,2.1,2.6,2.4,4.2h-12.7
			V185.2z M2247,209.7h-3v-11.3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5V209.7z M2252.6,209.7h-3v-11.3c0-5.5-8.3-5.5-8.3,0v11.4
			h-1.6v-18.5h12.9L2252.6,209.7z M2253,183.5c-3.2-4-9-4.8-13.2-1.9v-4.1h13.2V183.5z M2254.4,171.5c-0.9,0.8-1.4,1.9-1.4,3.1v0.2
			h-3.3v-5.4c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v5.4h-1.6v-5.4c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v5.4h-3v-9.2h14.6
			L2254.4,171.5L2254.4,171.5z M2258.6,209.7h-3.3v-12.8h3.3V209.7z M2258.6,194.2h-3.3v-1.6h3.3V194.2z M2258.6,189.9h-1.9
			c0,0,0-1.4-1-1.4v-14c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5l0,0L2258.6,189.9z M2267.9,209.7h-6.6v-12.8h6.6V209.7z
			 M2267.9,194.2h-6.6v-1.6h6.6V194.2z M2267.9,189.9h-6.6v-8.1h6.6V189.9z M2261.3,179.1V176h5l1.9,3.1H2261.3z"
        />
        <path
          class="st0"
          d="M1951.8,324.1l-26.7-43.8v-4.6h1.3v-2.6h-3v-5.9l2.8-1.3v-1.4h-9.8l-11.7-38.9l1.9-1.9v-4l-3.4-1.6
			c0,0-9.7-64.9-9.7-122.3h1.7V84.9h-1.7v-1.6h1.9V79h-2.6l-3.4-3.7l-1.4-15.5l-1.4,15.5l-3.4,3.7h-2.6v4.2h1.9v1.6h-1.7V96h1.7
			c0,57.4-9.7,122.3-9.7,122.3l-3.4,1.6v4l1.9,1.9l-11.7,38.9h-9.8v1.4l2.8,1.3v5.9h-3v2.6h1.3v4.6l-26.7,43.8h-3.7v4.3h32v-4.3
			h-4.7c0.7-2.4,10.5-32.2,40.2-32.2s39.4,29.8,40.2,32.2h-4.7v4.3h32v-4.3L1951.8,324.1L1951.8,324.1z M1876.1,264.5l5.2-24h13.6
			l5.2,24H1876.1z"
        />
        <path
          class="st0"
          d="M967.8,280.5c0-0.6-0.4-1-1-1H858.4v-24.4c0-0.6-0.4-1-1-1h-0.1l-47,5.1c-0.5,0.1-0.9,0.5-0.9,1v3.2h-5.9
			v-9.8c0-0.6-0.4-1-1-1H781V246c0-0.6-0.4-1-1-1h-9.3v-29c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1v29h-8.3v-15.2c0-0.6-0.4-1-1-1
			s-1,0.4-1,1V245h-4.1c-0.6,0-1,0.4-1,1v83c0,0.6,0.4,1,1,1h9.2c0.1,0,0.2,0,0.2,0h16.9c0.1,0,0.2,0,0.2,0h3.9c0.1,0,0.2,0,0.2,0
			h72.4c0.1,0,0.2,0,0.2,0h6.6c0.1,0,0.2,0,0.2,0h102.3c0.6,0,1-0.4,1-1v-48.5H967.8z"
        />
        <path
          class="st0"
          d="M1191.7,201.1h-4.1v-15.2c0-0.6-0.4-1-1-1s-1,0.4-1,1v15.2h-8.3v-28.9c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1
			v28.9h-9.3c-0.6,0-1,0.4-1,1v6.6h-21.5c-0.6,0-1,0.4-1,1v9.8h-5.9v-3.2c0-0.5-0.4-0.9-0.9-1l-47-5.1c-0.5-0.1-1,0.3-1.1,0.9v0.1
			v24.4h-6V202c0-0.6-0.4-1-1-1h-4.1v-15.2c0-0.6-0.4-1-1-1s-1,0.4-1,1V201h-8.3v-28.9c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1V201
			h-9.3c-0.6,0-1,0.4-1,1v6.6h-21.5c-0.6,0-1,0.4-1,1v9.8h-5.9v-3.2c0-0.5-0.4-0.9-0.9-1l-47-5.1c-0.5-0.1-1,0.3-1.1,0.9v0.1v24.4
			h-21.4c-0.6,0-1,0.4-1,1v48.6l0,0c0,0.1,0,44.8,0.1,45c0.2,0.4,238.4,0.1,238.4-0.4V202.1C1192.7,201.5,1192.3,201.1,1191.7,201.1
			z"
        />
        <path
          class="st0"
          d="M1753.7,331.2c0.2,0.4,238.4,0.1,238.4-0.4V252c0-0.6-0.4-1-1-1h-25.6c-0.6,0-1,0.4-1,1v6.6H1943
			c-0.6,0-1,0.4-1,1v9.8h-5.9v-3.2c0-0.5-0.4-0.9-0.9-1l-29-2.6c-0.3,0-2.7,7.8-2.9,8s-10.5-2-10.5-1.7l-5.8,16.6l-15.9-0.6l3-28.4
			c0-0.6-0.2-6.2-0.8-6.2l2.5,0.6h-21.5c-0.6,0-1,0.4-1,1v6.6H1832c-0.6,0-1,0.4-1,1v9.8h-6v-3.2c0-0.5-0.4-0.9-0.9-1l-47-5.1
			c-0.5-0.1-1,0.3-1.1,0.9v0.1v24.4h-21.3c-0.6,0-1,0.4-1,1"
        />
        <path
          class="st0"
          d="M2077,251h-25.6c-0.6,0-1,0.4-1,1v6.6h-21.5c-0.6,0-1,0.4-1,1v9.8h-5.9v-3.2c0-0.5-0.4-0.9-0.9-1l-29-2.6
			c-0.3,0-2.7,7.8-2.9,8s-10.5-2-10.5-1.7l-5.8,16.6l-15.9-0.6l3-28.4c0-0.6-0.2-6.2-0.8-6.2l2.5,0.6h-21.5c-0.6,0-1,0.4-1,1v6.6
			h-21.5c-0.6,0-1,0.4-1,1v9.8l0,0l1.2,44.5c-0.3,0,159.9,17.5,159.9,16.9V252C2077.9,251.5,2077.5,251,2077,251z"
        />
        <path
          class="st0"
          d="M2215.9,251h-25.6c-0.6,0-1,0.4-1,1v6.6h-21.5c-0.6,0-1,0.4-1,1v9.8h-5.9v-3.2c0-0.5-0.4-0.9-0.9-1l-29-2.6
			c-0.3,0-2.6,7.8-2.9,8s-10.5-2-10.5-1.7l-5.8,16.6l-15.9-0.6l3-28.4c0-0.6-0.2-6.2-0.8-6.2l2.5,0.6h-21.5c-0.6,0-1,0.4-1,1v6.6
			h-21.5c-0.6,0-1,0.4-1,1v9.8h-5.9v-3.2c0-0.5-0.4-0.9-0.9-1l-48.6,26.3c-0.3,0,1-31.3,0.8-31.1s-0.3,20-0.3,20.3v4.8h-21.3
			c-0.6,0-1,0.4-1,1c0,0,0,44.6,0.1,44.7c0.2,0.4,238.4,0.1,238.4-0.4V252C2216.8,251.5,2216.4,251,2215.9,251z"
        />
        <path
          class="st0"
          d="M783.2,201.1h-4.1v-15.2c0-0.6-0.4-1-1-1s-1,0.4-1,1v15.2h-8.3v-28.9c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1
			v28.9h-9.3c-0.6,0-1,0.4-1,1v6.6H735c-0.6,0-1,0.4-1,1v9.8h-5.9v-3.2c0-0.5-0.4-0.9-0.9-1l-47-5.1c-0.5-0.1-1,0.3-1.1,0.9v0.1
			v24.4h-6V202c0-0.6-0.4-1-1-1H668v-15.2c0-0.6-0.4-1-1-1s-1,0.4-1,1V201h-8.3v-28.9c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1V201
			h-9.3c-0.6,0-1,0.4-1,1v6.6h-21.5c-0.6,0-1,0.4-1,1v9.8H617v-3.2c0-0.5-0.4-0.9-0.9-1l-47-5.1c-0.5-0.1-1,0.3-1.1,0.9v0.1v24.4
			h-21.4c-0.6,0-1,0.4-1,1v48.6l0,0c0,0.1,0,44.8,0.1,45c0.2,0.4,238.4,0.1,238.4-0.4V202.1C784.2,201.5,783.8,201.1,783.2,201.1
			L783.2,201.1z"
        />
        <path
          class="st0"
          d="M1863,209.2h-41.2c-0.8,0-1.5,0.7-1.5,1.5l0,0v9.2H1777c-0.8,0-1.5,0.7-1.5,1.5l0,0v78.9h-14.4
			c-0.8,0-1.5,0.7-1.5,1.5l0,0v26.6c0,0.8,0.7,1.5,1.5,1.5l0,0H1863c0.8,0,1.5-0.7,1.5-1.5l0,0V210.7
			C1864.5,209.9,1863.8,209.2,1863,209.2L1863,209.2z"
        />
        <path
          class="st2"
          d="M1831.5,300.4h5.2v-88.2h-5.2V300.4z M1823.3,300.4h5.2v-88.2h-5.2V300.4z M1801.1,223h-22.6v7.5h22.6V223z
			 M1796.7,300.4h18.8v-3.6h-18.8V300.4z M1839.8,300.4h5.2v-88.2h-5.2V300.4z M1856.3,212.3V327h5.2V212.2L1856.3,212.3z
			 M1848,300.4h1.3c0.8,0,1.5,0.7,1.5,1.5l0,0V327h2.4V212.2h-5.2L1848,300.4L1848,300.4z"
        />
        <path
          class="st1"
          d="M1818.6,295.3v5.1h1.7V223h-16.1v70.8h12.9C1817.9,293.8,1818.6,294.5,1818.6,295.3z M1762.6,303.4V327h85.2
			v-23.6H1762.6z M1793.7,295.3c0-0.8,0.7-1.5,1.5-1.5l0,0h5.9v-60.2h-22.6v66.8h15.2V295.3z"
        />
        <path
          class="st0"
          d="M1863,209.2h-41.2c-0.8,0-1.5,0.7-1.5,1.5l0,0v9.2H1777c-0.8,0-1.5,0.7-1.5,1.5l0,0v78.9h-14.4
			c-0.8,0-1.5,0.7-1.5,1.5l0,0v26.6c0,0.8,0.7,1.5,1.5,1.5l0,0H1863c0.8,0,1.5-0.7,1.5-1.5l0,0V210.7
			C1864.5,209.9,1863.8,209.2,1863,209.2L1863,209.2z M1839.8,212.2h5.2v88.2h-5.2V212.2z M1831.6,212.2h5.2v88.2h-5.2V212.2z
			 M1823.3,212.2h5.2v88.2h-5.2V212.2z M1804.1,223h16.1v77.4h-1.7v-5.1c0-0.8-0.7-1.5-1.5-1.5l0,0h-12.9V223z M1815.5,296.8v3.6
			h-18.8v-3.6H1815.5z M1778.5,223h22.6v7.5h-22.6V223z M1778.5,233.6h22.6v60.2h-5.9c-0.8,0-1.5,0.7-1.5,1.5l0,0v5.1h-15.2V233.6z
			 M1847.8,327h-85.2v-23.6h85.2V327z M1853.2,327h-2.4v-25.1c0-0.8-0.7-1.5-1.5-1.5l0,0h-1.3v-88.2h5.2L1853.2,327z M1861.4,327
			h-5.2V212.2h5.2V327z"
        />
        <path
          class="st0"
          d="M1594,280.5c0-0.6-0.4-1-1-1h-13.3v-24.4c0-0.6-0.4-1-1-1h-0.1l-47,5.1c-0.5,0.1-0.9,0.5-0.9,1v3.2h-5.9v-9.8
			c0-0.6-0.4-1-1-1h-21.5V246c0-0.6-0.4-1-1-1h-9.3v-29c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1v29h-8.3v-15.2c0-0.6-0.4-1-1-1
			s-1,0.4-1,1V245h-4.1c-0.6,0-1,0.4-1,1v83c0,0.6,0.4,1,1,1h9.2c0.1,0,0.2,0,0.2,0h16.9c0.1,0,0.2,0,0.2,0h3.9c0.1,0,0.2,0,0.2,0
			h72.4c0.1,0,0.2,0,0.2,0h6.6c0.1,0,0.2,0,0.2,0h7.1c0.6,0,1-0.4,1-1v-48.5H1594z"
        />
        <path
          class="st0"
          d="M1393.4,280.5c0-0.6-0.4-1-1-1h-13.3v-24.4c0-0.6-0.4-1-1-1h-0.1l-47,5.1c-0.5,0.1-0.9,0.5-0.9,1v3.2h-5.9
			v-9.8c0-0.6-0.4-1-1-1h-21.5V246c0-0.6-0.4-1-1-1h-9.3v-29c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1v29h-8.3v-15.2c0-0.6-0.4-1-1-1
			s-1,0.4-1,1V245h-4.1c-0.6,0-1,0.4-1,1v83c0,0.6,0.4,1,1,1h9.2c0.1,0,0.2,0,0.2,0h16.9c0.1,0,0.2,0,0.2,0h3.9c0.1,0,0.2,0,0.2,0
			h72.4c0.1,0,0.2,0,0.2,0h6.7c0.1,0,0.2,0,0.2,0h7.2c0.6,0,1-0.4,1-1L1393.4,280.5L1393.4,280.5z"
        />
        <path
          class="st0"
          d="M1685.5,280.5c0-0.6-0.4-1-1-1h-13.3v-24.4c0-0.6-0.4-1-1-1h-0.1l-47,5.1c-0.5,0.1-0.9,0.5-0.9,1v3.2h-5.9
			v-9.8c0-0.6-0.4-1-1-1h-21.5V246c0-0.6-0.4-1-1-1h-9.3v-29c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1v29h-8.3v-15.2c0-0.6-0.4-1-1-1
			s-1,0.4-1,1V245h-4.1c-0.6,0-1,0.4-1,1v83c0,0.6,0.4,1,1,1h9.2c0.1,0,0.2,0,0.2,0h16.9c0.1,0,0.2,0,0.2,0h3.9c0.1,0,0.2,0,0.2,0
			h72.4c0.1,0,0.2,0,0.2,0h6.6c0.1,0,0.2,0,0.2,0h7.2c0.6,0,1-0.4,1-1L1685.5,280.5L1685.5,280.5z"
        />
        <path
          class="st0"
          d="M1529.1,280.5c0-0.6-0.4-1-1-1h-13.3v-24.4c0-0.6-0.4-1-1-1h-0.1l-47,5.1c-0.5,0.1-0.9,0.5-0.9,1v3.2h-5.9
			v-9.8c0-0.6-0.4-1-1-1h-21.5V246c0-0.6-0.4-1-1-1h-9.3v-29c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1v29h-8.3v-15.2c0-0.6-0.4-1-1-1
			s-1,0.4-1,1V245h-4.1c-0.6,0-1,0.4-1,1v83c0,0.6,0.4,1,1,1h9.2c0.1,0,0.2,0,0.2,0h16.9c0.1,0,0.2,0,0.2,0h3.9c0.1,0,0.2,0,0.2,0
			h72.4c0.1,0,0.2,0,0.2,0h6.6c0.1,0,0.2,0,0.2,0h7.2c0.6,0,1-0.4,1-1L1529.1,280.5L1529.1,280.5z"
        />
        <path
          class="st0"
          d="M1492.1,254.5c0-0.8-0.7-1.5-1.5-1.5l0,0h-20.2v-37.2c0-0.8-0.7-1.5-1.5-1.5c-0.1,0-0.1,0-0.2,0L1397,222
			c-0.8,0.1-1.4,0.7-1.4,1.5v4.8h-9.1v-14.9c0-0.8-0.7-1.5-1.5-1.5l0,0h-32.8v-10c0-0.8-0.7-1.5-1.5-1.5l0,0h-14.1v-44.1
			c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v44.1H1321v-23.1c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v23.1
			h-6.2c-0.8,0-1.5,0.7-1.5,1.5l0,0v126.7c0,0.8,0.7,1.5,1.5,1.5l0,0h14c0.1,0,0.2,0,0.4,0h25.7c0.1,0,0.2,0,0.4,0h5.9
			c0.1,0,0.2,0,0.4,0H1469c0.1,0,0.2,0,0.4,0h10.1c0.1,0,0.2,0,0.4,0h10.9c0.8,0,1.5-0.7,1.5-1.5l0,0v-74h-0.2L1492.1,254.5
			L1492.1,254.5z"
        />
        <rect x="1433.3" y="237.7" class="st1" width="21.2" height="15.2" />
        <path
          class="st2"
          d="M1411.7,236.5v-5.2h-51.8v5.2h51.6H1411.7z M1414.7,229.8V253h15.4v-16.8c0-0.8,0.7-1.5,1.5-1.5l0,0h24.2
			c0.8,0,1.5,0.7,1.5,1.5l0,0V253h9.8v-35.6l-68.7,7.4v3.5h14.6C1414,228.3,1414.7,228.9,1414.7,229.8
			C1414.8,229.8,1414.8,229.8,1414.7,229.8L1414.7,229.8z M1349.1,203.3h-36V327h4.7V213.4c0-0.8,0.7-1.5,1.5-1.5l0,0h29.8V203.3z
			 M1411.7,244.8v-5.2h-0.2h-51.6v5.2h51.6H1411.7z M1383.4,214.9h-23.5v13.4h23.5V214.9z M1359.9,277.7h35.6v-5.2h-35.6V277.7z
			 M1359.9,285.9h35.6v-5.2h-35.6V285.9z M1359.9,269.4h35.6v-5.2h-35.6V269.4z M1359.9,261.2h35.6V256h-35.6V261.2z M1479.6,261.2
			h9.4V256h-90.4v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-24.2v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3
			s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-24.2v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-24.2v5.2
			h24.2c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-24.2v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7
			c-0.2,0-0.3,0-0.5,0h-24.2v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1398v5.2h24.2
			c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-24.2v5.2h24.2c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7
			c-0.2,0-0.3,0-0.5,0h-24.2v5.5h90.4V322h-9.4c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h9.4v-5.2h-9.4
			c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h9.4v-5.2h-9.4c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h9.4v-5.2h-9.4c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h9.4v-5.2h-9.4
			c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h9.4v-5.4h-9.4c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h9.4v-5.2h-9.4c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h9.4v-5.2h-9.4
			c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0L1479.6,261.2L1479.6,261.2z M1468.8,321.9H1443
			c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7
			C1469.1,321.9,1468.9,321.9,1468.8,321.9z M1468.8,313.6H1443c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7
			c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7C1469.1,313.6,1468.9,313.6,1468.8,313.6z M1468.8,305.4H1443c-0.8,0.1-1.6-0.4-1.7-1.3
			s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7C1469.1,305.4,1468.9,305.4,1468.8,305.4z
			 M1468.8,297.2H1443c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7
			C1469.1,297.2,1468.9,297.2,1468.8,297.2z M1468.8,288.9H1443c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7
			c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7C1469.1,289,1468.9,289,1468.8,288.9z M1468.8,280.7H1443c-0.8,0.1-1.6-0.4-1.7-1.3
			s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7C1469.1,280.7,1468.9,280.7,1468.8,280.7z
			 M1468.8,272.5H1443c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7
			C1469.1,272.5,1468.9,272.5,1468.8,272.5z M1468.8,264.2H1443c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7
			c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7C1469.1,264.3,1468.9,264.3,1468.8,264.2z M1411.7,247.8h-0.2h-51.6v5.2h51.8
			L1411.7,247.8L1411.7,247.8z M1359.9,318.8h35.6v-5.2h-35.6V318.8z M1359.9,326.9h35.6v-5.1h-35.6V326.9z M1320.9,326.9h36v-112
			h-36V326.9z M1326,220h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7C1325.7,220,1325.8,220,1326,220z M1326,228.2h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			c-0.2,0-0.3,0-0.5,0H1326c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7C1325.7,228.2,1325.8,228.2,1326,228.2z M1326,236.5
			h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7C1325.7,236.5,1325.8,236.5,1326,236.5z M1326,244.7h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			C1325.7,244.7,1325.8,244.7,1326,244.7z M1326,252.9h25.7c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0
			H1326c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7C1325.7,252.9,1325.8,252.9,1326,252.9z M1326,261.2h25.7
			c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7
			C1325.7,261.1,1325.8,261.1,1326,261.2z M1326,269.4h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1326
			c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7C1325.7,269.4,1325.8,269.4,1326,269.4z M1326,277.6h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7C1325.7,277.6,1325.8,277.6,1326,277.6z
			 M1326,285.9h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7
			C1325.7,285.8,1325.8,285.8,1326,285.9z M1326,294.1h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1326
			c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7C1325.7,294.1,1325.8,294.1,1326,294.1z M1326,302.3h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7C1325.7,302.3,1325.8,302.3,1326,302.3z
			 M1326,310.5h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7
			C1325.7,310.5,1325.8,310.5,1326,310.5z M1326,318.8h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1326
			c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7C1325.7,318.8,1325.8,318.8,1326,318.8z M1359.9,310.5h35.6v-5.2h-35.6V310.5z
			 M1359.9,302.3h35.6v-5.2h-35.6V302.3z M1359.9,294.1h35.6v-5.2h-35.6V294.1z"
        />
        <path
          class="st0"
          d="M1326.1,272.5h25.7c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1326
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7C1325.7,272.5,1325.9,272.5,1326.1,272.5z M1326.1,264.2h25.7c0.8,0.1,1.6-0.4,1.7-1.3
			s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7C1325.7,264.3,1325.9,264.3,1326.1,264.2z
			 M1326.1,256h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7C1325.7,256,1325.9,256,1326.1,256z M1326.1,247.8h25.7c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C1325.7,247.8,1325.9,247.8,1326.1,247.8z M1326.1,280.7h25.7c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1326
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7C1325.7,280.7,1325.9,280.7,1326.1,280.7z M1326.1,321.8h25.7c0.8,0.1,1.6-0.4,1.7-1.3
			s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7C1325.7,321.9,1325.9,321.9,1326.1,321.8z
			 M1326.1,313.6h25.7c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7
			C1325.7,313.6,1325.9,313.6,1326.1,313.6z M1326.1,239.6h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0H1326c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C1325.7,239.6,1325.9,239.6,1326.1,239.6z
			 M1326.1,305.4h25.7c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7
			C1325.7,305.4,1325.9,305.4,1326.1,305.4z M1326.1,297.2h25.7c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1326
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7C1325.7,297.2,1325.9,297.2,1326.1,297.2z M1326.1,288.9h25.7c0.8,0.1,1.6-0.4,1.7-1.3
			s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1326c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7C1325.7,289,1325.9,289,1326.1,288.9z
			 M1443.1,305.4h25.7c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1443c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7
			C1442.7,305.4,1442.9,305.4,1443.1,305.4z M1326.1,231.3h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0H1326c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C1325.7,231.4,1325.9,231.4,1326.1,231.3z
			 M1443.1,313.6h25.7c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1443c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7
			C1442.7,313.6,1442.9,313.6,1443.1,313.6z M1443.1,321.8h25.7c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1443
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7C1442.7,321.9,1442.9,321.9,1443.1,321.8z M1443.1,288.9h25.7c0.8,0.1,1.6-0.4,1.7-1.3
			s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1443c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7C1442.7,289,1442.9,289,1443.1,288.9z
			 M1443.1,297.2h25.7c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1443c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7
			C1442.7,297.2,1442.9,297.2,1443.1,297.2z M1443.1,280.7h25.7c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1443
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7C1442.7,280.7,1442.9,280.7,1443.1,280.7z M1492.1,254.5c0-0.8-0.7-1.5-1.5-1.5l0,0
			h-20.2v-37.2c0-0.8-0.7-1.5-1.5-1.5c-0.1,0-0.1,0-0.2,0L1397,222c-0.8,0.1-1.4,0.7-1.4,1.5v4.8h-9.1v-14.9c0-0.8-0.7-1.5-1.5-1.5
			l0,0h-32.8v-10c0-0.8-0.7-1.5-1.5-1.5l0,0h-14.1v-44.1c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v44.1H1321v-23.1
			c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v23.1h-6.2c-0.8,0-1.5,0.7-1.5,1.5l0,0v126.7c0,0.8,0.7,1.5,1.5,1.5l0,0h14
			c0.1,0,0.2,0,0.4,0h25.7c0.1,0,0.2,0,0.4,0h5.9c0.1,0,0.2,0,0.4,0H1469c0.1,0,0.2,0,0.4,0h10.1c0.1,0,0.2,0,0.4,0h10.9
			c0.8,0,1.5-0.7,1.5-1.5l0,0v-74L1492.1,254.5z M1317.8,213.3V327h-4.7V203.3h36v8.5h-29.8C1318.5,211.8,1317.9,212.5,1317.8,213.3
			L1317.8,213.3z M1356.8,327h-36V214.9h36V327z M1398.5,224.8l68.7-7.4V253h-9.8v-16.8c0-0.8-0.7-1.5-1.5-1.5l0,0h-24.2
			c-0.8,0-1.5,0.7-1.5,1.5l0,0V253h-15.4v-23.2c0-0.8-0.7-1.5-1.5-1.5l0,0h-14.6L1398.5,224.8z M1454.4,237.7V253h-21.2v-15.3
			H1454.4z M1359.9,214.9h23.5v13.4h-23.5V214.9z M1359.9,231.3h51.8v5.2h-0.2h-51.6L1359.9,231.3L1359.9,231.3z M1359.9,239.5h51.6
			h0.2v5.2h-0.2h-51.6V239.5z M1359.9,247.8h51.6h0.2v5.2h-51.8L1359.9,247.8L1359.9,247.8z M1395.5,327h-35.6v-5.1h35.6V327z
			 M1395.5,318.8h-35.6v-5.2h35.6V318.8z M1395.5,310.6h-35.6v-5.2h35.6V310.6z M1395.5,302.3h-35.6v-5.2h35.6V302.3z M1395.5,294.1
			h-35.6v-5.2h35.6V294.1z M1395.5,285.9h-35.6v-5.2h35.6V285.9z M1395.5,277.6h-35.6v-5.2h35.6V277.6z M1395.5,269.4h-35.6v-5.2
			h35.6V269.4z M1395.5,261.2h-35.6V256h35.6V261.2z M1489,261.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0
			h9.4v5.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3
			s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.2h-9.4
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h9.4v5.2h-9.4c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.2h-9.4
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h9.4v5.5h-90.4V322h24.2c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-24.2v-5.2h24.2c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-24.2v-5.2h24.2
			c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-24.2v-5.2h24.2c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0H1399v-5.2h24.2c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-24.2v-5.4h24.2
			c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-24.2v-5.2h24.2c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-24.2v-5.2h24.2c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-24.2V256h90.4L1489,261.2
			L1489,261.2z M1326,223.1h25.7c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1326
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C1325.7,223.1,1325.8,223.1,1326,223.1z M1443,272.5h25.7
			c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1443c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7
			C1442.7,272.5,1442.8,272.5,1443,272.5z M1443,264.2h25.7c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1443
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7C1442.7,264.3,1442.8,264.3,1443,264.2z"
        />
        <path
          class="st0"
          d="M1776.3,239.6h-13V232c0-0.8-0.7-1.5-1.5-1.5l0,0h-3.3v-29.2c0-0.8-0.7-1.5-1.5-1.5l0,0h-36.8v-33
			c0-0.8-0.7-1.5-1.5-1.5l0,0h-70.3c-0.8,0-1.5,0.7-1.5,1.5l0,0v161.8c0,0.8,0.7,1.5,1.5,1.5l0,0h70.3c0.1,0,0.2,0,0.4,0h57.3
			c0.8,0,1.5-0.7,1.5-1.5l0,0v-87.5C1777.9,240.2,1777.2,239.6,1776.3,239.6L1776.3,239.6z"
        />
        <path
          class="st2"
          d="M1657.9,278.6h19.5v-13h-19.5V278.6z M1657.9,214.2h19.5v-46h-19.5V214.2z M1657.9,246.6h19.5v-13.3h-19.5
			V246.6z M1657.9,262.6h19.5v-13h-19.5V262.6z M1680.5,311h10.4v-13.3h-10.4V311z M1657.9,230.2h19.5v-13h-19.5V230.2z M1680.5,327
			h10.4v-12.9h-10.4V327z M1680.5,230.3h10.4v-13h-10.4V230.3z M1680.5,246.6h10.4v-13.3h-10.4V246.6z M1680.5,294.7h10.4v-13h-10.4
			V294.7z M1680.5,278.7h10.4v-13h-10.4V278.7z M1680.5,262.7h10.4v-13h-10.4V262.7z M1657.9,327h19.5v-12.9h-19.5V327z M1649.9,311
			h5v-13.3h-5V311z M1649.9,294.6h5v-13h-5V294.6z M1649.9,278.6h5v-13h-5V278.6z M1755.4,202.8h-18.8v27.7h18.8V202.8z
			 M1649.9,262.6h5v-13h-5V262.6z M1649.9,327h5v-12.9h-5V327z M1649.9,230.3h5v-13h-5V230.3z M1657.9,311h19.5v-13.3h-19.5V311z
			 M1680.5,214.2h10.4v-46h-10.4V214.2z M1649.9,214.2h5v-46h-5V214.2z M1649.9,246.6h5v-13.3h-5V246.6z M1657.9,294.7h19.5v-13
			h-19.5V294.7z M1693.9,246.6h9.7v-13.3h-9.7V246.6z M1728.4,327h5.2v-84.4h-5.2V327z M1706.7,230.3h10.4v-13h-10.4V230.3z
			 M1720.2,239.6h5.2v-36.8h-5.2V239.6z M1693.9,214.2h23.2v-46h-23.2V214.2z M1736.7,327h5.2v-84.4h-5.2V327z M1720.2,327h5.2
			v-84.4h-5.2V327z M1728.4,239.6h5.2v-36.7h-5.2V239.6z M1761.4,327h5.2v-84.4h-5.2V327z M1744.9,327h5.2v-84.4h-5.2V327z
			 M1769.6,242.6V327h5.2v-84.4H1769.6z M1753.1,327h5.2v-84.4h-5.2V327z M1736.7,239.6h23.6v-6h-23.6V239.6z M1693.9,262.6h9.7v-13
			h-9.7V262.6z M1693.9,327h9.7v-12.9h-9.7V327z M1693.9,230.3h9.7v-13h-9.7V230.3z M1693.9,278.7h9.7v-13h-9.7V278.7z
			 M1693.9,294.7h9.7v-13h-9.7V294.7z M1693.9,311h9.7v-13.3h-9.7V311z M1706.7,246.6h10.4v-13.3h-10.4V246.6z M1706.7,262.6h10.4
			v-13h-10.4V262.6z M1706.7,278.6h10.4v-13h-10.4V278.6z M1706.7,326.9h10.4V314h-10.4V326.9z M1706.7,310.9h10.4v-13.2h-10.4
			V310.9z M1706.7,294.6h10.4v-13h-10.4V294.6z"
        />
        <path
          class="st0"
          d="M1776.3,239.6h-13V232c0-0.8-0.7-1.5-1.5-1.5l0,0h-3.3v-29.2c0-0.8-0.7-1.5-1.5-1.5l0,0h-36.8v-33
			c0-0.8-0.7-1.5-1.5-1.5l0,0h-70.3c-0.8,0-1.5,0.7-1.5,1.5l0,0v161.8c0,0.8,0.7,1.5,1.5,1.5l0,0h70.3c0.1,0,0.2,0,0.4,0h57.3
			c0.8,0,1.5-0.7,1.5-1.5l0,0v-87.5C1777.9,240.2,1777.2,239.6,1776.3,239.6L1776.3,239.6z M1654.9,327h-5v-12.9h5V327z M1654.9,311
			h-5v-13.3h5V311z M1654.9,294.6h-5v-13h5V294.6z M1654.9,278.6h-5v-13h5V278.6z M1654.9,262.6h-5v-13h5V262.6z M1654.9,246.6h-5
			v-13.4h5V246.6z M1654.9,230.3h-5v-13h5V230.3z M1654.9,214.3h-5v-46h5V214.3z M1677.4,327h-19.5v-12.9h19.5V327z M1677.4,311
			h-19.5v-13.3h19.5V311z M1677.4,294.6h-19.5v-13h19.5V294.6z M1677.4,278.6h-19.5v-13h19.5V278.6z M1677.4,262.6h-19.5v-13h19.5
			V262.6z M1677.4,246.6h-19.5v-13.4h19.5V246.6z M1677.4,230.3h-19.5v-13h19.5V230.3z M1677.4,214.3h-19.5v-46h19.5V214.3z
			 M1690.9,327h-10.4v-12.9h10.4V327z M1690.9,311h-10.4v-13.3h10.4V311z M1690.9,294.6h-10.4v-13h10.4V294.6z M1690.9,278.6h-10.4
			v-13h10.4V278.6z M1690.9,262.6h-10.4v-13h10.4V262.6z M1690.9,246.6h-10.4v-13.4h10.4V246.6z M1690.9,230.3h-10.4v-13h10.4V230.3
			z M1690.9,214.3h-10.4v-46h10.4V214.3z M1703.7,327h-9.7v-12.9h9.7V327z M1703.7,311h-9.7v-13.3h9.7V311z M1703.7,294.6h-9.7v-13
			h9.7V294.6z M1703.7,278.6h-9.7v-13h9.7V278.6z M1703.7,262.6h-9.7v-13h9.7V262.6z M1703.7,246.6h-9.7v-13.4h9.7V246.6z
			 M1703.7,230.3h-9.7v-13h9.7V230.3z M1717.1,327h-10.4v-12.9h10.4V327z M1717.1,311h-10.4v-13.3h10.4V311z M1717.1,294.6h-10.4
			v-13h10.4V294.6z M1717.1,278.6h-10.4v-13h10.4V278.6z M1717.1,262.6h-10.4v-13h10.4V262.6z M1717.1,246.6h-10.4v-13.4h10.4V246.6
			z M1717.1,230.3h-10.4v-13h10.4V230.3z M1717.1,214.3h-23.2v-46h23.2V214.3z M1725.3,327h-5.2v-84.4h5.2V327z M1725.3,239.6h-5.2
			v-36.7h5.2V239.6z M1733.6,327h-5.2v-84.4h5.2V327z M1733.6,239.6h-5.2v-36.7h5.2V239.6z M1736.6,202.8h18.8v27.7h-18.8V202.8z
			 M1741.8,327h-5.2v-84.4h5.2V327z M1750,327h-5.2v-84.4h5.2V327z M1758.3,327h-5.2v-84.4h5.2V327z M1760.2,239.6h-23.6v-6h23.6
			V239.6z M1766.5,327h-5.2v-84.4h5.2V327z M1774.8,327h-5.2v-84.4h5.2V327z"
        />
        <path
          class="st0"
          d="M633.8,239.6h-13V232c0-0.8-0.7-1.5-1.5-1.5l0,0H616v-29.2c0-0.8-0.7-1.5-1.5-1.5l0,0h-36.8v-33
			c0-0.8-0.7-1.5-1.5-1.5l0,0h-70.3c-0.8,0-1.5,0.7-1.5,1.5v161.8c0,0.8,0.7,1.5,1.5,1.5h70.3c0.1,0,0.2,0,0.4,0h57.3
			c0.8,0,1.5-0.7,1.5-1.5l0,0v-87.5C635.3,240.2,634.7,239.6,633.8,239.6L633.8,239.6z"
        />
        <path
          class="st2"
          d="M515.4,278.6h19.5v-13h-19.5V278.6z M515.4,214.2h19.5v-46h-19.5V214.2z M515.4,246.6h19.5v-13.3h-19.5V246.6
			z M515.4,262.6h19.5v-13h-19.5V262.6z M538,311h10.4v-13.3H538V311z M515.5,230.2H535v-13h-19.5V230.2z M538,327h10.4v-12.9H538
			V327z M538,230.3h10.4v-13H538V230.3z M538,246.6h10.4v-13.3H538V246.6z M538,294.7h10.4v-13H538V294.7z M538,278.7h10.4v-13H538
			V278.7z M538,262.7h10.4v-13H538V262.7z M515.4,327h19.5v-12.9h-19.5V327z M507.4,311h5v-13.3h-5L507.4,311L507.4,311z
			 M507.4,294.6h5v-13h-5L507.4,294.6L507.4,294.6z M507.4,278.6h5v-13h-5L507.4,278.6L507.4,278.6z M613,202.8h-18.8v27.7H613
			V202.8z M507.4,262.6h5v-13h-5L507.4,262.6L507.4,262.6z M507.4,327h5v-12.9h-5L507.4,327L507.4,327z M507.4,230.2h5v-13h-5
			L507.4,230.2L507.4,230.2z M515.4,311h19.5v-13.3h-19.5V311z M538,214.2h10.4v-46H538V214.2z M507.4,214.2h5v-46h-5L507.4,214.2z
			 M507.4,246.6h5v-13.3h-5L507.4,246.6z M515.5,294.7H535v-13h-19.5V294.7z M551.5,246.6h9.7v-13.3h-9.7V246.6z M585.9,327h5.2
			v-84.4h-5.2V327z M564.2,230.3h10.4v-13h-10.4V230.3z M577.7,239.6h5.2v-36.8h-5.2V239.6z M551.4,214.2h23.2v-46h-23.2V214.2z
			 M594.2,327h5.2v-84.4h-5.2V327z M577.7,327h5.2v-84.4h-5.2V327z M585.9,239.6h5.2v-36.7h-5.2V239.6z M618.9,327h5.1v-84.4h-5.2
			L618.9,327z M602.4,327h5.2v-84.4h-5.2V327z M627.1,242.6V327h5.2v-84.4H627.1z M610.6,327h5.2v-84.4h-5.2V327z M594.2,239.6h23.6
			v-6h-23.6V239.6z M551.4,262.6h9.7v-13h-9.7V262.6z M551.4,327h9.7v-12.9h-9.7V327z M551.4,230.3h9.7v-13h-9.7V230.3z
			 M551.4,278.7h9.7v-13h-9.7V278.7z M551.4,294.7h9.7v-13h-9.7V294.7z M551.4,311h9.7v-13.3h-9.7V311z M564.2,246.6h10.4v-13.3
			h-10.4V246.6z M564.2,262.6h10.4v-13h-10.4V262.6z M564.2,278.6h10.4v-13h-10.4V278.6z M564.2,326.9h10.4V314h-10.4V326.9z
			 M564.2,310.9h10.4v-13.2h-10.4V310.9z M564.2,294.6h10.4v-13h-10.4V294.6z"
        />
        <path
          class="st0"
          d="M633.8,239.6h-13V232c0-0.8-0.7-1.5-1.5-1.5l0,0H616v-29.2c0-0.8-0.7-1.5-1.5-1.5l0,0h-36.8v-33
			c0-0.8-0.7-1.5-1.5-1.5l0,0h-70.3c-0.8,0-1.5,0.7-1.5,1.5v161.8c0,0.8,0.7,1.5,1.5,1.5h70.3c0.1,0,0.2,0,0.4,0h57.3
			c0.8,0,1.5-0.7,1.5-1.5l0,0v-87.5C635.3,240.2,634.7,239.6,633.8,239.6L633.8,239.6z M512.4,327h-5v-12.9h5V327z M512.4,311h-5
			v-13.3h5V311z M512.4,294.6h-5v-13h5V294.6z M512.4,278.6h-5v-13h5V278.6z M512.4,262.6h-5v-13h5V262.6z M512.4,246.6h-5v-13.4h5
			V246.6z M512.4,230.3h-5v-13h5V230.3z M512.4,214.3h-5v-46h5V214.3z M534.9,327h-19.5v-12.9h19.5V327z M534.9,311h-19.5v-13.3
			h19.5V311z M534.9,294.6h-19.5v-13h19.5V294.6z M534.9,278.6h-19.5v-13h19.5V278.6z M534.9,262.6h-19.5v-13h19.5V262.6z
			 M534.9,246.6h-19.5v-13.4h19.5V246.6z M534.9,230.3h-19.5v-13h19.5V230.3z M534.9,214.3h-19.5v-46h19.5V214.3z M548.4,327H538
			v-12.9h10.4V327z M548.4,311H538v-13.3h10.4V311z M548.4,294.6H538v-13h10.4V294.6z M548.4,278.6H538v-13h10.4V278.6z
			 M548.4,262.6H538v-13h10.4V262.6z M548.4,246.6H538v-13.4h10.4V246.6z M548.4,230.3H538v-13h10.4V230.3z M548.4,214.3H538v-46
			h10.4V214.3z M561.2,327h-9.7v-12.9h9.7V327z M561.2,311h-9.7v-13.3h9.7V311z M561.2,294.6h-9.7v-13h9.7V294.6z M561.2,278.6h-9.7
			v-13h9.7V278.6z M561.2,262.6h-9.7v-13h9.7V262.6z M561.2,246.6h-9.7v-13.4h9.7V246.6z M561.2,230.3h-9.7v-13h9.7V230.3z
			 M574.6,327h-10.4v-12.9h10.4V327z M574.6,311h-10.4v-13.3h10.4V311z M574.6,294.6h-10.4v-13h10.4V294.6z M574.6,278.6h-10.4v-13
			h10.4V278.6z M574.6,262.6h-10.4v-13h10.4V262.6z M574.6,246.6h-10.4v-13.4h10.4V246.6z M574.6,230.3h-10.4v-13h10.4V230.3z
			 M574.6,214.3h-23.2v-46h23.2V214.3z M582.9,327h-5.2v-84.4h5.2V327z M582.9,239.6h-5.2v-36.7h5.2V239.6z M591.1,327h-5.2v-84.4
			h5.2V327z M591.1,239.6h-5.2v-36.7h5.2V239.6z M594.1,202.8H613v27.7h-18.8L594.1,202.8z M599.3,327h-5.2v-84.4h5.2V327z
			 M607.6,327h-5.2v-84.4h5.2V327z M615.8,327h-5.2v-84.4h5.2V327z M617.8,239.6h-23.6v-6h23.6V239.6z M624,327h-5.2v-84.4h5.2V327z
			 M632.2,327H627v-84.4h5.2V327z"
        />
        <path
          class="st0"
          d="M2485.7,280.1c0-0.6-0.4-1-1-1h-13.3v-24.4c0-0.6-0.4-1-1-1h-0.1l-47,5.1c-0.5,0.1-0.9,0.5-0.9,1v3.2h-5.9
			v-9.8c0-0.6-0.4-1-1-1H2394v-6.6c0-0.6-0.4-1-1-1h-9.3V186c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1v58.6h-24v-15.2c0-0.6-0.4-1-1-1
			s-1,0.4-1,1v15.2h-19.2c-0.6,0-1,0.4-1,1v83.1c0,0.6,0.4,1,1,1h40c0.1,0,0.2,0,0.2,0h16.9c0.1,0,0.2,0,0.2,0h3.9
			c0.1,0,0.2,0,0.2,0h72.4c0.1,0,0.2,0,0.2,0h6.6c0.1,0,0.2,0,0.2,0h7.2c0.6,0,1-0.4,1-1L2485.7,280.1L2485.7,280.1z"
        />
        <path
          class="st0"
          d="M2095.6,201.1h4.1v-15.2c0-0.6,0.4-1,1-1s1,0.4,1,1v15.2h8.3v-28.9c0-0.6,0.4-1,1-1l0,0c0.6,0,1,0.4,1,1v28.9
			h9.3c0.6,0,1,0.4,1,1v6.6h21.5c0.6,0,1,0.4,1,1v9.8h5.9v-3.2c0-0.5,0.4-0.9,0.9-1l47-5.1c0.5-0.1,1,0.3,1.1,0.9v0.1v24.4h6V202
			c0-0.6,0.4-1,1-1h25.6c0.6,0,1,0.4,1,1v6.6h21.5c0.6,0,1,0.4,1,1v9.8h5.9v-3.2c0-0.5,0.4-0.9,0.9-1l47-5.1c0.5-0.1,1,0.3,1.1,0.9
			v0.1v24.4h21.3c0.6,0,1,0.4,1,1v48.6l0,0c0,0.1,0,44.8-0.1,45c-0.1,0.4-238.4,0.1-238.4-0.4V202.1
			C2094.5,201.5,2095,201.1,2095.6,201.1C2095.5,201.1,2095.6,201.1,2095.6,201.1z"
        />
        <path
          class="st0"
          d="M2222.9,239.6h13V232c0-0.8,0.7-1.5,1.5-1.5l0,0h3.3v-29.2c0-0.8,0.7-1.5,1.5-1.5l0,0h36.8v-33
			c0-0.8,0.7-1.5,1.5-1.5l0,0h70.3c0.8,0,1.5,0.7,1.5,1.5l0,0v161.8c0,0.8-0.7,1.5-1.5,1.5l0,0h-70.2c-0.1,0-0.2,0-0.4,0h-57.3
			c-0.8,0-1.5-0.7-1.5-1.5l0,0v-87.5C2221.4,240.2,2222.1,239.6,2222.9,239.6L2222.9,239.6z"
        />
        <path
          class="st2"
          d="M2341.3,265.7h-19.5v13h19.5V265.7z M2341.3,168.3h-19.5v46h19.5V168.3z M2341.3,233.3h-19.5v13.3h19.5V233.3
			z M2341.3,249.6h-19.5v13h19.5V249.6z M2318.8,297.7h-10.4V311h10.4V297.7z M2341.3,217.2h-19.5v13h19.5V217.2z M2318.8,314h-10.4
			v13h10.4V314z M2318.8,217.2h-10.4v13h10.4V217.2z M2318.8,233.2h-10.4v13.4h10.4V233.2z M2318.8,281.6h-10.4v13h10.4V281.6z
			 M2318.8,265.6h-10.4v13h10.4V265.6z M2318.8,249.6h-10.4v13h10.4V249.6z M2341.3,314.1h-19.5V327h19.5V314.1z M2349.4,297.7h-5
			V311h5V297.7z M2349.4,281.7h-5v13h5V281.7z M2349.4,265.7h-5v13h5V265.7z M2243.8,230.5h18.8v-27.7h-18.8V230.5z M2349.4,249.6
			h-5v13h5V249.6z M2349.4,314.1h-5V327h5V314.1z M2349.4,217.3h-5v13h5V217.3z M2341.3,297.7h-19.5V311h19.5V297.7z M2318.8,168.3
			h-10.4v46h10.4V168.3z M2349.4,168.3h-5v46h5V168.3z M2349.4,233.3h-5v13.3h5V233.3z M2341.3,281.7h-19.5v13h19.5V281.7z
			 M2305.3,233.3h-9.7v13.3h9.7V233.3z M2270.8,242.6h-5.2V327h5.2V242.6z M2292.5,217.2h-10.4v13h10.4V217.2z M2279.1,202.8h-5.2
			v36.7h5.2V202.8z M2305.3,168.2h-23.2v46h23.2V168.2z M2262.6,242.6h-5.2V327h5.2V242.6z M2279.1,242.6h-5.2V327h5.2V242.6z
			 M2270.8,202.8h-5.2v36.7h5.2V202.8z M2237.8,242.6h-5.1V327h5.1V242.6z M2254.3,242.6h-5.2V327h5.2V242.6z M2224.4,242.6V327h5.2
			v-84.4H2224.4z M2246.1,242.6h-5.2V327h5.2V242.6z M2262.5,233.5H2239v6h23.6L2262.5,233.5z M2305.3,249.6h-9.7v13h9.7V249.6z
			 M2305.3,314h-9.7v13h9.7V314z M2305.3,217.3h-9.7v13h9.7V217.3z M2305.3,265.7h-9.7v13h9.7V265.7z M2305.3,281.7h-9.7v13h9.7
			V281.7z M2305.3,297.7h-9.7V311h9.7V297.7z M2292.5,233.2h-10.4v13.4h10.4V233.2z M2292.5,249.6h-10.4v13h10.4V249.6z
			 M2292.5,265.6h-10.4v13h10.4V265.6z M2292.5,314h-10.4v13h10.4V314z M2292.5,297.6h-10.4V311h10.4V297.6z M2292.5,281.6h-10.4v13
			h10.4V281.6z"
        />
        <path
          class="st0"
          d="M2221.4,241.1v87.4c0,0.8,0.7,1.5,1.5,1.5l0,0h57.3c0.1,0,0.2,0,0.4,0h70.3c0.8,0,1.5-0.7,1.5-1.5l0,0V166.7
			c0-0.8-0.7-1.5-1.5-1.5l0,0h-70.3c-0.8,0-1.5,0.7-1.5,1.5l0,0v33h-36.8c-0.8,0-1.5,0.7-1.5,1.5l0,0v29.2h-3.3
			c-0.8,0-1.5,0.7-1.5,1.5l0,0v7.5h-13C2222.1,239.5,2221.4,240.2,2221.4,241.1L2221.4,241.1z M2344.3,314.1h5V327h-5V314.1z
			 M2344.3,297.7h5V311h-5V297.7z M2344.3,281.7h5v13h-5V281.7z M2344.3,265.7h5v13h-5V265.7z M2344.3,249.7h5v13h-5V249.7z
			 M2344.3,233.4h5v13.2h-5V233.4z M2344.3,217.4h5v13h-5V217.4z M2344.3,168.4h5v46h-5V168.4z M2321.8,314.1h19.5V327h-19.5V314.1z
			 M2321.8,297.7h19.5V311h-19.5V297.7z M2321.8,281.7h19.5v13h-19.5V281.7z M2321.8,265.7h19.5v13h-19.5V265.7z M2321.8,249.7h19.5
			v13h-19.5V249.7z M2321.8,233.4h19.5v13.2h-19.5V233.4z M2321.8,217.4h19.5v13h-19.5V217.4z M2321.8,168.4h19.5v46h-19.5V168.4z
			 M2308.4,314.1h10.4V327h-10.4V314.1z M2308.4,297.8h10.4V311h-10.4V297.8z M2308.4,281.8h10.4v13h-10.4V281.8z M2308.4,265.8
			h10.4v13h-10.4V265.8z M2308.4,249.8h10.4v13h-10.4V249.8z M2308.4,233.4h10.4v13.2h-10.4V233.4z M2308.4,217.4h10.4v13h-10.4
			V217.4z M2308.4,168.4h10.4v46h-10.4V168.4z M2295.6,314.2h9.7V327h-9.7V314.2z M2295.6,297.9h9.7V311h-9.7V297.9z M2295.6,281.9
			h9.7v13h-9.7V281.9z M2295.6,265.9h9.7v13h-9.7V265.9z M2295.6,249.9h9.7v13h-9.7V249.9z M2295.6,233.5h9.7v13.1h-9.7V233.5z
			 M2295.6,217.5h9.7v13h-9.7V217.5z M2282.1,314.3h10.4V327h-10.4V314.3z M2282.1,298h10.4v13h-10.4V298z M2282.1,282h10.4v13
			h-10.4V282z M2282.1,266h10.4v13h-10.4V266z M2282.1,250h10.4v13h-10.4V250z M2282.1,233.6h10.4v13h-10.4V233.6z M2282.1,217.6
			h10.4v13h-10.4V217.6z M2282.1,168.6h23.2v46h-23.2V168.6z M2273.9,243h5.2v84h-5.2V243z M2273.9,203.2h5.2v36.7h-5.2V203.2z
			 M2265.6,243h5.2v84h-5.2V243z M2265.6,203.2h5.2v36.7h-5.2V203.2z M2262.6,230.9h-18.8v-28h18.8V230.9z M2257.4,243h5.2v84h-5.2
			V243z M2249.2,243h5.2v84h-5.2V243z M2240.9,243h5.2v84h-5.2V243z M2239,233.9h23.6v6H2239V233.9z M2232.7,243h5.1v84h-5.1V243z
			 M2224.5,243h5.2v84h-5.2V243z"
        />
        <path
          class="st0"
          d="M1074.7,303.7h-48.2v-82.2c0-0.8-0.7-1.5-1.5-1.5l0,0h-31.5v-88.9c0-0.8-0.7-1.5-1.5-1.5l0,0h-56.1v-21.9
			c0-0.8-0.7-1.5-1.5-1.5l0,0h-58.5c-0.8,0-1.5,0.7-1.5,1.5l0,0V146h-66.7c-0.8,0-1.5,0.7-1.5,1.5l0,0v181c0,0.8,0.7,1.5,1.5,1.5
			l0,0h267.1c0.8,0,1.5-0.7,1.5-1.5l0,0v-23.3C1076.2,304.3,1075.5,303.7,1074.7,303.7L1074.7,303.7z"
        />
        <rect x="809.1" y="305.3" class="st1" width="46.4" height="21.6" />
        <rect x="935.9" y="140.8" class="st1" width="54.6" height="13.4" />
        <path
          class="st2"
          d="M1010,327h5.2V223h-5.2V327z M1018.2,223v104h5.2V223H1018.2z M897.7,109.2h-2.8v3.9h2.8V109.2z M903.6,109.2
			h-2.8v3.9h2.8V109.2z M885.9,109.2H883v3.9h2.8L885.9,109.2z M1001.7,327h5.2V223h-5.2V327z M891.7,109.2h-2.8v3.9h2.8V109.2z
			 M909.4,109.2h-2.8v3.9h2.8V109.2z M958.2,298.3h-9.6v11.9h9.6V298.3z M913.9,231.3h-32.4c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h31.1l-8-5.2h-23.1c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h18.4l-8-5.2h-10.4c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0H892
			c0.1,0,0.2,0,0.3,0h40.5V109.2h-2.6v5.4c0,0.8-0.7,1.5-1.5,1.5l0,0h-47.2c-0.8,0-1.5-0.7-1.5-1.5v-5.4h-2.6v124.5h36.5V231.3z
			 M881.5,121.3h47.2c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7C881.2,121.3,881.3,121.3,881.5,121.3z M881.5,129.5h47.2c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			C881.2,129.5,881.3,129.5,881.5,129.5z M881.5,137.8h47.2c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0
			h-47.2c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7C881.2,137.7,881.3,137.7,881.5,137.8z M881.5,146h47.2
			c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			C881.2,146,881.3,146,881.5,146z M881.5,154.2h47.2c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-47.2
			c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7C881.2,154.2,881.3,154.2,881.5,154.2z M881.5,162.5h47.2
			c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			C881.2,162.4,881.3,162.4,881.5,162.5z M881.5,170.7h47.2c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0
			h-47.2c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7C881.2,170.7,881.3,170.7,881.5,170.7z M881.5,178.9h47.2
			c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			C881.2,178.9,881.3,178.9,881.5,178.9z M881.5,187.1h47.2c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0
			h-47.2c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7C881.2,187.1,881.3,187.1,881.5,187.1z M881.5,195.4h47.2
			c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			C881.2,195.4,881.3,195.4,881.5,195.4z M881.5,203.6h47.2c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0
			h-47.2c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7C881.2,203.6,881.3,203.6,881.5,203.6z M993.5,233.7h5.2V223h-5.2V233.7
			z M958.2,283.3h-9.6v11.9h9.6V283.3z M870,327h128.7v-90.3H870V327z M876.7,298.3c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h5.3v-12h-5.3c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0
			h5.3v-12h-5.3c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h5.3v-12H877c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h5.3v-7.6c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v7.6h9.6v-7.6
			c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v7.6h9.6v-7.6c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v7.6h9.6v-7.6
			c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v7.6h9.6v-7.6c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v7.6h9.6v-7.6
			c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v7.6h9.6v-7.6c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v7.6h9.6v-7.6
			c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v7.6h9.6v-7.6c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v7.6h5.8
			c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-5.3v11.9h5.3c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-5.3v12h5.3c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0
			h-5.3v11.9h5.3c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-5.3V310h5.3c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-5.3v7.8c-0.1,0.8-0.9,1.4-1.7,1.3c-0.6-0.1-1.1-0.6-1.3-1.3v-7.7H974v7.7
			c-0.1,0.8-0.9,1.4-1.7,1.3c-0.6-0.1-1.1-0.6-1.3-1.3v-7.7h-9.6v7.7c-0.1,0.8-0.9,1.4-1.7,1.3c-0.6-0.1-1.1-0.6-1.3-1.3v-7.7h-9.6
			v7.7c-0.1,0.8-0.9,1.4-1.7,1.3c-0.6-0.1-1.1-0.6-1.3-1.3v-7.7h-9.6v7.7c-0.1,0.8-0.9,1.4-1.7,1.3c-0.6-0.1-1.1-0.6-1.3-1.3v-7.7
			h-9.6v7.7c-0.1,0.8-0.9,1.4-1.7,1.3c-0.6-0.1-1.1-0.6-1.3-1.3v-7.7H911v7.7c-0.1,0.8-0.9,1.4-1.7,1.3c-0.6-0.1-1.1-0.6-1.3-1.3
			v-7.7h-9.6v7.7c-0.1,0.8-0.9,1.4-1.7,1.3c-0.6-0.1-1.1-0.6-1.3-1.3v-7.7h-10.1v7.7c-0.1,0.8-0.9,1.4-1.7,1.3
			c-0.6-0.1-1.1-0.6-1.3-1.3v-7.7H877c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h5.3v-12L876.7,298.3z
			 M958.2,253.4h-9.6v11.9h9.6V253.4z M958.2,268.4h-9.6v11.9h9.6V268.4z M944.1,157.2v54.6h5.2v-54.6L944.1,157.2L944.1,157.2z
			 M897.4,214.8l18.4,12h36.9l18.4-12H897.4z M957.5,233.7v-6.3l-2.8,1.8v4.5H957.5z M965.8,233.7V222l-5.2,3.4v8.3H965.8z
			 M951.7,233.7v-3.8H917v3.8H951.7z M990.4,233.7v-76.5h-5.1v76.5H990.4z M990.4,132.6h-54.6v5.2h54.6V132.6z M809.1,302.3h5.2V149
			h-5.2V302.3z M977.2,214.6l-0.1,0.1v19h5.2v-76.4h-5.2V212c0.4,0.2,0.6,0.5,0.7,0.9C978,213.5,977.8,214.2,977.2,214.6
			L977.2,214.6z M960.6,157.2v54.6h5.2v-54.6L960.6,157.2z M941.1,157.2h-5.2v54.6h5.2V157.2z M927.1,109.2h-2.8v3.9h2.8V109.2z
			 M921.2,109.2h-2.8v3.9h2.8V109.2z M952.4,157.2v54.6h5.2v-54.6L952.4,157.2z M915.4,109.2h-2.8v3.9h2.8V109.2z M974.1,157.2h-5.2
			v54.6h5.2V157.2z M974.1,216.6l-5.2,3.4v13.7h5.2V216.6z M907.4,298.3h-9.6v11.9h9.6V298.3z M945.5,283.3h-9.6v11.9h9.6V283.3z
			 M932.8,253.4h-9.6v11.9h9.6V253.4z M945.5,298.3h-9.6v11.9h9.6V298.3z M945.5,253.4h-9.6v11.9h9.6V253.4z M945.5,268.4h-9.6v11.9
			h9.6V268.4z M817.3,302.3h5.2V149h-5.2V302.3z M932.8,268.4h-9.6v11.9h9.6V268.4z M932.8,283.4h-9.6v11.9h9.6V283.4z M932.8,298.4
			h-9.6v11.9h9.6V298.4z M970.9,268.4h-9.6v11.9h9.6V268.4z M970.9,283.4h-9.6v11.9h9.6V283.4z M983.6,253.5H974v11.9h9.6V253.5z
			 M970.9,253.5h-9.6v11.9h9.6V253.5z M983.6,298.4H974v11.9h9.6V298.4z M983.6,268.4H974v11.9h9.6V268.4z M983.6,283.4H974v11.9
			h9.6V283.4z M920.1,253.5h-9.6v11.9h9.6V253.5z M874.3,231.3h-11c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h11V223h-11c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h11v-5.2h-11
			c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h11v-5.2h-11c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h11v-5.2h-11c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0
			h11v-5.2h-11c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h11v-5.3h-11c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h11v-5.2h-11c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0
			h11v-5.2h-11c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h11v-5.2h-11c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h11V149h-15.8v178h8.4v-5.2h-3.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h3.6v-5.2h-3.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h3.6v-5.2h-3.6
			c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h3.6v-5.2h-3.6c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h3.6V289h-3.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0
			h3.6v-5.2h-3.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h3.6v-5.2h-3.6c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h3.6v-5.5H865c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0
			h3.6V256h-3.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h3.6v-5.2h-3.6c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h3.6v-5.2h-3.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0
			h3.6v-1.3c0-0.8,0.7-1.5,1.5-1.5l0,0h5.8L874.3,231.3z M894.7,298.3h-9.6v11.9h9.6V298.3z M850.3,302.3h5.2V149h-5.2V302.3z
			 M894.7,268.4h-9.6v11.9h9.6V268.4z M894.7,283.4h-9.6v11.9h9.6V283.4z M825.6,302.4h5.2V149h-5.2V302.4z M833.8,302.4h5.2V149
			h-5.2V302.4z M842.1,302.4h5.2V149h-5.2V302.4z M920.1,268.4h-9.6v11.9h9.6V268.4z M920.1,298.4h-9.6v11.9h9.6V298.4z
			 M920.1,283.4h-9.6v11.9h9.6V283.4z M907.4,253.4h-9.6v11.9h9.6V253.4z M894.7,253.4h-9.6v11.9h9.6V253.4z M907.4,268.4h-9.6v11.9
			h9.6V268.4z M970.9,298.4h-9.6v11.9h9.6V298.4z M907.4,283.4h-9.6v11.9h9.6V283.4z"
        />
        <path
          class="st0"
          d="M881.5,206.6h47.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-47.2
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C881.2,206.7,881.3,206.7,881.5,206.6z M1074.7,303.6h-48.2v-82.2
			c0-0.8-0.7-1.5-1.5-1.5l0,0h-31.5V131c0-0.8-0.7-1.5-1.5-1.5l0,0h-56.1v-21.9c0-0.8-0.7-1.5-1.5-1.5l0,0h-58.5
			c-0.8,0-1.5,0.7-1.5,1.5l0,0v38.3h-66.7c-0.8,0-1.5,0.7-1.5,1.5l0,0v181c0,0.8,0.7,1.5,1.5,1.5l0,0h267.1c0.8,0,1.5-0.7,1.5-1.5
			l0,0v-23.3C1076.2,304.3,1075.5,303.7,1074.7,303.6L1074.7,303.6L1074.7,303.6z M935.9,132.6h54.6v5.2h-54.6V132.6z M935.9,140.8
			h54.6v13.4h-54.6V140.8z M990.5,157.2v76.5h-5.2v-76.5H990.5z M977.9,212.9c-0.1-0.4-0.4-0.7-0.7-0.9v-54.8h5.2v76.5h-5.2v-19
			l0.1-0.1C977.8,214.2,978,213.5,977.9,212.9L977.9,212.9z M974.1,157.2v54.6h-5.2v-54.6L974.1,157.2z M965.8,157.2v54.6h-5.2
			v-54.6L965.8,157.2z M957.6,157.2v54.6h-5.2v-54.6L957.6,157.2z M949.3,157.2v54.6h-5.2v-54.6L949.3,157.2z M935.9,157.2h5.2v54.6
			h-5.2V157.2z M971.3,214.8l-18.4,12H916l-18.4-12H971.3z M974.1,216.6v17.1h-5.2V220L974.1,216.6z M965.8,222v11.7h-5.2v-8.3
			L965.8,222z M957.6,227.4v6.3h-2.8v-4.5L957.6,227.4z M951.7,229.9v3.8H917v-3.8H951.7z M924.3,109.2h2.8v3.9h-2.8V109.2z
			 M918.4,109.2h2.8v3.9h-2.8V109.2z M912.5,109.2h2.8v3.9h-2.8V109.2z M906.6,109.2h2.8v3.9h-2.8V109.2z M900.7,109.2h2.8v3.9h-2.8
			V109.2z M894.8,109.2h2.8v3.9h-2.8V109.2z M888.9,109.2h2.8v3.9h-2.8V109.2z M883,109.2h2.8v3.9H883V109.2z M877.3,109.2h2.7v5.4
			c0,0.8,0.7,1.5,1.5,1.5l0,0h47.2c0.8,0,1.5-0.7,1.5-1.5l0,0v-5.4h2.6v102.6h-40.5c-0.1,0-0.2,0-0.3,0h-10.5
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0H892l8,5.2h-18.4c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h23.1l8,5.2h-31.1c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h32.4v2.4h-36.6L877.3,109.2z M842.1,149h5.2v153.3h-5.2V149z M833.8,149h5.2v153.3h-5.2V149z M825.6,149h5.2
			v153.3h-5.2V149z M817.3,149h5.2v153.3h-5.2V149z M809.1,149h5.2v153.3h-5.2V149z M855.5,327h-46.4v-21.7h46.4V327z M855.5,302.3
			h-5.2V149h5.2V302.3z M866.9,235.2v1.3h-3.6c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h3.6v5.2h-3.6
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h3.6v5.2h-3.6c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h3.6v5.2h-3.6c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0
			h3.6v5.2h-3.6c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h3.6v5.2h-3.6c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h3.6v5.2h-3.6c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0
			h3.6v5.2H864c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h3.6v5.2h-3.6c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h3.6v5.2h-3.6c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0
			h3.6v5.2h-3.6c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h3.6v5.6h-8.4V149h15.8v5.2h-11
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h11v5.2h-11c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h11v5.2h-11c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h11
			v5.2h-11c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h11v5.2h-11c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h11v5.2h-11c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h11
			v5.2h-11c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h11v5.2h-11c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h11v5.5h-11c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h11
			v5.2h-11c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h11v2.4h-5.8c-0.8,0-1.5,0.7-1.5,1.5
			C866.9,235.1,866.9,235.2,866.9,235.2L866.9,235.2z M998.7,327H870v-90.3h128.7V327z M998.7,233.7h-5.2V223h5.2V233.7z
			 M1006.9,327h-5.2V223h5.2V327z M1015.2,327h-5.2V223h5.2V327z M1023.4,327h-5.2V223h5.2V327z M1073.2,327h-46.7v-20.3h46.7V327z
			 M876.7,310.2c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h5.3v7.8c0.1,0.8,0.9,1.4,1.7,1.3
			c0.6-0.1,1.1-0.6,1.3-1.3v-7.7h9.6v7.7c0.1,0.8,0.9,1.4,1.7,1.3c0.6-0.1,1.1-0.6,1.3-1.3v-7.7h9.6v7.7c0.1,0.8,0.9,1.4,1.7,1.3
			c0.6-0.1,1.1-0.6,1.3-1.3v-7.7h9.6v7.7c0.1,0.8,0.9,1.4,1.7,1.3c0.6-0.1,1.1-0.6,1.3-1.3v-7.7h9.6v7.7c0.1,0.8,0.9,1.4,1.7,1.3
			c0.6-0.1,1.1-0.6,1.3-1.3v-7.7h9.6v7.7c0.1,0.8,0.9,1.4,1.7,1.3c0.6-0.1,1.1-0.6,1.3-1.3v-7.7h9.6v7.7c0.1,0.8,0.9,1.4,1.7,1.3
			c0.6-0.1,1.1-0.6,1.3-1.3v-7.7h9.6v7.7c0.1,0.8,0.9,1.4,1.7,1.3c0.6-0.1,1.1-0.6,1.3-1.3v-7.7h9.6v7.7c0.1,0.8,0.9,1.4,1.7,1.3
			c0.6-0.1,1.1-0.6,1.3-1.3v-7.7h5.8c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-5.3v-12h5.3
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-5.3v-12h5.3c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-5.3v-12h5.3c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0
			H986v-12h5.3c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-5.3v-7.6c-0.1-0.8-0.9-1.4-1.7-1.3
			c-0.6,0.1-1.1,0.6-1.3,1.3v7.6H974v-7.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v7.6h-9.6v-7.6
			c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v7.6h-9.6v-7.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v7.6h-9.6
			v-7.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v7.6h-9.6v-7.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v7.6
			H911v-7.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v7.6h-9.6v-7.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3
			v7.6h-10.1v-7.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v7.6H877c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h5.3v11.9h-5.3c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h5.3v12h-5.3
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h5.3v11.9h-5.3c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h5.3V310L876.7,310.2z M973.9,253.4h9.6v11.9h-9.6V253.4z M973.9,268.4h9.6v11.9h-9.6
			V268.4z M973.9,283.4h9.6v11.9h-9.6V283.4z M973.9,298.4h9.6v11.9h-9.6V298.4z M961.2,253.5h9.6v11.9h-9.6V253.5z M961.2,268.5
			h9.6v11.8h-9.6V268.5z M961.2,283.5h9.6v11.9h-9.6V283.5z M961.2,298.5h9.6v11.9h-9.6V298.5z M948.6,253.6h9.6v11.9h-9.6V253.6z
			 M948.6,268.6h9.6v11.7h-9.6V268.6z M948.6,283.6h9.6v11.9h-9.6V283.6z M948.6,298.6h9.6v11.9h-9.6V298.6z M935.9,253.7h9.6v11.9
			h-9.6V253.7z M935.9,268.7h9.6v11.6h-9.6V268.7z M935.9,283.7h9.6v11.9h-9.6V283.7z M935.9,298.7h9.6v11.9h-9.6V298.7z
			 M923.2,253.8h9.6v11.9h-9.6V253.8z M923.2,268.8h9.6v11.5h-9.6V268.8z M923.2,283.8h9.6v11.9h-9.6V283.8z M923.2,298.8h9.6v11.9
			h-9.6V298.8z M910.5,253.9h9.6v11.9h-9.6V253.9z M910.5,268.9h9.6v11.4h-9.6V268.9z M910.5,283.9h9.6v11.9h-9.6V283.9z
			 M910.5,298.9h9.6v11.9h-9.6V298.9z M897.8,254h9.6v11.9h-9.6V254z M897.8,269h9.6v11.3h-9.6V269z M897.8,284h9.6v11.9h-9.6V284z
			 M897.8,299h9.6v11.9h-9.6V299z M885.1,254.1h9.6V266h-9.6V254.1z M885.1,269.1h9.6v11.2h-9.6V269.1z M885.1,284.1h9.6V296h-9.6
			V284.1z M885.1,299.1h9.6V311h-9.6V299.1z M881.5,166.3h47.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0
			h-47.2c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C881.2,166.3,881.3,166.3,881.5,166.3L881.5,166.3z M881.5,141.6h47.2
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C881.2,141.6,881.3,141.6,881.5,141.6L881.5,141.6z M881.5,149.8h47.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-47.2c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C881.2,149.9,881.3,149.9,881.5,149.8L881.5,149.8z
			 M881.5,133.4h47.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7C881.2,133.4,881.3,133.4,881.5,133.4L881.5,133.4z M881.5,125.1h47.2c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C881.2,125.2,881.3,125.2,881.5,125.1L881.5,125.1z M881.5,182.8h47.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-47.2c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C881.2,182.8,881.3,182.8,881.5,182.8L881.5,182.8z
			 M881.5,191h47.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7C881.2,191,881.3,191,881.5,191L881.5,191z M881.5,158.1h47.2c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			C881.2,158.1,881.3,158.1,881.5,158.1L881.5,158.1z M881.5,199.2h47.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-47.2c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7C881.2,199.2,881.3,199.2,881.5,199.2L881.5,199.2z
			 M881.5,174.5h47.2c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-47.2c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7C881.2,174.5,881.3,174.5,881.5,174.5L881.5,174.5z"
        />
        <path
          class="st0"
          d="M1303.3,200.3H1291v-69.2c0-0.8-0.7-1.5-1.5-1.5l0,0h-63.2c-0.8,0-1.5,0.7-1.5,1.5l0,0v23.1h-32.4
			c-0.8,0-1.5,0.7-1.5,1.5l0,0v172.8c0,0.8,0.7,1.5,1.5,1.5l0,0h33.3c0.2,0.1,0.4,0.1,0.6,0.1h26.6c0.2,0,0.3,0,0.5-0.1h27.1
			c0.1,0,0.2,0,0.4,0h22.6c0.8,0,1.5-0.7,1.5-1.5l0,0V201.8C1304.9,201,1304.2,200.3,1303.3,200.3
			C1303.4,200.3,1303.4,200.3,1303.3,200.3z"
        />
        <path
          class="st2"
          d="M1278.9,272.5h-16.6v5.2h16.6V272.5z M1278.9,264.2h-16.6v5.2h16.6V264.2z M1278.9,247.8h-16.6v5.2h16.6
			V247.8z M1278.9,239.6h-16.6v5.2h16.6V239.6z M1262.2,294.1h16.6V289h-16.6V294.1z M1278.9,132.6h-51.1v9.7h51.1V132.6z
			 M1288,132.6h-6v161.5h6V132.6z M1278.9,231.3h-16.6v5.2h16.6V231.3z M1253.4,154.2h25.4v-4.7h-51.1v4.5h25
			C1253,154,1253.2,154.1,1253.4,154.2L1253.4,154.2z M1278.9,280.7h-16.6v5.2h16.6V280.7z M1278.9,256h-16.6v5.2h16.6V256z
			 M1259.2,157.2h-39.3v136.9h39.3V157.2z M1252.8,289h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6
			c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7C1253.2,289,1253,289,1252.8,289z M1252.8,280.7h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3
			s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7C1253.2,280.7,1253,280.7,1252.8,280.7z
			 M1252.8,272.5h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7
			C1253.2,272.5,1253,272.5,1252.8,272.5z M1252.8,264.2h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6
			c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7C1253.2,264.3,1253,264.3,1252.8,264.2z M1252.8,256h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C1253.2,256,1253,256,1252.8,256z M1252.8,247.8h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6
			c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C1253.2,247.8,1253,247.8,1252.8,247.8z M1252.8,239.5h-26.6
			c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C1253.2,239.5,1253,239.5,1252.8,239.5z M1252.8,231.3h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C1253.2,231.3,1253,231.3,1252.8,231.3z M1225.3,223.2
			v-11.6c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v11.6c-0.1,0.8-0.9,1.4-1.7,1.3C1225.9,224.3,1225.4,223.8,1225.3,223.2
			L1225.3,223.2z M1233.8,223.2v-11.6c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v11.6c-0.1,0.8-0.9,1.4-1.7,1.3
			C1234.4,224.3,1233.9,223.8,1233.8,223.2L1233.8,223.2z M1242.2,223.2v-11.6c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3
			v11.6c-0.1,0.8-0.9,1.4-1.7,1.3C1242.9,224.3,1242.4,223.8,1242.2,223.2L1242.2,223.2z M1250.7,223.2v-11.6
			c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v11.6c-0.1,0.8-0.9,1.4-1.7,1.3C1251.3,224.3,1250.8,223.8,1250.7,223.2
			L1250.7,223.2z M1252.8,206.6h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6
			c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C1253.1,206.6,1253,206.6,1252.8,206.6z M1252.8,198.3h-26.6
			c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C1253.1,198.3,1253,198.3,1252.8,198.3z M1252.8,190.1h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C1253.1,190.1,1253,190.1,1252.8,190.1z M1252.8,181.8
			h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7C1253.1,181.9,1253,181.9,1252.8,181.8z M1252.8,173.6h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7
			C1253.1,173.6,1253,173.6,1252.8,173.6z M1252.8,165.3h-26.6c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7C1253.1,165.4,1253,165.4,1252.8,165.3z M1278.9,165.5
			h-16.6v5.2h16.6V165.5z M1278.9,223.1h-16.6v5.2h16.6V223.1z M1205.2,297.1v5.2h41.1c0.8,0,1.5,0.7,1.5,1.5l0,0V327h54v-29.9
			H1205.2z M1209.8,165.8v128.3h7V157.2h-22.9v7h14.4C1209.1,164.2,1209.8,164.9,1209.8,165.8
			C1209.8,165.7,1209.8,165.7,1209.8,165.8L1209.8,165.8z M1278.9,173.8h-16.6v5.2h16.6V173.8z M1278.9,206.7h-16.6v5.2h16.6V206.7z
			 M1278.9,182h-16.6v5.2h16.6V182z M1278.9,214.9h-16.6v5.2h16.6V214.9z M1278.9,190.2h-16.6v5.2h16.6V190.2z M1278.9,198.5h-16.6
			v5.2h16.6V198.5z"
        />
        <path
          class="st1"
          d="M1291,203.3V294h10.8v-90.7H1291z M1202.2,295.6c0-0.8,0.7-1.5,1.5-1.5l0,0h3V167.3h-12.8v135h8.3V295.6z
			 M1193.9,327h50.9v-21.6h-50.9V327z M1227.8,146.4h51.1v-1.1h-51.1V146.4z M1262.2,162.4h16.6v-5.2h-16.6V162.4z"
        />
        <path
          class="st0"
          d="M1303.3,200.3H1291v-69.2c0-0.8-0.7-1.5-1.5-1.5l0,0h-63.2c-0.8,0-1.5,0.7-1.5,1.5l0,0v23.1h-32.4
			c-0.8,0-1.5,0.7-1.5,1.5l0,0v172.8c0,0.8,0.7,1.5,1.5,1.5l0,0h33.3c0.2,0.1,0.4,0.1,0.6,0.1h26.6c0.2,0,0.3,0,0.5-0.1h27.1
			c0.1,0,0.2,0,0.4,0h22.6c0.8,0,1.5-0.7,1.5-1.5l0,0V201.8C1304.9,201,1304.2,200.3,1303.3,200.3
			C1303.4,200.3,1303.4,200.3,1303.3,200.3z M1281.9,132.6h6v161.5h-6V132.6z M1227.8,132.6h51.1v9.7h-51.1V132.6z M1227.8,145.3
			h51.1v1.1h-51.1V145.3z M1227.8,149.4h51.1v4.7h-25.4c-0.2-0.1-0.4-0.1-0.6-0.1h-25L1227.8,149.4z M1262.2,285.9v-5.2h16.6v5.2
			H1262.2z M1278.9,288.9v5.1h-16.6v-5.1H1278.9z M1262.2,277.6v-5.2h16.6v5.2H1262.2z M1262.2,269.4v-5.2h16.6v5.2H1262.2z
			 M1262.2,261.1V256h16.6v5.2L1262.2,261.1z M1262.2,252.9v-5.2h16.6v5.3L1262.2,252.9z M1262.2,244.7v-5.2h16.6v5.2H1262.2z
			 M1262.2,236.5v-5.2h16.6v5.2H1262.2z M1262.2,228.2v-5.1h16.6v5.2L1262.2,228.2z M1262.2,220v-5.2h16.6v5.2H1262.2z
			 M1262.2,211.8v-5.2h16.6v5.2H1262.2z M1262.2,203.5v-5.2h16.6v5.2H1262.2z M1262.2,195.3v-5.2h16.6v5.2H1262.2z M1262.2,187.1
			V182h16.6v5.2L1262.2,187.1z M1262.2,178.9v-5.2h16.6v5.2H1262.2z M1262.2,170.6v-5.2h16.6v5.2H1262.2z M1262.2,162.3v-5.2h16.6
			v5.2H1262.2z M1219.9,157.2h39.3v136.9h-39.3V157.2z M1193.9,157.2h22.9v136.9h-7V165.8c0-0.8-0.7-1.5-1.5-1.5l0,0h-14.4V157.2z
			 M1193.9,167.2h12.8V294h-3c-0.8,0-1.5,0.7-1.5,1.5l0,0v6.7h-8.3V167.2z M1244.8,327h-50.9v-21.7h50.9V327z M1301.8,327h-54v-23.2
			c0-0.8-0.7-1.5-1.5-1.5l0,0h-41.1v-5.2h96.6V327z M1301.8,294.1H1291v-90.8h10.8V294.1z M1252.8,203.5h-26.6
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			C1253.2,203.5,1253,203.5,1252.8,203.5z M1253.8,223.2v-11.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v11.6
			c0.1,0.8,0.9,1.4,1.7,1.3C1253.2,224.3,1253.7,223.8,1253.8,223.2L1253.8,223.2z M1252.8,195.3h-26.6c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			C1253.2,195.2,1253,195.2,1252.8,195.3z M1245.3,223.2v-11.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v11.6
			c0.1,0.8,0.9,1.4,1.7,1.3C1244.7,224.3,1245.2,223.8,1245.3,223.2L1245.3,223.2z M1252.8,162.3h-26.6c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			C1253.2,162.3,1253,162.3,1252.8,162.3z M1236.8,223.2v-11.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v11.6
			c0.1,0.8,0.9,1.4,1.7,1.3C1236.2,224.3,1236.7,223.8,1236.8,223.2L1236.8,223.2z M1252.8,187h-26.6c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			C1253.2,187,1253,187,1252.8,187z M1252.8,170.5h-26.6c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7C1253.2,170.5,1253,170.5,1252.8,170.5z M1252.8,178.8h-26.6
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			C1253.2,178.8,1253,178.8,1252.8,178.8z M1252.8,253h-26.6c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0
			h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7C1253.2,252.9,1253,252.9,1252.8,253z M1252.8,261.2h-26.6
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7
			C1253.2,261.2,1253,261.2,1252.8,261.2z M1252.8,269.4h-26.6c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6
			c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7C1253.2,269.4,1253,269.4,1252.8,269.4z M1252.8,277.7h-26.6c-0.8-0.1-1.6,0.4-1.7,1.3
			s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7C1253.2,277.6,1253,277.6,1252.8,277.7z
			 M1252.8,285.9h-26.6c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7
			C1253.2,285.9,1253,285.9,1252.8,285.9z M1252.8,228.2h-26.6c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7C1253.2,228.2,1253,228.2,1252.8,228.2z M1228.4,223.2
			v-11.6c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v11.6c0.1,0.8,0.9,1.4,1.7,1.3C1227.7,224.3,1228.3,223.8,1228.4,223.2
			L1228.4,223.2z M1252.8,236.5h-26.6c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6
			c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7C1253.2,236.5,1253,236.5,1252.8,236.5z M1252.8,244.7h-26.6
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			C1253.2,244.7,1253,244.7,1252.8,244.7z"
        />
        <path
          class="st0"
          d="M1914.6,200.3h12.4v-69.2c0-0.8,0.7-1.5,1.5-1.5l0,0h63.2c0.8,0,1.5,0.7,1.5,1.5l0,0v23.1h32.4
			c0.8,0,1.5,0.7,1.5,1.5l0,0v172.8c0,0.8-0.7,1.5-1.5,1.5l0,0h-33.3c-0.2,0.1-0.4,0.1-0.6,0.1h-26.6c-0.2,0-0.3,0-0.5-0.1h-27.1
			c-0.1,0-0.2,0-0.4,0h-22.6c-0.8,0-1.5-0.7-1.5-1.5l0,0V201.8C1913.1,201,1913.8,200.3,1914.6,200.3z"
        />
        <path
          class="st2"
          d="M1939.1,277.7h16.6v-5.2h-16.6V277.7z M1939.1,269.4h16.6v-5.2h-16.6V269.4z M1939.1,253h16.6v-5.2h-16.6V253
			z M1939.1,244.7h16.6v-5.2h-16.6V244.7z M1955.7,288.9h-16.6v5.1h16.6V288.9z M1939.1,142.3h51.1v-9.7h-51.1V142.3z M1930,294.1h6
			V132.6h-6V294.1z M1939.1,236.5h16.6v-5.2h-16.6V236.5z M1965.2,154h25v-4.6h-51.1v4.7h25.4C1964.7,154,1964.9,154,1965.2,154z
			 M1939.1,285.8h16.6v-5.1h-16.6V285.8z M1939.1,261.1h16.6V256h-16.6V261.1z M1958.8,294h39.3V157.2h-39.3V294z M1963.6,287.4
			c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965
			C1964.3,288.9,1963.7,288.2,1963.6,287.4L1963.6,287.4z M1963.6,279.1c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3
			s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965C1964.3,280.6,1963.7,280,1963.6,279.1L1963.6,279.1z M1963.6,270.9
			c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965
			C1964.3,272.4,1963.7,271.8,1963.6,270.9L1963.6,270.9z M1963.6,262.7c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3
			s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965C1964.3,264.1,1963.7,263.5,1963.6,262.7L1963.6,262.7z M1963.6,254.4
			c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965
			C1964.3,255.9,1963.7,255.3,1963.6,254.4L1963.6,254.4z M1963.6,246.2c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965C1964.3,247.7,1963.7,247,1963.6,246.2L1963.6,246.2z M1963.6,237.9
			c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965
			C1964.3,239.4,1963.7,238.8,1963.6,237.9L1963.6,237.9z M1963.6,229.7c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965C1964.3,231.2,1963.7,230.6,1963.6,229.7L1963.6,229.7z M1991.2,224.7
			c-0.8,0-1.5-0.7-1.5-1.5l0,0v-11.5c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v11.6C1992.6,224,1992,224.7,1991.2,224.7
			L1991.2,224.7z M1982.7,224.7c-0.8,0-1.5-0.7-1.5-1.5l0,0v-11.5c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v11.6
			C1984.2,224,1983.5,224.7,1982.7,224.7L1982.7,224.7z M1974.2,224.7c-0.8,0-1.5-0.7-1.5-1.5l0,0v-11.5c0.1-0.8,0.9-1.4,1.7-1.3
			c0.6,0.1,1.1,0.6,1.3,1.3v11.6C1975.7,224,1975,224.7,1974.2,224.7L1974.2,224.7z M1965.7,224.7c-0.8,0-1.5-0.7-1.5-1.5l0,0v-11.5
			c0.1-0.8,0.9-1.4,1.7-1.3c0.6,0.1,1.1,0.6,1.3,1.3v11.6C1967.2,224,1966.6,224.7,1965.7,224.7L1965.7,224.7z M1963.6,205
			c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965
			C1964.3,206.5,1963.6,205.8,1963.6,205L1963.6,205z M1963.6,196.8c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965C1964.3,198.3,1963.6,197.6,1963.6,196.8L1963.6,196.8z M1963.6,188.5
			c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965
			C1964.3,190,1963.6,189.4,1963.6,188.5L1963.6,188.5z M1963.6,180.3c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3
			c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965C1964.3,181.8,1963.6,181.1,1963.6,180.3L1963.6,180.3z M1963.6,172
			c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965
			C1964.3,173.5,1963.6,172.9,1963.6,172L1963.6,172L1963.6,172z M1963.6,163.8c0-0.8,0.7-1.5,1.5-1.5l0,0h26.6
			c0.8-0.1,1.6,0.4,1.7,1.3c0.1,0.8-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H1965C1964.3,165.3,1963.6,164.6,1963.6,163.8L1963.6,163.8z
			 M1939.1,170.7h16.6v-5.2h-16.6V170.7z M1939.1,228.3h16.6v-5.2h-16.6V228.3z M1916.1,297.1V327h54v-23.2c0-0.8,0.7-1.5,1.5-1.5
			l0,0h41.1v-5.2L1916.1,297.1z M2009.7,164.2h14.4v-7h-22.9V294h7V165.8C2008.1,164.9,2008.8,164.2,2009.7,164.2L2009.7,164.2
			L2009.7,164.2z M1939.1,178.9h16.6v-5.2h-16.6V178.9z M1939.1,211.8h16.6v-5.2h-16.6V211.8z M1939.1,187.1h16.6V182h-16.6V187.1z
			 M1939.1,220.1h16.6v-5.2h-16.6V220.1z M1939.1,195.4h16.6v-5.2h-16.6V195.4z M1939.1,203.6h16.6v-5.2h-16.6V203.6z"
        />
        <path
          class="st1"
          d="M1916.1,203.3V294h10.9v-90.7H1916.1z M2015.8,302.3h8.3v-135h-12.8V294h3c0.8,0,1.5,0.7,1.5,1.5l0,0V302.3z
			 M2024.1,305.3h-50.9V327h50.9V305.3z M1990.2,145.3h-51.1v1.1h51.1V145.3z M1955.7,157.2h-16.6v5.2h16.6V157.2z"
        />
        <path
          class="st0"
          d="M1913.1,201.8v126.7c0,0.8,0.7,1.5,1.5,1.5l0,0h22.6c0.1,0,0.2,0,0.4,0h27.1c0.1,0.1,0.3,0.1,0.5,0.1h26.6
			c0.2,0,0.4,0,0.6-0.1h33.3c0.8,0,1.5-0.7,1.5-1.5l0,0V155.7c0-0.8-0.7-1.5-1.5-1.5l0,0h-32.4v-23.1c0-0.8-0.7-1.5-1.5-1.5l0,0
			h-63.2c-0.8,0-1.5,0.7-1.5,1.5l0,0v69.2h-12.3C1913.8,200.3,1913.1,200.9,1913.1,201.8L1913.1,201.8z M1936.1,294.1h-6V132.6h6
			V294.1z M1990.2,142.3h-51.1v-9.7h51.1V142.3z M1990.2,146.4h-51.1v-1.1h51.1V146.4z M1990.2,154h-25c-0.2,0-0.4,0-0.6,0.1h-25.4
			v-4.7h51.1L1990.2,154z M1939.1,285.9v-5.2h16.6v5.2H1939.1z M1955.8,288.9v5.1h-16.6v-5.1H1955.8z M1939.1,277.6v-5.2h16.6v5.2
			H1939.1z M1939.1,269.4v-5.2h16.6v5.2H1939.1z M1939.1,261.1V256h16.6v5.2L1939.1,261.1z M1939.1,252.9v-5.2h16.6v5.3
			L1939.1,252.9z M1939.1,244.7v-5.2h16.6v5.2H1939.1z M1939.1,236.5v-5.2h16.6v5.2H1939.1z M1939.1,228.2v-5.1h16.6v5.2
			L1939.1,228.2z M1939.1,220v-5.2h16.6v5.2H1939.1z M1939.1,211.8v-5.2h16.6v5.2H1939.1z M1939.1,203.5v-5.2h16.6v5.2H1939.1z
			 M1939.1,195.3v-5.2h16.6v5.2H1939.1z M1939.1,187.1V182h16.6v5.2L1939.1,187.1z M1939.1,178.9v-5.2h16.6v5.2H1939.1z
			 M1939.1,170.6v-5.2h16.6v5.2H1939.1z M1939.1,162.3v-5.2h16.6v5.2H1939.1z M1998.1,294h-39.3V157.2h39.3V294z M2024.1,164.2
			h-14.4c-0.8,0-1.5,0.7-1.5,1.5l0,0v128.4h-7V157.2h22.9V164.2z M2024.1,302.2h-8.3v-6.7c0-0.8-0.7-1.5-1.5-1.5l0,0h-3V167.3h12.8
			V302.2z M1973.2,305.2h50.9V327h-50.9V305.2z M1916.2,297h96.6v5.2h-41.1c-0.8,0-1.5,0.7-1.5,1.5l0,0V327h-54V297z M1916.2,203.2
			h10.8V294h-10.8V203.2z M1963.7,204.9c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-26.6C1964.4,203.4,1963.7,204,1963.7,204.9C1963.6,204.9,1963.6,205,1963.7,204.9L1963.7,204.9z
			 M1965.8,224.6c0.8,0,1.5-0.7,1.5-1.5l0,0v-11.5c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v11.6c0,0.8,0.6,1.5,1.4,1.5
			L1965.8,224.6z M1963.7,196.7c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-26.6C1964.4,195.2,1963.7,195.8,1963.7,196.7C1963.6,196.7,1963.6,196.7,1963.7,196.7L1963.7,196.7z
			 M1974.2,224.6c0.8,0,1.5-0.7,1.5-1.5l0,0v-11.5c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v11.6c0,0.8,0.6,1.5,1.4,1.5
			L1974.2,224.6z M1963.7,163.7c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-26.6C1964.4,162.2,1963.7,162.8,1963.7,163.7c-0.1,0-0.1,0.1-0.1,0.1L1963.7,163.7z M1982.7,224.6
			c0.8,0,1.5-0.7,1.5-1.5l0,0v-11.5c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v11.6c0,0.8,0.7,1.5,1.5,1.5V224.6z
			 M1963.7,188.4c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-26.6
			C1964.4,186.9,1963.7,187.6,1963.7,188.4C1963.6,188.4,1963.6,188.5,1963.7,188.4L1963.7,188.4z M1963.7,172
			c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-26.6
			C1964.4,170.4,1963.7,171.1,1963.7,172C1963.6,172,1963.6,172,1963.7,172L1963.7,172z M1963.7,180.2c0,0.8,0.7,1.5,1.5,1.5l0,0
			h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-26.6C1964.4,178.7,1963.7,179.3,1963.7,180.2
			C1963.6,180.2,1963.6,180.2,1963.7,180.2L1963.7,180.2z M1963.7,254.4c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-26.6C1964.4,252.8,1963.7,253.5,1963.7,254.4
			C1963.6,254.4,1963.6,254.4,1963.7,254.4L1963.7,254.4z M1963.7,262.6c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3
			s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-26.6C1964.4,261.1,1963.7,261.7,1963.7,262.6C1963.6,262.6,1963.6,262.7,1963.7,262.6
			L1963.7,262.6z M1963.7,270.8c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-26.6
			C1964.4,269.3,1963.7,270,1963.7,270.8c-0.1,0-0.1,0.1-0.1,0.1L1963.7,270.8z M1963.7,279.1c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6
			c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-26.6C1964.4,277.6,1963.7,278.2,1963.7,279.1
			c-0.1,0-0.1,0.1-0.1,0.1L1963.7,279.1z M1963.7,287.3c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-26.6C1964.4,285.8,1963.7,286.5,1963.7,287.3c-0.1,0-0.1,0.1-0.1,0.1L1963.7,287.3z M1963.7,229.6
			c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-26.6
			C1964.4,228.1,1963.7,228.8,1963.7,229.6c-0.1,0-0.1,0.1-0.1,0.1L1963.7,229.6z M1991.2,224.6c0.8,0,1.5-0.7,1.5-1.5l0,0v-11.5
			c-0.1-0.8-0.9-1.4-1.7-1.3c-0.6,0.1-1.1,0.6-1.3,1.3v11.6C1989.7,224,1990.3,224.7,1991.2,224.6L1991.2,224.6z M1963.6,238
			c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1965
			C1964.3,236.5,1963.6,237.2,1963.6,238z M1963.6,246.2c0,0.8,0.7,1.5,1.5,1.5l0,0h26.6c0.8,0.1,1.6-0.4,1.7-1.3
			c0.1-0.8-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0H1965C1964.3,244.8,1963.7,245.4,1963.6,246.2L1963.6,246.2z"
        />
        <polygon class="st0" points="1107.1,248.5 1027.4,328.2 1186.8,328.2 		" />

        <rect
          x="1062.6"
          y="306"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 93.5129 844.2486)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1065.8,305.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1065.8,305.4 M1065.8,304l-5.3,5.3l5.3,5.3l5.3-5.3L1065.8,304z"
        />

        <rect
          x="1056.6"
          y="311.8"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 87.6543 841.6955)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1059.8,311.2l3.9,3.9l-3.9,3.9l-3.9-3.9L1059.8,311.2 M1059.8,309.8l-5.3,5.3l5.3,5.3l5.3-5.3L1059.8,309.8z
			"
        />

        <rect
          x="1145.2"
          y="305.8"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 117.855 902.5734)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1148.5,305.2l3.9,3.9l-3.9,3.9l-3.9-3.9L1148.5,305.2 M1148.5,303.8l-5.3,5.3l5.3,5.3l5.3-5.3L1148.5,303.8z
			"
        />

        <rect
          x="1151.3"
          y="312"
          transform="matrix(0.7072 -0.707 0.707 0.7072 115.1662 908.5592)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1154.4,311.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1154.4,311.4 M1154.4,309.9l-5.3,5.3l5.3,5.3l5.3-5.3L1154.4,309.9z
			"
        />
        <polygon class="st4" points="1085,297.5 1089.6,292.9 1094.1,297.5 1089.4,301.9 		" />
        <path
          class="st0"
          d="M1089.6,293.6l3.9,3.9l-4,3.8l-3.7-3.7L1089.6,293.6 M1089.6,292.2l-5.3,5.3l5.1,5.1l5.5-5.1L1089.6,292.2
			L1089.6,292.2z"
        />

        <rect
          x="1080.2"
          y="300"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 102.9159 854.9373)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1083.4,299.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1083.4,299.4 M1083.4,298l-5.3,5.3l5.3,5.3l5.3-5.3L1083.4,298
			L1083.4,298z"
        />
        <polygon class="st4" points="1120.3,297.5 1124.9,292.9 1129.5,297.5 1124.9,301.9 		" />
        <path
          class="st0"
          d="M1124.9,293.6l3.9,3.9l-3.9,3.7l-3.9-3.7L1124.9,293.6 M1124.9,292.2l-5.3,5.3l5.3,5.1l5.3-5.1L1124.9,292.2z
			"
        />

        <rect
          x="1127.5"
          y="300.2"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 116.617 888.4404)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1130.7,299.6l3.9,3.9l-3.9,3.9l-3.9-3.9L1130.7,299.6 M1130.7,298.2l-5.3,5.3l5.3,5.3l5.3-5.3L1130.7,298.2z
			"
        />

        <rect
          x="1115.7"
          y="288.3"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 121.5605 876.6066)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1118.9,287.6l3.9,3.9l-3.9,3.9l-3.9-3.9L1118.9,287.6 M1118.9,286.2l-5.3,5.3l5.3,5.3l5.3-5.3L1118.9,286.2z
			"
        />

        <rect
          x="1109.9"
          y="282.3"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 124.1111 870.7506)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1113.1,281.6l3.9,3.9l-3.9,3.9l-3.9-3.9L1113.1,281.6 M1113.1,280.2l-5.3,5.3l5.3,5.3l5.3-5.3L1113.1,280.2z
			"
        />

        <rect
          x="1103.9"
          y="276.3"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 126.6008 864.7404)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1107.1,275.7l3.9,3.9l-3.9,3.9l-3.9-3.9L1107.1,275.7 M1107.1,274.2l-5.3,5.3l5.3,5.3l5.3-5.3L1107.1,274.2z
			"
        />
        <polygon class="st4" points="1096.7,285.5 1101.3,280.9 1105.8,285.5 1101.2,290.1 		" />
        <path
          class="st0"
          d="M1101.3,281.6l3.7,3.9l-3.9,3.9l-3.7-3.9L1101.3,281.6 M1101.3,280.2l-5.3,5.3l5.1,5.3l5.3-5.3L1101.3,280.2z
			"
        />
        <polygon class="st4" points="1090.8,291.5 1095.3,286.9 1099.8,291.5 1095.2,296.1 		" />
        <path
          class="st0"
          d="M1095.3,287.6l3.7,3.9l-3.9,3.9l-3.7-3.9L1095.3,287.6 M1095.3,286.2l-5.3,5.3l5.1,5.3l5.3-5.3L1095.3,286.2z
			"
        />
        <polygon class="st4" points="1090.8,303.3 1095.3,298.9 1099.9,303.5 1095.4,308 		" />
        <path
          class="st0"
          d="M1095.3,299.6l3.9,3.9l-3.9,3.9l-3.9-4L1095.3,299.6 M1095.3,298.2l-5.3,5.1l5.3,5.5l5.3-5.3L1095.3,298.2
			L1095.3,298.2z"
        />
        <polygon class="st4" points="1096.7,297.5 1101.3,292.9 1105.9,297.5 1101.2,301.9 		" />
        <path
          class="st0"
          d="M1101.3,293.6l3.9,3.9l-4,3.8l-3.7-3.7L1101.3,293.6 M1101.3,292.2l-5.3,5.3l5.1,5.1l5.5-5.1L1101.3,292.2
			L1101.3,292.2z"
        />

        <rect
          x="1103.9"
          y="288.2"
          transform="matrix(0.707 -0.7072 0.7072 0.707 118.2637 868.3638)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1107.1,287.6l3.9,3.9l-3.9,3.9l-3.9-3.9L1107.1,287.6 M1107.1,286.2l-5.3,5.3l5.3,5.3l5.3-5.3L1107.1,286.2z
			"
        />
        <polygon class="st4" points="1108.5,297.5 1113.1,292.9 1117.7,297.5 1113.1,301.9 		" />
        <path
          class="st0"
          d="M1113.1,293.6l3.9,3.9l-3.9,3.7l-3.9-3.7L1113.1,293.6 M1113.1,292.2l-5.3,5.3l5.3,5.1l5.3-5.1L1113.1,292.2z
			"
        />

        <rect
          x="1115.7"
          y="300.2"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 113.1631 880.0921)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1118.9,299.6l3.9,3.9l-3.9,3.9l-3.9-3.9L1118.9,299.6 M1118.9,298.2l-5.3,5.3l5.3,5.3l5.3-5.3L1118.9,298.2z
			"
        />

        <rect
          x="1115.7"
          y="311.9"
          transform="matrix(0.7072 -0.707 0.707 0.7072 104.8061 883.377)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1118.9,311.2l3.9,3.9l-3.9,3.9l-3.9-3.9L1118.9,311.2 M1118.9,309.8l-5.3,5.3l5.3,5.3l5.3-5.3L1118.9,309.8z
			"
        />

        <rect
          x="1121.6"
          y="306"
          transform="matrix(0.707 -0.7072 0.7072 0.707 110.8498 886.1317)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1124.9,305.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1124.9,305.4 M1124.9,304l-5.3,5.3l5.3,5.3l5.3-5.3L1124.9,304
			L1124.9,304z"
        />

        <rect
          x="1127.5"
          y="312.1"
          transform="matrix(0.7072 -0.707 0.707 0.7072 108.1112 891.7823)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1130.7,311.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1130.7,311.4 M1130.7,309.9l-5.3,5.3l5.3,5.3l5.3-5.3L1130.7,309.9z
			"
        />

        <rect
          x="1133.4"
          y="306"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 114.2496 894.2838)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1136.7,305.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1136.7,305.4 M1136.7,304l-5.3,5.3l5.3,5.3l5.3-5.3L1136.7,304z"
        />

        <rect
          x="1139.4"
          y="312"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 111.7576 900.2915)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1142.7,311.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1142.7,311.4 M1142.7,309.9l-5.3,5.3l5.3,5.3l5.3-5.3L1142.7,309.9z
			"
        />

        <rect
          x="1139.4"
          y="300"
          transform="matrix(0.707 -0.7072 0.7072 0.707 120.3156 896.918)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1142.7,299.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1142.7,299.4 M1142.7,298l-5.3,5.3l5.3,5.3l5.3-5.3L1142.7,298z"
        />
        <polygon class="st4" points="1132.1,297.5 1136.7,292.9 1141.3,297.5 1136.7,301.9 		" />
        <path
          class="st0"
          d="M1136.7,293.6l3.9,3.9l-3.9,3.7l-3.9-3.7L1136.7,293.6 M1136.7,292.2l-5.3,5.3l5.3,5.1l5.3-5.1L1136.7,292.2z
			"
        />

        <rect
          x="1127.5"
          y="288.3"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 125.0142 884.9516)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1130.7,287.6l3.9,3.9l-3.9,3.9l-3.9-3.9L1130.7,287.6 M1130.7,286.2l-5.3,5.3l5.3,5.3l5.3-5.3L1130.7,286.2z
			"
        />

        <rect
          x="1121.6"
          y="282.2"
          transform="matrix(0.707 -0.7072 0.7072 0.707 127.7001 879.1035)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1124.9,281.6l3.9,3.9l-3.9,3.9l-3.9-3.9L1124.9,281.6 M1124.9,280.2l-5.3,5.3l5.3,5.3l5.3-5.3L1124.9,280.2z
			"
        />

        <rect
          x="1115.7"
          y="276.4"
          transform="matrix(0.7072 -0.707 0.707 0.7072 129.8955 872.9858)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1118.9,275.7l3.9,3.9l-3.9,3.9l-3.9-3.9L1118.9,275.7 M1118.9,274.2l-5.3,5.3l5.3,5.3l5.3-5.3L1118.9,274.2z
			"
        />

        <rect
          x="1109.9"
          y="270.5"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 132.4594 867.2968)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1113.1,269.9l3.9,3.9l-3.9,3.9l-3.9-3.9L1113.1,269.9 M1113.1,268.4l-5.3,5.3l5.3,5.3l5.3-5.3L1113.1,268.4z
			"
        />

        <rect
          x="1103.9"
          y="264.4"
          transform="matrix(0.707 -0.7072 0.7072 0.707 135.1139 861.3907)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1107.1,263.9l3.9,3.9l-3.9,3.9l-3.9-3.9L1107.1,263.9 M1107.1,262.5l-5.3,5.3l5.3,5.3l5.3-5.3L1107.1,262.5z
			"
        />

        <rect
          x="1097.9"
          y="270.5"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 128.9411 858.7944)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1101.2,269.9l3.9,3.9l-3.9,3.9l-3.9-3.9L1101.2,269.9 M1101.2,268.4l-5.3,5.3l5.3,5.3l5.3-5.3L1101.2,268.4z
			"
        />
        <polygon class="st4" points="1090.8,279.7 1095.3,275.1 1099.8,279.7 1095.2,284.3 		" />
        <path
          class="st0"
          d="M1095.3,275.9l3.7,3.9l-3.9,3.9l-3.7-3.9L1095.3,275.9 M1095.3,274.4l-5.3,5.3l5.1,5.3l5.3-5.3L1095.3,274.4z
			"
        />
        <polygon class="st4" points="1084.9,285.5 1089.5,280.9 1094,285.5 1089.4,290.1 		" />
        <path
          class="st0"
          d="M1089.5,281.6l3.7,3.9l-3.9,3.9l-3.7-3.9L1089.5,281.6 M1089.5,280.2l-5.3,5.3l5.1,5.3l5.3-5.3L1089.5,280.2z
			"
        />

        <rect
          x="1080.2"
          y="288.3"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 111.1617 851.5103)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1083.4,287.6l3.9,3.9l-3.9,3.9l-3.9-3.9L1083.4,287.6 M1083.4,286.2l-5.3,5.3l5.3,5.3l5.3-5.3L1083.4,286.2z
			"
        />
        <polygon class="st4" points="1073,297.5 1077.6,292.9 1082.2,297.5 1077.6,301.9 		" />
        <path
          class="st0"
          d="M1077.6,293.6l3.9,3.9l-3.9,3.7l-3.9-3.7L1077.6,293.6 M1077.6,292.2l-5.3,5.3l5.3,5.1l5.3-5.1L1077.6,292.2z
			"
        />

        <rect
          x="1068.4"
          y="300"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 99.4622 846.5926)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1071.6,299.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1071.6,299.4 M1071.6,298l-5.3,5.3l5.3,5.3l5.3-5.3L1071.6,298z"
        />

        <rect
          x="1068.4"
          y="312.1"
          transform="matrix(0.7072 -0.707 0.707 0.7072 90.8044 850.0005)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1071.6,311.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1071.6,311.4 M1071.6,309.9l-5.3,5.3l5.3,5.3l5.3-5.3L1071.6,309.9z
			"
        />

        <rect
          x="1074.4"
          y="306"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 96.9666 852.597)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1077.6,305.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1077.6,305.4 M1077.6,304l-5.3,5.3l5.3,5.3l5.3-5.3L1077.6,304z"
        />

        <rect
          x="1080.4"
          y="312"
          transform="matrix(0.7072 -0.707 0.707 0.7072 94.4073 858.4282)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1083.6,311.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1083.6,311.4 M1083.6,309.9l-5.3,5.3l5.3,5.3l5.3-5.3L1083.6,309.9z
			"
        />

        <rect
          x="1086.3"
          y="306"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 100.4555 860.9943)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1089.6,305.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1089.6,305.4 M1089.6,304l-5.3,5.3l5.3,5.3l5.3-5.3L1089.6,304z"
        />
        <polygon class="st4" points="1090.8,315.1 1095.3,310.6 1099.9,315.2 1095.4,319.8 		" />
        <path
          class="st0"
          d="M1095.3,311.4l3.9,3.9l-3.9,3.9l-3.9-4L1095.3,311.4 M1095.3,310l-5.3,5.1l5.3,5.5l5.3-5.3L1095.3,310
			L1095.3,310z"
        />

        <rect
          x="1098.1"
          y="306"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 103.9093 869.3425)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1101.3,305.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1101.3,305.4 M1101.3,304l-5.3,5.3l5.3,5.3l5.3-5.3L1101.3,304z"
        />

        <rect
          x="1104.1"
          y="312.1"
          transform="matrix(0.7072 -0.707 0.707 0.7072 101.2575 875.238)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1107.3,311.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1107.3,311.4 M1107.3,309.9l-5.3,5.3l5.3,5.3l5.3-5.3L1107.3,309.9z
			"
        />

        <rect
          x="1103.9"
          y="300"
          transform="matrix(0.707 -0.7072 0.7072 0.707 109.9115 871.8206)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1107.1,299.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1107.1,299.4 M1107.1,298l-5.3,5.3l5.3,5.3l5.3-5.3L1107.1,298z"
        />

        <rect
          x="1109.9"
          y="306"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 107.3655 877.6932)"
          class="st4"
          width="6.5"
          height="6.5"
        />
        <path
          class="st0"
          d="M1113.1,305.4l3.9,3.9l-3.9,3.9l-3.9-3.9L1113.1,305.4 M1113.1,304l-5.3,5.3l5.3,5.3l5.3-5.3L1113.1,304z"
        />
        <polygon class="st4" points="1128,325.3 1130.9,322.4 1133.8,325.3 		" />
        <path
          class="st0"
          d="M1130.9,323.1l1.7,1.7h-3.4L1130.9,323.1 M1130.9,321.7l-4.1,4.1h8.2L1130.9,321.7z"
        />
        <polygon class="st4" points="1136.4,325.3 1132.1,321 1136.7,316.5 1141.3,321 1137,325.3 		" />
        <path
          class="st0"
          d="M1136.7,317.2l3.9,3.9l-3.8,3.8h-0.2l-3.8-3.8L1136.7,317.2 M1136.7,315.8l-5.3,5.3l4.8,4.8h1l4.8-4.8
			L1136.7,315.8L1136.7,315.8z"
        />
        <polygon class="st4" points="1139.8,325.3 1142.7,322.4 1145.6,325.3 		" />
        <path
          class="st0"
          d="M1142.7,323.1l1.7,1.7h-3.4L1142.7,323.1 M1142.7,321.7l-4.1,4.1h8.2L1142.7,321.7z"
        />
        <polygon
          class="st4"
          points="1148.2,325.3 1143.9,321 1148.5,316.5 1153.1,321 1148.8,325.3 		"
        />
        <path
          class="st0"
          d="M1148.5,317.2l3.9,3.9l-3.8,3.8h-0.2l-3.8-3.8L1148.5,317.2 M1148.5,315.8l-5.3,5.3l4.8,4.8h1l4.8-4.8
			L1148.5,315.8L1148.5,315.8z"
        />
        <polygon class="st4" points="1151.6,325.3 1154.4,322.4 1157.3,325.3 		" />
        <path
          class="st0"
          d="M1154.4,323.1l1.7,1.7h-3.4L1154.4,323.1 M1154.4,321.7l-4.1,4.1h8.2L1154.4,321.7z"
        />
        <polygon
          class="st4"
          points="1159.9,325.3 1155.7,321 1160.3,316.5 1164.8,321 1160.6,325.3 		"
        />
        <path
          class="st0"
          d="M1160.3,317.2l3.9,3.9l-3.8,3.8h-0.2l-3.8-3.8L1160.3,317.2 M1160.3,315.8l-5.3,5.2l4.8,4.8h1l4.8-4.8
			L1160.3,315.8L1160.3,315.8z"
        />
        <polygon class="st4" points="1163.5,325.3 1166.4,322.4 1169.3,325.3 		" />
        <path
          class="st0"
          d="M1166.4,323.1l1.7,1.7h-3.4L1166.4,323.1 M1166.4,321.7l-4.1,4.1h8.2L1166.4,321.7z"
        />
        <polygon class="st4" points="1175.3,325.3 1177.7,323 1179.9,325.3 		" />
        <path
          class="st0"
          d="M1177.7,323.7l1.1,1.2h-2.2L1177.7,323.7 M1177.7,322.2l-3.6,3.6h7L1177.7,322.2L1177.7,322.2z"
        />
        <polygon
          class="st4"
          points="1171.7,325.3 1167.6,321.1 1171.7,317 1176.1,321.5 1172.3,325.3 		"
        />
        <path
          class="st0"
          d="M1171.7,317.7l3.7,3.9l-3.3,3.3h-0.1l-3.6-3.8L1171.7,317.7 M1171.7,316.3l-4.8,4.8l4.6,4.8h1l4.3-4.3
			L1171.7,316.3L1171.7,316.3z"
        />

        <rect
          x="1163.1"
          y="312.1"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 118.519 916.8443)"
          class="st4"
          width="5.8"
          height="6.5"
        />
        <path
          class="st0"
          d="M1165.7,311.7l3.9,3.9l-3.4,3.4l-3.9-3.9L1165.7,311.7 M1165.7,310.3l-4.8,4.8l5.3,5.3l4.8-4.8L1165.7,310.3z
			"
        />

        <rect
          x="1157.1"
          y="306.3"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 120.8596 910.8984)"
          class="st4"
          width="5.8"
          height="6.5"
        />
        <path
          class="st0"
          d="M1159.7,305.9l3.9,3.9l-3.4,3.4l-3.9-3.9L1159.7,305.9 M1159.7,304.5l-4.8,4.8l5.3,5.3l4.8-4.8L1159.7,304.5z
			"
        />

        <rect
          x="1151.3"
          y="300.3"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 123.4125 905.0399)"
          class="st4"
          width="5.8"
          height="6.5"
        />
        <path
          class="st0"
          d="M1153.9,299.9l3.9,3.9l-3.4,3.4l-3.9-3.9L1153.9,299.9 M1153.9,298.5l-4.8,4.8l5.3,5.3l4.8-4.8L1153.9,298.5z
			"
        />
        <polygon class="st4" points="1144.1,297.5 1148.1,293.4 1152.6,298 1148.5,301.9 		" />
        <path
          class="st0"
          d="M1148.1,294.1l3.7,3.8l-3.3,3.2l-3.7-3.7L1148.1,294.1 M1148.1,292.7l-4.8,4.8l5.1,5.1l4.8-4.6L1148.1,292.7z
			"
        />

        <rect
          x="1139.4"
          y="288.5"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 128.2755 893.1888)"
          class="st4"
          width="5.8"
          height="6.5"
        />
        <path
          class="st0"
          d="M1142,288.1l3.9,3.9l-3.4,3.4l-3.9-3.9L1142,288.1 M1142,286.7l-4.8,4.8l5.3,5.3l4.8-4.8L1142,286.7z"
        />

        <rect
          x="1133.6"
          y="282.5"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 130.826 887.3325)"
          class="st4"
          width="5.8"
          height="6.5"
        />
        <path
          class="st0"
          d="M1136.2,282.1l3.9,3.9l-3.4,3.4l-3.9-3.9L1136.2,282.1 M1136.2,280.7l-4.8,4.8l5.3,5.3l4.8-4.8L1136.2,280.7z
			"
        />

        <rect
          x="1127.6"
          y="276.7"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 133.1689 881.3842)"
          class="st4"
          width="5.8"
          height="6.5"
        />
        <path
          class="st0"
          d="M1130.2,276.4l3.9,3.9l-3.4,3.4l-3.9-3.9L1130.2,276.4 M1130.2,274.9l-4.8,4.8l5.3,5.3l4.8-4.8L1130.2,274.9z
			"
        />

        <rect
          x="1121.6"
          y="270.8"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 135.5594 875.4057)"
          class="st4"
          width="5.8"
          height="6.5"
        />
        <path
          class="st0"
          d="M1124.2,270.4l3.9,3.9l-3.4,3.4l-3.9-3.9L1124.2,270.4 M1124.2,269l-4.8,4.8l5.3,5.3l4.8-4.8L1124.2,269z"
        />

        <rect
          x="1115.8"
          y="264.9"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 138.061 869.5799)"
          class="st4"
          width="5.8"
          height="6.5"
        />
        <path
          class="st0"
          d="M1118.4,264.6l3.9,3.9l-3.4,3.4l-3.9-3.9L1118.4,264.6 M1118.4,263.1l-4.8,4.8l5.3,5.3l4.8-4.8L1118.4,263.1z
			"
        />

        <rect
          x="1109.8"
          y="259"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 140.4481 863.6013)"
          class="st4"
          width="5.8"
          height="6.5"
        />
        <path
          class="st0"
          d="M1112.4,258.6l3.9,3.9l-3.4,3.4l-3.9-3.9L1112.4,258.6 M1112.4,257.2l-4.8,4.8l5.3,5.3l4.8-4.8L1112.4,257.2z
			"
        />
        <polygon class="st4" points="1103.1,256.5 1107.1,252.6 1111.2,256.6 1107.2,260.6 		" />
        <path
          class="st0"
          d="M1107.1,253.3l3.4,3.4l-3.3,3.2l-3.4-3.4L1107.1,253.3 M1107.1,251.9l-4.8,4.6l4.8,4.8l4.8-4.6L1107.1,251.9z
			"
        />

        <rect
          x="1098.2"
          y="259.3"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 137.2077 855.6383)"
          class="st4"
          width="6.5"
          height="5.8"
        />
        <path
          class="st0"
          d="M1101.7,258.6l3.3,3.4l-3.9,3.9l-3.4-3.4L1101.7,258.6 M1101.6,257.2l-5.3,5.3l4.8,4.8l5.3-5.3L1101.6,257.2z
			"
        />

        <rect
          x="1092.3"
          y="265.3"
          transform="matrix(0.707 -0.7072 0.7072 0.707 131.3016 853.3687)"
          class="st4"
          width="6.5"
          height="5.8"
        />
        <path
          class="st0"
          d="M1095.9,264.6l3.4,3.4l-3.9,3.9l-3.4-3.4L1095.9,264.6 M1095.9,263.1l-5.3,5.3l4.8,4.8l5.3-5.3L1095.9,263.1z
			"
        />

        <rect
          x="1086.4"
          y="271.4"
          transform="matrix(0.7072 -0.707 0.707 0.7072 125.0884 850.7013)"
          class="st4"
          width="6.5"
          height="5.8"
        />
        <path
          class="st0"
          d="M1089.9,270.5l3.4,3.4l-3.9,3.9l-3.4-3.4L1089.9,270.5 M1089.9,269.1l-5.3,5.3l4.8,4.8l5.3-5.3L1089.9,269.1z
			"
        />

        <rect
          x="1080.6"
          y="277.1"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 119.4514 848.4034)"
          class="st4"
          width="6.5"
          height="5.8"
        />
        <path
          class="st0"
          d="M1084.1,276.4l3.4,3.4l-3.9,3.9l-3.4-3.4L1084.1,276.4 M1084.1,274.9l-5.3,5.3l4.8,4.8l5.3-5.3L1084.1,274.9z
			"
        />

        <rect
          x="1074.6"
          y="283.1"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 113.447 845.9113)"
          class="st4"
          width="6.5"
          height="5.8"
        />
        <path
          class="st0"
          d="M1078.1,282.3l3.4,3.4l-3.9,3.9l-3.4-3.4L1078.1,282.3 M1078.1,280.9l-5.3,5.3l4.8,4.8l5.3-5.3L1078.1,280.9z
			"
        />

        <rect
          x="1068.6"
          y="288.9"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 107.586 843.3607)"
          class="st4"
          width="6.5"
          height="5.8"
        />
        <path
          class="st0"
          d="M1072.1,288.1l3.4,3.4l-3.9,3.9l-3.4-3.4L1072.1,288.1 M1072.1,286.7l-5.3,5.3l4.8,4.8l5.3-5.3L1072.1,286.7z
			"
        />
        <polygon class="st4" points="1061.7,298 1066.3,293.4 1070.4,297.5 1065.8,301.9 		" />
        <path
          class="st0"
          d="M1066.3,294.1l3.4,3.4l-3.9,3.8l-3.3-3.2L1066.3,294.1 M1066.3,292.7l-5.3,5.3l4.8,4.6l5.3-5.1L1066.3,292.7
			L1066.3,292.7z"
        />

        <rect
          x="1056.8"
          y="300.8"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 95.7374 838.4954)"
          class="st4"
          width="6.5"
          height="5.8"
        />
        <path
          class="st0"
          d="M1060.3,300.1l3.4,3.4l-3.9,3.9l-3.4-3.4L1060.3,300.1 M1060.3,298.7l-5.3,5.3l4.8,4.8l5.3-5.3L1060.3,298.7
			L1060.3,298.7z"
        />

        <rect
          x="1050.9"
          y="306.6"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 89.9105 836.0497)"
          class="st4"
          width="6.5"
          height="5.8"
        />
        <path
          class="st0"
          d="M1054.4,305.9l3.4,3.4l-3.9,3.9l-3.4-3.4L1054.4,305.9 M1054.4,304.5l-5.3,5.3l4.8,4.8l5.3-5.3L1054.4,304.5z
			"
        />

        <rect
          x="1045"
          y="312.6"
          transform="matrix(0.707 -0.7072 0.7072 0.707 83.9958 833.7764)"
          class="st4"
          width="6.5"
          height="5.8"
        />
        <path
          class="st0"
          d="M1048.6,311.9l3.4,3.4l-3.9,3.9l-3.4-3.4L1048.6,311.9 M1048.6,310.5l-5.3,5.3l4.8,4.8l5.3-5.3L1048.6,310.5z
			"
        />
        <polygon class="st4" points="1034.4,325.3 1036.8,323 1039.2,325.3 		" />
        <path
          class="st0"
          d="M1036.8,323.7l1.2,1.2h-2.3L1036.8,323.7 M1036.8,322.2l-3.6,3.6h7.2L1036.8,322.2L1036.8,322.2z"
        />
        <polygon class="st4" points="1041.8,325.3 1038,321.6 1042.6,317 1046.7,321 1042.4,325.3 		" />
        <path
          class="st0"
          d="M1042.6,317.7l3.4,3.4l-3.8,3.8h-0.2l-3.3-3.3L1042.6,317.7 M1042.6,316.3l-5.3,5.3l4.3,4.3h1l4.8-4.8
			L1042.6,316.3L1042.6,316.3z"
        />
        <polygon class="st4" points="1045.2,325.3 1048.1,322.4 1050.9,325.3 		" />
        <path
          class="st0"
          d="M1048,323.1l1.7,1.7h-3.4L1048,323.1 M1048,321.7l-4.1,4.1h8.2L1048,321.7z"
        />
        <polygon
          class="st4"
          points="1053.6,325.3 1049.3,321 1053.8,316.5 1058.4,321 1054.2,325.3 		"
        />
        <path
          class="st0"
          d="M1053.8,317.2l3.9,3.9l-3.8,3.8h-0.2l-3.8-3.8L1053.8,317.2 M1053.9,315.7l-5.3,5.3l4.8,4.8h1l4.8-4.8
			L1053.9,315.7L1053.9,315.7z"
        />
        <polygon class="st4" points="1057.1,325.3 1060,322.4 1062.9,325.3 		" />
        <path
          class="st0"
          d="M1060,323.1l1.7,1.7h-3.4L1060,323.1 M1060,321.7l-4.1,4.1h8.2L1060,321.7z"
        />
        <polygon
          class="st4"
          points="1065.5,325.3 1061.2,321 1065.8,316.5 1070.4,321 1066.1,325.3 		"
        />
        <path
          class="st0"
          d="M1065.8,317.2l3.9,3.9l-3.8,3.8h-0.2l-3.8-3.8L1065.8,317.2 M1065.8,315.8l-5.3,5.3l4.8,4.8h1l4.8-4.8
			L1065.8,315.8L1065.8,315.8z"
        />
        <polygon class="st4" points="1068.9,325.3 1071.8,322.4 1074.7,325.3 		" />
        <path
          class="st0"
          d="M1071.8,323.1l1.7,1.7h-3.4L1071.8,323.1 M1071.8,321.7l-4.1,4.1h8.2L1071.8,321.7z"
        />
        <polygon class="st4" points="1077.3,325.3 1073,321 1077.6,316.5 1082.2,321 1077.9,325.3 		" />
        <path
          class="st0"
          d="M1077.6,317.2l3.9,3.9l-3.8,3.8h-0.2l-3.8-3.8L1077.6,317.2 M1077.6,315.8l-5.3,5.2l4.8,4.8h1l4.8-4.8
			L1077.6,315.8L1077.6,315.8z"
        />
        <polygon class="st4" points="1080.7,325.3 1083.6,322.4 1086.5,325.3 		" />
        <path
          class="st0"
          d="M1083.6,323.1l1.7,1.7h-3.4L1083.6,323.1 M1083.6,321.7l-4.1,4.1h8.2L1083.6,321.7z"
        />
        <polygon
          class="st4"
          points="1100.9,325.3 1096.7,321.2 1101.3,316.6 1105.9,321.2 1101.8,325.3 		"
        />
        <path
          class="st0"
          d="M1101.3,317.3l3.9,3.9l-3.6,3.6h-0.5l-3.6-3.6L1101.3,317.3 M1101.3,315.9l-5.3,5.3l4.6,4.6h1.4l4.6-4.6
			L1101.3,315.9L1101.3,315.9z"
        />
        <polygon class="st4" points="1104.6,325.3 1107.3,322.6 1110,325.3 		" />
        <path
          class="st0"
          d="M1107.3,323.3l1.5,1.5h-3L1107.3,323.3 M1107.3,321.9l-3.9,3.9h7.9L1107.3,321.9L1107.3,321.9z"
        />
        <polygon class="st4" points="1092.6,325.3 1095.4,322.6 1098.1,325.3 		" />
        <path
          class="st0"
          d="M1095.3,323.3l1.5,1.5h-3L1095.3,323.3 M1095.4,321.9l-3.9,3.9h7.9L1095.4,321.9L1095.4,321.9z"
        />
        <polygon
          class="st4"
          points="1112.9,325.3 1108.5,321 1113.1,316.4 1117.7,321 1113.3,325.3 		"
        />
        <path
          class="st0"
          d="M1113.1,317.1l3.9,3.9l-3.9,3.9l0,0l-3.9-3.9L1113.1,317.1 M1113.1,315.7l-5.3,5.3l4.9,4.9h0.8l4.9-4.9
			L1113.1,315.7L1113.1,315.7z"
        />
        <polygon class="st4" points="1116.1,325.3 1119.1,322.4 1122.1,325.3 		" />
        <path
          class="st0"
          d="M1119.1,323.1l1.8,1.8h-3.5L1119.1,323.1 M1119.1,321.6l-4.2,4.2h8.4L1119.1,321.6L1119.1,321.6z"
        />
        <polygon
          class="st4"
          points="1124.6,325.3 1120.3,321 1124.9,316.5 1129.5,321 1125.2,325.3 		"
        />
        <path
          class="st0"
          d="M1124.9,317.2l3.9,3.9l-3.8,3.8h-0.2l-3.8-3.8L1124.9,317.2 M1124.9,315.7l-5.3,5.3l4.8,4.8h1l4.8-4.8
			L1124.9,315.7L1124.9,315.7z"
        />
        <polygon class="st4" points="1089.1,325.3 1084.8,321 1089.4,316.5 1094,321 1089.7,325.3 		" />
        <path
          class="st0"
          d="M1089.4,317.2l3.9,3.9l-3.8,3.8h-0.2l-3.8-3.8L1089.4,317.2 M1089.4,315.7l-5.3,5.3l4.8,4.8h1l4.8-4.8
			L1089.4,315.7L1089.4,315.7z"
        />
        <rect x="1506.3" y="267.5" class="st0" width="1.2" height="8.7" />
        <rect x="1516.8" y="267.5" class="st0" width="1.2" height="8.7" />
        <rect x="1540.8" y="267.5" class="st0" width="1.2" height="8.7" />
        <rect x="1551.3" y="267.5" class="st0" width="1.2" height="8.7" />
        <rect x="1561.9" y="267.5" class="st0" width="1.2" height="8.7" />
        <rect x="1586.4" y="267.5" class="st0" width="1.2" height="8.7" />
        <rect x="1597" y="267.5" class="st0" width="1.2" height="8.7" />
        <rect x="1503.7" y="256.5" class="st3" width="134.4" height="71" />
        <rect x="1505.8" y="231.6" class="st3" width="130" height="36.8" />
        <rect x="1509.7" y="209.1" class="st3" width="121.7" height="26.9" />
        <rect x="1511.3" y="204.7" class="st3" width="43.1" height="26.9" />
        <rect x="1587.7" y="204.7" class="st3" width="43.1" height="26.9" />
        <rect x="1513" y="155.6" class="st3" width="38" height="49.5" />
        <rect x="1590.3" y="155.6" class="st3" width="38" height="49.5" />
        <path
          class="st5"
          d="M1570.8,236c-7.5,0-13.6,6.1-13.6,13.6s6.1,13.6,13.6,13.6s13.6-6.1,13.6-13.6l0,0
			C1584.4,242.1,1578.4,236,1570.8,236z M1583.3,249.6c0,1.1-0.2,2.2-0.4,3.4l-4.7-1.6c0.2-0.6,0.2-1.2,0.2-1.8c0-0.3,0-0.7-0.2-1
			l4.9-1C1583.3,248.2,1583.3,248.9,1583.3,249.6L1583.3,249.6z M1558.4,249.6c0-1.1,0.2-2.2,0.4-3.4l4.7,1.6
			c-0.2,0.6-0.2,1.2-0.2,1.8c0,0.3,0,0.7,0.2,1l-4.9,1C1558.6,251,1558.4,250.2,1558.4,249.6L1558.4,249.6z M1577,247.6l-4.1,0.8
			l3.2-2.8C1576.4,246.2,1576.6,247,1577,247.6L1577,247.6z M1576.8,245l3.7-3.4c1.2,1.4,2,3,2.4,4.7l-4.9,1
			C1577.8,246.5,1577.4,245.7,1576.8,245L1576.8,245z M1572,247.8l1.4-3.9c0.6,0.3,1.2,0.7,1.8,1.2L1572,247.8z M1571,247.4
			l-0.8-4.1h0.8c0.5,0,0.9,0,1.4,0.2L1571,247.4z M1569.8,247.6l-2.8-3.1c0.6-0.5,1.3-0.8,2-1L1569.8,247.6z M1566.3,243.7l-3.3-3.7
			c1.4-1.2,3-2,4.7-2.4l1,4.9C1567.8,242.7,1567,243.1,1566.3,243.7L1566.3,243.7z M1569,248.4l-3.9-1.4c0.3-0.6,0.7-1.2,1.2-1.8
			L1569,248.4z M1568.6,249.4l-4.1,0.8v-0.8c0-0.5,0-0.9,0.2-1.4L1568.6,249.4z M1563.7,251.6c0.1,0.9,0.6,1.7,1.2,2.4l-3.7,3.5
			c-1.2-1.4-2-3-2.4-4.7L1563.7,251.6z M1564.9,251.4l4.1-0.8l-3.2,2.8C1565.4,252.8,1565,252.1,1564.9,251.4L1564.9,251.4z
			 M1569.6,251.4l-1.4,3.9c-0.6-0.3-1.2-0.7-1.8-1.2L1569.6,251.4z M1570.6,251.8l0.8,4.1h-0.8c-0.5,0-0.9,0-1.4-0.2L1570.6,251.8z
			 M1571.8,251.4l2.8,3.1c-0.6,0.5-1.3,0.8-2,1L1571.8,251.4z M1575.3,255.3l3.3,3.7c-1.4,1.2-3,2-4.7,2.4l-1-4.7
			C1573.8,256.3,1574.6,255.8,1575.3,255.3L1575.3,255.3z M1572.6,250.8l3.9,1.4c-0.3,0.6-0.7,1.2-1.2,1.8L1572.6,250.8z
			 M1573,249.6l4.1-0.8v0.8c0,0.5,0,0.9-0.2,1.4L1573,249.6z M1579.7,240.9l-3.7,3.1c-0.7-0.7-1.5-1.2-2.4-1.6l1.6-4.7
			C1576.9,238.5,1578.5,239.5,1579.7,240.9L1579.7,240.9z M1574.2,237.6l-1.6,4.7c-0.6-0.2-1.2-0.2-1.8-0.2c-0.3,0-0.7,0-1,0.2
			l-1-4.9c0.7-0.2,1.3-0.2,2-0.2C1571.9,237.2,1573.1,237.3,1574.2,237.6L1574.2,237.6z M1562.2,240.7l3.3,3.7
			c-0.7,0.7-1.2,1.5-1.6,2.4l-4.7-1.6C1559.9,243.6,1560.9,242,1562.2,240.7L1562.2,240.7z M1562,258.3l3.7-3.4
			c0.7,0.7,1.5,1.2,2.4,1.6l-1.6,4.7C1564.8,260.5,1563.3,259.5,1562,258.3L1562,258.3z M1567.5,261.4l1.6-4.7
			c0.6,0.2,1.2,0.2,1.8,0.2h1l1,4.9c-0.7,0.2-1.3,0.2-2,0.2C1569.7,262,1568.6,261.8,1567.5,261.4L1567.5,261.4z M1579.5,258.3
			l-3.3-3.7c0.7-0.7,1.2-1.5,1.6-2.4l4.7,1.6C1581.9,255.5,1580.9,257,1579.5,258.3L1579.5,258.3z"
        />
        <path
          class="st5"
          d="M1532.8,248.8c2.4,0,4.3-1.9,4.3-4.3c0-2.4-1.9-4.3-4.3-4.3s-4.3,1.9-4.3,4.3l0,0
			C1528.5,246.8,1530.4,248.8,1532.8,248.8z M1532.8,241.1c1.8,0,3.3,1.5,3.3,3.3c0,0,0,0,0,0.1c0,1.9-1.5,3.4-3.3,3.4
			c-1.9,0-3.4-1.5-3.4-3.3l0,0C1529.5,242.6,1530.9,241.1,1532.8,241.1L1532.8,241.1L1532.8,241.1z"
        />
        <path
          class="st5"
          d="M1609.3,239.9c-2.4,0-4.3,1.9-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3s4.3-1.9,4.3-4.3l0,0
			C1613.6,241.9,1611.7,239.9,1609.3,239.9z M1609.3,247.6c-1.8,0-3.3-1.5-3.3-3.3c0,0,0,0,0-0.1c0-1.9,1.5-3.4,3.3-3.4
			c1.9,0,3.4,1.5,3.4,3.3l0,0C1612.6,246.1,1611.2,247.6,1609.3,247.6L1609.3,247.6z"
        />
        <rect x="1527.3" y="269.7" class="st5" width="1.2" height="8.3" />
        <rect x="1537.3" y="269.7" class="st5" width="1.2" height="8.3" />
        <rect x="1560.2" y="269.7" class="st5" width="1.2" height="8.3" />
        <rect x="1570.3" y="269.7" class="st5" width="1.2" height="8.3" />
        <rect x="1580.3" y="269.7" class="st5" width="1.2" height="8.3" />
        <rect x="1603.8" y="269.7" class="st5" width="1.2" height="8.3" />
        <rect x="1613.8" y="269.7" class="st5" width="1.2" height="8.3" />
        <path
          class="st5"
          d="M1638.4,325.5v-67.8h1.6v-1.2h-3.7v-23.6h1.6v-1.2h-5.1v-27.6h-3v-54.6h-2.7v4.7h-34.5v-4.7h-2.8v54.6h-3v4.5
			h-14.4V196h4.1v-2.8h-4.1v-4.3h-2.8v4.3h-4.1v2.8h4.1v12.6h-14.4v-4.5h-3v-54.6h-2.8v4.7H1515v-4.7h-2.8v54.6h-3v27.6h-5.1v1.2
			h1.6v23.7h-3.7v1.2h1.6v67.8h-3v2.8h140.7v-2.8h-2.9L1638.4,325.5L1638.4,325.5z M1637.3,257.7v67.8h-4.7v-68h4.7V257.7z
			 M1611.8,325.5h-4.3v-13.6h4.3V325.5z M1606.3,310.9v14.6h-10.8V307c1.4-4.7,5.1-12.2,14.2-14.8c9.7,2.8,13.2,10.8,14.4,15.4v17.9
			h-11v-14.6H1606.3z M1624.1,304c-2-4.7-5.9-10.8-14.2-13.2h-0.4c-8.1,2.2-12,8.1-14,12.6v-21.7h28.6V304z M1595.3,279.1v-11h28.6
			v11H1595.3z M1578.9,325.5h-4.3v-15.8h4.3V325.5z M1573.4,308.5v17h-5.1v-17h-6.7v17h-6.3v-24.2c1.4-4.9,5.3-13.4,15.6-16.4
			c11,3.1,14.8,13,16,17.5v22.9h-6.7v-16.9h-6.7L1573.4,308.5z M1567.1,325.5h-4.3v-15.8h4.3V325.5z M1586.8,298.8
			c-2-5.3-6.3-12.4-15.8-15h-0.4c-7,2-12.7,7.2-15.4,14v-16h31.5v17H1586.8z M1555.3,279.1v-11h31.5v11H1555.3z M1534.8,325.4h-4.3
			v-13.6h4.3V325.4z M1529.3,310.9v14.6h-11v-17.9c1.2-4.5,4.7-12.6,14.4-15.6c9.3,2.8,12.8,10.2,14.2,14.8v18.5H1536v-14.6h-6.7
			V310.9z M1546.8,303.6c-2.4-6.2-7.6-10.8-14-12.6h-0.4c-6.6,1.8-11.9,6.7-14.2,13.2v-22.3h28.6V303.6z M1518,279.1v-11h28.6v11
			H1518z M1512.3,232.6h3v32.9h-3V232.6z M1523.5,176.5c0-5.5,1-8.9,2.6-8.9s2.4,3.4,2.6,8.9v27.6h-4.9
			C1523.6,199.5,1523.6,184.3,1523.5,176.5L1523.5,176.5z M1546.8,206.8v24H1518v-24H1546.8z M1536.1,176.5c0-5.5,1-8.9,2.6-8.9
			s2.4,3.4,2.6,8.9v27.6h-4.9C1536.2,199.5,1536.2,184.3,1536.1,176.5L1536.1,176.5z M1571,210.8L1571,210.8l15.2,20.5h-30.3
			L1571,210.8L1571,210.8z M1601,176.5c0-5.5,1-8.9,2.6-8.9s2.4,3.4,2.6,8.9V204h-4.9C1600.8,199.5,1600.8,184.3,1601,176.5
			L1601,176.5z M1624.1,206.8v24h-28.8v-24H1624.1z M1613.4,176.5c0-5.5,1-8.9,2.6-8.9s2.4,3.4,2.6,8.9V204h-4.9
			C1613.4,199.5,1613.4,184.3,1613.4,176.5L1613.4,176.5z M1629.8,265.3h-3v-32.7h3V265.3z M1595.3,233.6h28.6V251
			c-1.6-4.7-5.5-12-14.6-14.6h-0.4c-6.3,1.7-11.4,6.3-13.8,12.4v-15.2L1595.3,233.6L1595.3,233.6z M1600.6,265.3v-5.5
			c0-5.5,1-8.9,2.6-8.9s2.4,3.4,2.6,8.9v5.5H1600.6z M1612.8,265.3v-5.5c0-5.5,1-8.9,2.6-8.9s2.4,3.4,2.6,8.9v5.5H1612.8z
			 M1618.9,265.3v-5.5c0-6.5-1.2-9.9-3.6-9.9s-3.6,3.4-3.6,9.9v5.5h-4.9v-5.5c0-6.5-1.2-9.9-3.6-9.9s-3.6,3.4-3.6,9.9v5.5h-4.1
			v-13.4c1.4-4.5,4.9-11.8,14-14.4c13.2,3.9,14.8,18.1,14.8,18.1v9.7L1618.9,265.3L1618.9,265.3z M1592.5,265.3h-3v-32.7h3V265.3z
			 M1555.3,233.2h31.5v32.1h-31.5V233.2z M1552.5,265.3h-3v-32.7h3V265.3z M1518,255.9c0-0.2,1.4-14.4,14.8-18.3
			c8.9,2.6,12.6,9.9,14,14.4v13.4h-4.1v-5.5c0-6.5-1.2-9.9-3.6-9.9s-3.6,3.4-3.6,9.9v5.5h-4.9v-5.5c0-6.5-1.2-9.9-3.6-9.9
			s-3.6,3.4-3.6,9.9v5.5h-5.1L1518,255.9z M1536.3,265.3v-5.5c0-5.5,1-8.9,2.6-8.9s2.4,3.4,2.6,8.9v5.5H1536.3z M1524.3,265.3v-5.5
			c0-5.5,1-8.9,2.6-8.9s2.4,3.4,2.6,8.9v5.5H1524.3z M1546.8,248.8c-2.5-6-7.6-10.6-13.8-12.4h-0.4c-9.1,2.6-13,9.7-14.6,14.6v-17.5
			h28.6v15.4h0.2L1546.8,248.8L1546.8,248.8z M1512.7,268.3h2.6v11h-2.6V268.3z M1549.6,268.3h3v11h-3V268.3z M1589.6,268.3h3v11h-3
			V268.3z M1626.8,268.3h3v11h-3V268.3z M1635.1,232.8v23.6h-2.4v-23.6L1635.1,232.8z M1629.8,206.8v22.9h-3v-22.9H1629.8z
			 M1592.5,157h34.5v47.1h-7.3v-27.6c0-6.5-1.2-9.9-3.6-9.9s-3.6,3.4-3.6,9.9v27.6h-5.3v-27.6c0-6.5-1.2-9.9-3.6-9.9
			s-3.6,3.4-3.6,9.9v27.6h-7.3V157H1592.5z M1589.5,206.9h3v22.9h-3V206.9L1589.5,206.9z M1586.8,228.7l-13.2-17.9h13.2V228.7z
			 M1568.4,210.8l-13.2,17.9v-18L1568.4,210.8z M1552.4,206.9v22.9h-3v-22.9H1552.4z M1515.1,157h34.5v47.1h-7.3v-27.6
			c0-6.5-1.2-9.9-3.6-9.9s-3.6,3.4-3.6,9.9v27.6h-5.5v-27.6c0-6.5-1.2-9.9-3.6-9.9s-3.6,3.4-3.6,9.9v27.6h-7.3V157L1515.1,157z
			 M1512.3,206.9h3v22.9h-3V206.9L1512.3,206.9z M1507,232.9h2.4v23.7h-2.4V232.9z M1504.8,257.7h4.5v67.8h-4.7v-67.8H1504.8z
			 M1512.3,325.5v-43.6h3v43.5L1512.3,325.5z M1549.6,325.5v-43.6h3v43.5L1549.6,325.5z M1589.6,325.5v-43.6h3v43.5L1589.6,325.5z
			 M1626.8,325.5v-43.6h3v43.5L1626.8,325.5z"
        />
        <rect x="649.9" y="184.8" class="st3" width="146.5" height="6.6" />
        <rect x="649.9" y="206.3" class="st3" width="146.5" height="6.6" />
        <rect x="741.9" y="256.7" class="st3" width="51.8" height="5.3" />
        <rect x="651.7" y="256.7" class="st3" width="51.8" height="5.3" />
        <rect x="741.9" y="321.6" class="st3" width="51.8" height="7.6" />
        <rect x="653.5" y="321.6" class="st3" width="51.8" height="7.6" />
        <rect x="653.5" y="188.2" class="st3" width="138.2" height="47.5" />
        <rect x="744.9" y="221.6" class="st3" width="46.9" height="103.8" />
        <rect x="654.7" y="221.6" class="st3" width="46.9" height="103.8" />
        <path
          class="st3"
          d="M700.6,257.8c0,0,1.7-21.7,22-21.7s22.3,20.6,22.3,20.6H755V231h-63.8v26.7L700.6,257.8z"
        />
        <path
          class="st0"
          d="M746.5,256.3c-0.2-9.6-7.1-18.1-16-21c-9.1-2.9-19,0-25.1,7.2c-0.4,0.5-0.8,1.2-1.2,1.7l2.2,1.7l0,0l-2.4-1.4
			c-1,1.7-1.9,3.4-2.6,5.2c-0.7,2-1.2,4.1-1.3,6.3c-0.1,1.8,2.7,1.8,2.8,0c0.1-1.9,0.5-3.8,1.2-5.6c0.1-0.2,0.1-0.3,0.2-0.4
			c0.5-1.1-0.2,0.4,0.2-0.5c0.2-0.4,0.3-0.8,0.5-1.1c0.4-0.8,0.9-1.6,1.4-2.4c0.4-0.7,0.1-1.5-0.6-1.9c-0.6-0.3-1.4-0.1-1.8,0.5l0,0
			c-0.8,1.3,1.2,3,2.2,1.7c1.2-1.6,2.5-3.1,4-4.5c1.6-1.3,3.3-2.4,5.2-3.2c4.1-1.7,8.6-2.1,12.9-1c8.5,2,15.3,9.9,15.5,18.8
			c0.1,0.8,0.7,1.4,1.5,1.3C745.8,257.5,746.4,257,746.5,256.3L746.5,256.3z"
        />
        <path
          class="st0"
          d="M694.8,261.3v60.4c0,0.8,0.6,1.4,1.4,1.4h5.2c0.8,0,1.4-0.6,1.4-1.4v-60.4c0-0.8-0.6-1.4-1.4-1.4h-5.2
			c-0.8,0.1-1.4,0.7-1.3,1.5c0.1,0.7,0.6,1.2,1.3,1.3h5.2l-1.4-1.4v60.4l1.4-1.4h-5.2l1.4,1.4v-60.4c0-0.8-0.6-1.4-1.4-1.4
			C695.4,259.9,694.8,260.5,694.8,261.3z"
        />
        <path
          class="st0"
          d="M686.6,278.8c0-6-4.9-10.9-10.9-10.9s-10.9,4.9-10.9,10.9v25.6l1.4-1.4H665c-0.8,0-1.4,0.6-1.4,1.4v0.1
			c0,0.8,0.6,1.4,1.4,1.4h21.2c0.8,0,1.4-0.6,1.4-1.4v-0.1c0-0.8-0.6-1.4-1.4-1.4h-1.1l1.4,1.4L686.6,278.8
			c-0.1-0.8-0.8-1.4-1.5-1.3s-1.2,0.6-1.3,1.3v25.6c0,0.8,0.6,1.4,1.4,1.4h1.1l-1.4-1.4v0.1l1.4-1.4H665l1.4,1.4v-0.1l-1.4,1.4h1.1
			c0.8,0,1.4-0.6,1.4-1.4v-25.6c0-2.5,1.2-4.9,3.1-6.4c2.3-1.8,5.4-2.2,8.1-1.1c3,1.3,5,4.2,5,7.5c0,0.8,0.6,1.4,1.4,1.4
			C685.9,280.2,686.6,279.6,686.6,278.8z"
        />
        <path
          class="st0"
          d="M694.8,261.3v60.4l1.4-1.4h-9.9l1.4,1.4v-17.2c0-0.8-0.6-1.4-1.4-1.4h-1.1l1.4,1.4v-23.3c0-1.1,0-2.2,0-3.4
			c-0.6-6-5.9-10.4-11.9-9.9c-2.3,0.2-4.5,1.2-6.3,2.7c-2.3,2.1-3.7,5.1-3.7,8.2v25.5l1.4-1.4H665c-0.8,0-1.4,0.6-1.4,1.4v17.3
			l1.4-1.4h-10l1.4,1.4v-60.4l-1.4,1.4h41.2c0.8-0.1,1.4-0.7,1.3-1.5c-0.1-0.7-0.6-1.2-1.3-1.3H655c-0.8,0-1.4,0.6-1.4,1.4v60.4
			c0,0.8,0.6,1.4,1.4,1.4h10c0.8,0,1.4-0.6,1.4-1.4v-17.3l-1.4,1.4h1.1c0.8,0,1.4-0.6,1.4-1.4V281c0-1.1,0-2.1,0-3.2
			c0.5-4.5,4.5-7.7,9-7.2c2.6,0.3,4.9,1.8,6.2,4.1c0.8,1.6,1.1,3.4,1,5.1v24.4c0,0.8,0.6,1.4,1.4,1.4h1.1l-1.4-1.4v17.3
			c0,0.8,0.6,1.4,1.4,1.4h9.9c0.8,0,1.4-0.6,1.4-1.4v-60.4c-0.2-0.8-1-1.2-1.7-1C695.3,260.4,694.9,260.8,694.8,261.3z"
        />
        <path
          class="st0"
          d="M743.7,261.6v60.1c0,0.8,0.6,1.4,1.4,1.4h5.2c0.8,0,1.4-0.6,1.4-1.4v-60.1c0-0.8-0.6-1.4-1.4-1.4h-5.2
			c-0.8,0.1-1.4,0.7-1.3,1.5c0.1,0.7,0.6,1.2,1.3,1.3h5.2l-1.4-1.4v60.1l1.4-1.4h-5.2l1.4,1.4v-60c0-0.8-0.6-1.4-1.4-1.4
			C744.3,260.2,743.7,260.9,743.7,261.6z"
        />
        <path
          class="st0"
          d="M790.1,261.6v60.1l1.4-1.4h-10l1.4,1.4v-17.2c0-0.8-0.6-1.4-1.4-1.4h-1.1l1.4,1.4v-23.3c0-1.1,0-2.1,0-3.2
			c-0.4-6-5.7-10.6-11.7-10.1c-2.3,0.2-4.5,1.1-6.3,2.6c-2.4,2.1-3.8,5.1-3.8,8.3v25.6l1.4-1.4h-1.1c-0.8,0-1.4,0.6-1.4,1.4v17.2
			l1.4-1.4h-10l1.4,1.4v-60.1l-1.5,1.4h41.2c0.8-0.1,1.4-0.7,1.3-1.5c-0.1-0.7-0.6-1.2-1.3-1.3h-41.2c-0.8,0-1.4,0.6-1.4,1.4v60.1
			c0,0.8,0.6,1.4,1.4,1.4h10c0.8,0,1.4-0.6,1.4-1.4v-17.1l-1.4,1.4h1.1c0.8,0,1.4-0.6,1.4-1.4v-23.3c0-1,0-2.1,0-3.1
			c0.4-4.5,4.3-7.8,8.8-7.4c2.6,0.2,5,1.7,6.3,3.9c0.9,1.6,1.3,3.4,1.2,5.3v24.6c0,0.8,0.6,1.4,1.4,1.4h1.1l-1.5-1.4v17.2
			c0,0.8,0.6,1.4,1.4,1.4h10c0.8,0,1.4-0.6,1.4-1.4v-60.1c-0.2-0.8-0.9-1.2-1.7-1.1C790.6,260.7,790.2,261.1,790.1,261.6z"
        />
        <path
          class="st0"
          d="M790.1,191.8v14.4l1.4-1.4H655l1.4,1.4v-14.4l-1.4,1.4h136.5c0.8-0.1,1.4-0.7,1.3-1.5
			c-0.1-0.7-0.6-1.2-1.3-1.3H655c-0.8,0-1.4,0.6-1.4,1.4v14.4c0,0.8,0.6,1.4,1.4,1.4h136.5c0.8,0,1.4-0.6,1.4-1.4v-14.4
			c-0.1-0.8-0.7-1.4-1.5-1.3C790.7,190.6,790.1,191.1,790.1,191.8z"
        />
        <path
          class="st0"
          d="M691.9,303.1h-5.6c-0.8,0.1-1.4,0.7-1.3,1.5c0.1,0.7,0.6,1.2,1.3,1.3h5.6c0.8-0.1,1.4-0.7,1.3-1.5
			C693.1,303.7,692.6,303.2,691.9,303.1z"
        />
        <path
          class="st0"
          d="M665,303.1h-5.6c-0.8,0.1-1.4,0.7-1.3,1.5c0.1,0.7,0.6,1.2,1.3,1.3h5.6c0.8-0.1,1.4-0.7,1.3-1.5
			C666.2,303.7,665.7,303.2,665,303.1z"
        />
        <path
          class="st0"
          d="M683.8,278.8v25.7c0.1,0.8,0.7,1.4,1.5,1.3c0.7-0.1,1.2-0.6,1.3-1.3v-25.7c-0.1-0.8-0.7-1.4-1.5-1.3
			C684.4,277.6,683.8,278.1,683.8,278.8z"
        />
        <path
          class="st0"
          d="M667.5,304.5V279c0-1.6,0.4-3.1,1.2-4.5c2.4-3.8,7.5-5,11.3-2.5c0.5,0.3,0.9,0.7,1.3,1.1c1.3,1.3,3.3-0.7,2-2
			c-4.3-4.3-11.2-4.3-15.5,0c-1.4,1.4-2.4,3.1-2.9,5c-0.3,1.4-0.4,2.9-0.3,4.3v24.1c0.1,0.8,0.7,1.4,1.5,1.3
			C666.9,305.7,667.5,305.2,667.5,304.5L667.5,304.5z"
        />
        <path
          class="st0"
          d="M684.9,304.5v17.2l1.4-1.4H665l1.4,1.4v-17.2c-0.1-0.8-0.7-1.4-1.5-1.3c-0.7,0.1-1.2,0.6-1.3,1.3v17.2
			c0,0.8,0.6,1.4,1.4,1.4h21.2c0.8,0,1.4-0.6,1.4-1.4v-17.2c-0.1-0.8-0.7-1.4-1.5-1.3C685.4,303.3,684.9,303.8,684.9,304.5
			L684.9,304.5z"
        />
        <path
          class="st0"
          d="M787,303.1h-5.6c-0.8,0.1-1.4,0.7-1.3,1.5c0.1,0.7,0.6,1.2,1.3,1.3h5.6c0.8-0.1,1.4-0.7,1.3-1.5
			C788.2,303.7,787.7,303.2,787,303.1z"
        />
        <path
          class="st0"
          d="M760.2,303.1h-5.7c-0.8,0.1-1.4,0.7-1.3,1.5c0.1,0.7,0.6,1.2,1.3,1.3h5.7c0.8-0.1,1.4-0.7,1.3-1.5
			C761.4,303.7,760.9,303.2,760.2,303.1z"
        />
        <path
          class="st0"
          d="M779,278.8v25.7l1.4-1.4h-19.1l1.4,1.4v-25.7c0-4.5,3.6-8.1,8.1-8.2c1,0,2.1,0.2,3,0.6
			C776.9,272.5,778.9,275.5,779,278.8c0,0.8,0.7,1.4,1.5,1.3c0.7-0.1,1.2-0.6,1.3-1.3c0-6-4.9-10.9-10.9-10.9s-10.9,4.9-10.9,10.9
			v25.7c0,0.8,0.6,1.4,1.4,1.4h19.1c0.8,0,1.4-0.6,1.4-1.4v-25.7c-0.2-0.8-0.9-1.3-1.7-1.1C779.5,277.8,779.1,278.2,779,278.8z"
        />
        <path
          class="st0"
          d="M780,304.5v17.2l1.4-1.4h-21.2l1.4,1.4v-17.2c-0.1-0.8-0.7-1.4-1.5-1.3c-0.7,0.1-1.2,0.6-1.3,1.3v17.2
			c0,0.8,0.6,1.4,1.4,1.4h21.2c0.8,0,1.4-0.6,1.4-1.4v-17.2c-0.1-0.8-0.7-1.4-1.5-1.3C780.7,303.3,780.1,303.8,780,304.5L780,304.5z
			"
        />
        <path
          class="st0"
          d="M668.4,198.9c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C666.2,197.5,668.3,197.5,668.4,198.9
			c0.1,1.8,2.9,1.8,2.8,0c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9c0,2.2,1.8,3.9,3.9,3.9l0,0c2.1,0,3.9-1.8,3.9-3.9
			C671.3,197.1,668.5,197.1,668.4,198.9z"
        />
        <path
          class="st0"
          d="M684.4,198.9c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C682.1,197.5,684.3,197.5,684.4,198.9
			c0.1,1.8,2.9,1.8,2.8,0c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9c0,2.2,1.8,3.9,3.9,3.9l0,0c2.1,0,3.9-1.8,3.9-3.9
			C687.3,197.1,684.5,197.1,684.4,198.9z"
        />
        <path
          class="st0"
          d="M700.3,198.9c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C698.1,197.5,700.3,197.5,700.3,198.9
			c0.1,1.8,2.9,1.8,2.8,0c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9c0,2.2,1.8,3.9,3.9,3.9l0,0c2.1,0,3.9-1.8,3.9-3.9
			C703.2,197.1,700.4,197.1,700.3,198.9z"
        />
        <path
          class="st0"
          d="M716.3,198.9c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C714.1,197.5,716.3,197.5,716.3,198.9
			c0.1,1.8,2.9,1.8,2.8,0c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9c0,2.2,1.8,3.9,3.9,3.9l0,0c2.1,0,3.9-1.8,3.9-3.9
			C719.2,197.1,716.4,197.1,716.3,198.9z"
        />
        <path
          class="st0"
          d="M732.3,198.9c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C730,197.5,732.2,197.5,732.3,198.9
			c0.1,1.8,2.9,1.8,2.8,0c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9c0,2.2,1.8,3.9,3.9,3.9l0,0c2.1,0,3.9-1.8,3.9-3.9
			C735.2,197.1,732.4,197.1,732.3,198.9z"
        />
        <path
          class="st0"
          d="M748.3,198.9c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C746,197.5,748.2,197.5,748.3,198.9
			c0.1,1.8,2.9,1.8,2.8,0c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9c0,2.2,1.8,3.9,3.9,3.9l0,0c2.1,0,3.9-1.8,3.9-3.9
			C751.2,197.1,748.3,197.1,748.3,198.9z"
        />
        <path
          class="st0"
          d="M764.2,198.9c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C762,197.5,764.2,197.5,764.2,198.9
			c0.1,1.8,2.9,1.8,2.8,0c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9c0,2.2,1.8,3.9,3.9,3.9l0,0c2.1,0,3.9-1.8,3.9-3.9
			C767.1,197.1,764.3,197.1,764.2,198.9z"
        />
        <path
          class="st0"
          d="M780.2,198.9c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C778,197.5,780.1,197.5,780.2,198.9
			c0.1,1.8,2.9,1.8,2.8,0c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9c0,2.2,1.8,3.9,3.9,3.9l0,0c2.1,0,3.9-1.8,3.9-3.9
			C783.1,197.1,780.3,197.1,780.2,198.9z"
        />
        <path
          class="st0"
          d="M703.5,321.7v7.2l1.4-1.4H653l1.4,1.4v-7.2l-1.4,1.4h52c0.8-0.1,1.4-0.7,1.3-1.5c-0.1-0.7-0.6-1.2-1.3-1.3
			h-51.9c-0.8,0-1.4,0.6-1.4,1.4v7.2c0,0.8,0.6,1.4,1.4,1.4H705c0.8,0,1.4-0.6,1.4-1.4v-7.2c0-0.8-0.6-1.4-1.4-1.4
			S703.5,320.9,703.5,321.7z"
        />
        <path
          class="st0"
          d="M791.8,321.7v7.2l1.4-1.4h-51.9l1.4,1.4v-7.2l-1.4,1.4h51.9c0.8-0.1,1.4-0.7,1.3-1.5
			c-0.1-0.7-0.6-1.2-1.3-1.3h-51.9c-0.8,0-1.4,0.6-1.4,1.4v7.2c0,0.8,0.6,1.4,1.4,1.4h51.9c0.8,0,1.4-0.6,1.4-1.4v-7.2
			c0-0.8-0.6-1.4-1.4-1.4C792.4,320.3,791.8,320.9,791.8,321.7z"
        />
        <path
          class="st0"
          d="M687.6,232.3V247l1.4-1.4h-27.2l1.4,1.4v-14.7l-1.4,1.4H689c0.8-0.1,1.4-0.7,1.3-1.5
			c-0.1-0.7-0.6-1.2-1.3-1.3h-27.2c-0.8,0-1.4,0.6-1.4,1.4V247c0,0.8,0.6,1.4,1.4,1.4H689c0.8,0,1.4-0.6,1.4-1.4v-14.7
			c-0.1-0.8-0.7-1.4-1.5-1.3C688.2,231.1,687.7,231.7,687.6,232.3z"
        />
        <path
          class="st0"
          d="M757.4,250h27.2c0.8,0,1.4-0.6,1.4-1.4v-16.2c0-0.8-0.6-1.4-1.4-1.4h-27.2c-0.8,0-1.4,0.6-1.4,1.4v16.2
			C756.1,249.4,756.7,250,757.4,250c0.8-0.2,1.3-0.7,1.4-1.4v-16.2l-1.4,1.4h27.2l-1.4-1.4v16.2l1.4-1.4h-27.2
			c-0.8,0-1.4,0.6-1.4,1.4S756.6,250,757.4,250z"
        />
        <path
          class="st0"
          d="M792.9,256.4c0-6.8,0-13.6,0-20.4c0-7.6,0-15.2,0-22.8c0-0.1,0-0.1,0-0.2c0-0.8-0.6-1.4-1.4-1.4H655
			c-0.8,0-1.4,0.6-1.4,1.4c0,8.3,0,16.5,0,24.8c0,6.1,0,12.1,0,18.2c0.1,0.8,0.7,1.4,1.5,1.3c0.7-0.1,1.2-0.6,1.3-1.3
			c0-7.3,0-14.7,0-22c0-7,0-14,0-21l-1.4,1.4h136.5l-1.4-1.4c0,6.8,0,13.6,0,20.4c0,7.6,0,15.2,0,22.8c0,0.1,0,0.1,0,0.2
			c0.1,0.8,0.7,1.4,1.5,1.3C792.3,257.6,792.8,257,792.9,256.4L792.9,256.4z"
        />
        <path
          class="st0"
          d="M697.6,256v-30.1l-1.4,1.4h54l-1.4-1.4v30.4c0.1,0.8,0.7,1.4,1.5,1.3c0.7-0.1,1.2-0.6,1.3-1.3v-30.4
			c0-0.8-0.6-1.4-1.4-1.4h-54c-0.8,0-1.4,0.6-1.4,1.4V256c0.1,0.8,0.7,1.4,1.5,1.3C696.9,257.2,697.5,256.7,697.6,256L697.6,256z"
        />
        <path
          class="st0"
          d="M703.5,262.1h-51.8c-0.6,0-1.2-0.5-1.2-1.2v-4.6c0-0.6,0.5-1.2,1.2-1.2h51.8c0.6,0,1.2,0.5,1.2,1.2v4.6
			C704.6,261.6,704.1,262.1,703.5,262.1z M652.9,259.8h49.5v-2.3h-49.5V259.8z"
        />
        <path
          class="st0"
          d="M793.7,262.5h-51.8c-0.6,0-1.2-0.5-1.2-1.2v-4.6c0-0.6,0.5-1.2,1.2-1.2h51.8c0.6,0,1.2,0.5,1.2,1.2v4.6
			C794.9,261.9,794.4,262.5,793.7,262.5z M743.1,260.1h49.5v-2.3h-49.5V260.1z"
        />
        <path
          class="st0"
          d="M796.4,213.9H649.9c-0.6,0-1.2-0.5-1.2-1.2v-6c0-0.6,0.5-1.2,1.2-1.2h146.5c0.6,0,1.2,0.5,1.2,1.2v6
			C797.5,213.3,797,213.9,796.4,213.9z M651.1,211.5h144.1v-3.6H651.1V211.5z"
        />
        <path
          class="st0"
          d="M796.4,192.6H649.9c-0.6,0-1.2-0.5-1.2-1.2v-6.6c0-0.6,0.5-1.2,1.2-1.2h146.5c0.6,0,1.2,0.5,1.2,1.2v6.6
			C797.5,192.1,797,192.6,796.4,192.6z M651.1,190.3h144.1V186H651.1V190.3z"
        />
        <path
          class="st0"
          d="M2034.7,253.1c0-0.8,0.7-1.5,1.5-1.5l0,0h20.2v-37.2c0-0.8,0.7-1.5,1.5-1.5c0.1,0,0.1,0,0.2,0l71.7,7.7
			c0.8,0.1,1.4,0.7,1.4,1.5v4.8h9.1V212c0-0.8,0.7-1.5,1.5-1.5l0,0h32.8v-10c0-0.8,0.7-1.5,1.5-1.5l0,0h39c0.8,0,1.5,0.7,1.5,1.5
			l0,0v126.7c0,0.8-0.7,1.5-1.5,1.5l0,0h-14c-0.1,0-0.2,0-0.4,0H2175c-0.1,0-0.2,0-0.4,0h-5.9c-0.1,0-0.2,0-0.4,0h-110.4
			c-0.1,0-0.2,0-0.4,0h-10.1c-0.1,0-0.2,0-0.4,0h-10.9c-0.8,0-1.5-0.7-1.5-1.5l0,0L2034.7,253.1L2034.7,253.1z"
        />
        <rect x="2072.3" y="236.4" class="st1" width="21.2" height="15.2" />
        <path
          class="st2"
          d="M2115.2,235.1h51.6V230H2115v5.2L2115.2,235.1z M2113.5,226.9h14.6v-3.5l-68.7-7.4v35.5h9.8v-16.8
			c0-0.8,0.7-1.5,1.5-1.5l0,0h24.2c0.8,0,1.5,0.7,1.5,1.5l0,0v16.8h15.5v-23.2C2112,227.6,2112.7,226.9,2113.5,226.9L2113.5,226.9z
			 M2177.6,210.5h29.8c0.8,0,1.5,0.7,1.5,1.5l0,0v113.7h4.7V202h-36V210.5z M2115.2,243.4h51.6v-5.2h-51.6h-0.2v5.2H2115.2z
			 M2143.3,226.9h23.5v-13.4h-23.5V226.9z M2166.8,271.1h-35.6v5.2h35.6V271.1z M2166.8,279.3h-35.6v5.2h35.6V279.3z M2166.8,262.9
			h-35.6v5.2h35.6V262.9z M2166.8,254.7h-35.6v5.2h35.6V254.7z M2048.6,261.4c0,0.8-0.7,1.5-1.5,1.5l0,0h-9.4v5.2h9.4
			c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-9.4v5.2h9.4c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7
			c-0.2,0-0.3,0-0.5,0h-9.4v5.2h9.4c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-9.4v5.2h9.4
			c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-9.4v5.4h9.4c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7
			c-0.2,0-0.3,0-0.5,0h-9.4v5.2h9.4c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-9.4v5.2h9.4
			c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0h-9.4v5.1h90.4v-5.1h-24.2c-0.8,0.1-1.6-0.4-1.7-1.3
			s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h24.2v-5.2h-24.2c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h24.2V304h-24.2
			c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h24.2v-5.2h-24.2c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h24.2v-5.2h-24.2c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h24.2v-5.2h-24.2
			c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h24.2v-5.2h-24.2c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7
			c0.2,0,0.3,0,0.5,0h24.2V263h-24.2c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h24.2v-5.2h-90.4v5.2h9.4
			C2047.9,260,2048.5,260.6,2048.6,261.4L2048.6,261.4z M2056.4,319c0-0.8,0.7-1.5,1.5-1.5l0,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H2058C2057.1,320.5,2056.5,319.8,2056.4,319L2056.4,319z M2056.4,310.7
			c0-0.8,0.7-1.5,1.5-1.5l0,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H2058
			C2057.1,312.2,2056.4,311.6,2056.4,310.7L2056.4,310.7z M2056.4,302.5c0-0.8,0.7-1.5,1.5-1.5l0,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H2058C2057.1,304,2056.4,303.3,2056.4,302.5L2056.4,302.5z M2056.4,294.3
			c0-0.8,0.7-1.5,1.5-1.5l0,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H2058
			C2057.1,295.8,2056.4,295.1,2056.4,294.3L2056.4,294.3z M2056.4,286c0-0.8,0.7-1.5,1.5-1.5l0,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H2058C2057.1,287.5,2056.4,286.9,2056.4,286L2056.4,286L2056.4,286z M2056.4,277.8
			c0-0.8,0.7-1.5,1.5-1.5l0,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H2058
			C2057.1,279.3,2056.4,278.6,2056.4,277.8L2056.4,277.8z M2056.4,269.6c0-0.8,0.7-1.5,1.5-1.5l0,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3
			s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H2058C2057.1,271.1,2056.4,270.4,2056.4,269.6L2056.4,269.6z M2056.4,261.4
			c0-0.8,0.7-1.5,1.5-1.5l0,0h25.7c0.8-0.1,1.6,0.4,1.7,1.3s-0.4,1.6-1.3,1.7c-0.2,0-0.3,0-0.5,0H2058
			C2057.1,262.8,2056.4,262.2,2056.4,261.4L2056.4,261.4z M2115.1,251.6h51.8v-5.2h-51.6h-0.2V251.6z M2166.8,312.3h-35.6v5.2h35.6
			V312.3z M2166.8,320.5h-35.6v5.1h35.6V320.5z M2205.8,213.5h-36v112.1h36V213.5z M2202.2,220.2c0,0.8-0.7,1.5-1.5,1.5l0,0H2175
			c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7C2201.6,218.7,2202.2,219.4,2202.2,220.2L2202.2,220.2
			z M2202.2,228.4c0,0.8-0.7,1.5-1.5,1.5l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7
			C2201.6,227,2202.2,227.6,2202.2,228.4L2202.2,228.4z M2202.2,236.7c0,0.8-0.7,1.5-1.5,1.5l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7C2201.6,235.2,2202.2,235.8,2202.2,236.7L2202.2,236.7z M2202.2,244.9
			c0,0.8-0.7,1.5-1.5,1.5l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7
			C2201.6,243.4,2202.2,244.1,2202.2,244.9L2202.2,244.9z M2202.2,253.1c0,0.8-0.7,1.5-1.5,1.5l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3
			c-0.1-0.8,0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7C2201.6,251.7,2202.2,252.3,2202.2,253.1L2202.2,253.1z M2202.2,261.3
			c0,0.8-0.7,1.5-1.5,1.5l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7
			C2201.6,259.9,2202.2,260.5,2202.2,261.3L2202.2,261.3z M2202.2,269.6c0,0.8-0.7,1.5-1.5,1.5l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3
			s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7C2201.6,268.1,2202.2,268.8,2202.2,269.6L2202.2,269.6z M2202.2,277.8
			c0,0.8-0.7,1.5-1.5,1.5l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7
			C2201.6,276.3,2202.2,277,2202.2,277.8L2202.2,277.8z M2202.2,286c0,0.8-0.7,1.5-1.5,1.5l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3
			s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7C2201.5,284.6,2202.2,285.2,2202.2,286L2202.2,286z M2202.2,294.3c0,0.8-0.7,1.5-1.5,1.5
			l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7C2201.6,292.8,2202.2,293.5,2202.2,294.3L2202.2,294.3
			z M2202.2,302.5c0,0.8-0.7,1.5-1.5,1.5l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7
			C2201.6,301,2202.2,301.7,2202.2,302.5L2202.2,302.5z M2202.2,310.7c0,0.8-0.7,1.5-1.5,1.5l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3
			s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7C2201.6,309.3,2202.2,309.9,2202.2,310.7L2202.2,310.7z M2202.2,319
			c0,0.8-0.7,1.5-1.5,1.5l0,0H2175c-0.8,0.1-1.6-0.4-1.7-1.3s0.4-1.6,1.3-1.7c0.2,0,0.3,0,0.5,0h25.7
			C2201.6,317.5,2202.2,318.2,2202.2,319L2202.2,319z M2166.8,304h-35.6v5.2h35.6V304z M2166.8,295.8h-35.6v5.2h35.6V295.8z
			 M2166.8,287.5h-35.6v5.2h35.6V287.5z"
        />
        <path
          class="st0"
          d="M2202.2,269.6c0-0.8-0.7-1.5-1.5-1.5l0,0H2175c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0
			h25.7C2201.5,271.1,2202.2,270.4,2202.2,269.6z M2202.2,261.4c0-0.8-0.7-1.5-1.5-1.5l0,0H2175c-0.8-0.1-1.6,0.4-1.7,1.3
			s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2201.5,262.8,2202.2,262.2,2202.2,261.4z M2202.2,253.1c0-0.8-0.7-1.5-1.5-1.5l0,0H2175
			c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2201.5,254.6,2202.2,253.9,2202.2,253.1L2202.2,253.1
			z M2202.2,244.9c0-0.8-0.7-1.5-1.5-1.5l0,0H2175c-0.8-0.1-1.6,0.4-1.7,1.3c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7
			C2201.5,246.4,2202.2,245.7,2202.2,244.9z M2202.2,277.8c0-0.8-0.7-1.5-1.5-1.5l0,0H2175c-0.8-0.1-1.6,0.4-1.7,1.3
			s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2201.5,279.3,2202.2,278.6,2202.2,277.8z M2202.2,319c0-0.8-0.7-1.5-1.5-1.5l0,0H2175
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2201.5,320.4,2202.2,319.8,2202.2,319L2202.2,319z
			 M2202.2,310.7c0-0.8-0.7-1.5-1.5-1.5l0,0H2175c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7
			C2201.5,312.2,2202.2,311.6,2202.2,310.7z M2202.2,236.7c0-0.8-0.7-1.5-1.5-1.5l0,0H2175c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2201.6,238.1,2202.2,237.5,2202.2,236.7L2202.2,236.7z M2202.2,302.5
			c0-0.8-0.7-1.5-1.5-1.5l0,0H2175c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7
			C2201.5,304,2202.2,303.3,2202.2,302.5z M2202.2,294.3c0-0.8-0.7-1.5-1.5-1.5l0,0H2175c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h25.7C2201.5,295.7,2202.2,295.1,2202.2,294.3z M2202.2,286c0-0.8-0.7-1.5-1.5-1.5l0,0H2175
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2201.6,287.5,2202.2,286.8,2202.2,286L2202.2,286z
			 M2085.2,302.5c0-0.8-0.7-1.5-1.5-1.5l0,0H2058c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7
			C2084.5,304,2085.2,303.3,2085.2,302.5L2085.2,302.5z M2202.2,228.4c0-0.8-0.7-1.5-1.5-1.5l0,0H2175c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2201.6,229.9,2202.2,229.3,2202.2,228.4L2202.2,228.4z M2085.2,310.7
			c0-0.8-0.7-1.5-1.5-1.5l0,0H2058c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7
			C2084.5,312.2,2085.2,311.6,2085.2,310.7L2085.2,310.7z M2085.2,319c0-0.8-0.7-1.5-1.5-1.5l0,0H2058c-0.8-0.1-1.6,0.4-1.7,1.3
			s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2084.5,320.4,2085.2,319.8,2085.2,319L2085.2,319z M2085.2,286c0-0.8-0.7-1.5-1.5-1.5
			l0,0H2058c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2084.5,287.5,2085.2,286.8,2085.2,286L2085.2,286z
			 M2085.2,294.3c0-0.8-0.7-1.5-1.5-1.5l0,0H2058c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7
			C2084.5,295.7,2085.2,295.1,2085.2,294.3L2085.2,294.3z M2085.2,277.8c0-0.8-0.7-1.5-1.5-1.5l0,0H2058c-0.8-0.1-1.6,0.4-1.7,1.3
			s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2084.5,279.3,2085.2,278.6,2085.2,277.8L2085.2,277.8z M2034.7,327.2L2034.7,327.2
			c0,0.8,0.7,1.5,1.5,1.5l0,0h10.9c0.1,0,0.2,0,0.4,0h10.1c0.1,0,0.2,0,0.4,0h110.4c0.1,0,0.2,0,0.4,0h5.9c0.1,0,0.2,0,0.4,0h25.7
			c0.1,0,0.2,0,0.4,0h14c0.8,0,1.5-0.7,1.5-1.5l0,0V200.4c0-0.8-0.7-1.5-1.5-1.5l0,0h-39c-0.8,0-1.5,0.7-1.5,1.5l0,0v10h-32.8
			c-0.8,0-1.5,0.7-1.5,1.5l0,0v14.9h-9.1V222c0-0.8-0.6-1.4-1.4-1.5l-71.7-7.7c-0.8-0.1-1.6,0.5-1.7,1.3c0,0.1,0,0.1,0,0.2v37.2
			h-20.2c-0.8,0-1.5,0.7-1.5,1.5l0,0L2034.7,327.2z M2207.4,210.5h-29.8V202h36v123.6h-4.7V212
			C2208.9,211.2,2208.3,210.5,2207.4,210.5L2207.4,210.5L2207.4,210.5z M2169.9,213.5h36v112.2h-36V213.5z M2128.2,226.9h-14.6
			c-0.8,0-1.5,0.7-1.5,1.5l0,0v23.2h-15.4v-16.7c0-0.8-0.7-1.5-1.5-1.5l0,0H2071c-0.8,0-1.5,0.7-1.5,1.5l0,0v16.8h-9.8v-35.5
			l68.7,7.4L2128.2,226.9z M2093.5,236.3v15.2h-21.2v-15.2H2093.5z M2166.8,226.9h-23.5v-13.4h23.5V226.9z M2166.8,235.1h-51.6h-0.2
			V230h51.8V235.1z M2166.8,243.4h-51.6h-0.2v-5.2h0.2h51.6V243.4z M2166.8,251.6H2115v-5.2h0.2h51.6V251.6z M2131.2,320.5h35.6v5.1
			h-35.6V320.5z M2131.2,312.2h35.6v5.2h-35.6V312.2z M2131.2,304h35.6v5.2h-35.6V304z M2131.2,295.8h35.6v5.2h-35.6V295.8z
			 M2131.2,287.5h35.6v5.2h-35.6V287.5z M2131.2,279.3h35.6v5.2h-35.6V279.3z M2131.2,271.1h35.6v5.2h-35.6V271.1z M2131.2,262.8
			h35.6v5.2h-35.6V262.8z M2131.2,254.6h35.6v5.2h-35.6V254.6z M2037.7,254.6h90.4v5.2H2104c-0.8-0.1-1.6,0.4-1.7,1.3
			s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h24.2v5.2H2104c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h24.2v5.2H2104
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h24.2v5.2H2104c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h24.2v5.2H2104c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h24.2v5.4H2104
			c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h24.2v5.2H2104c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7
			c0.2,0,0.3,0,0.5,0h24.2v5.2H2104c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h24.2v5.1h-90.5v-5.1h9.4
			c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-9.4v-5.2h9.4c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-9.4V304h9.4c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-9.4v-5.2h9.4
			c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-9.4v-5.2h9.4c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-9.4v-5.2h9.4c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-9.4v-5.2h9.4
			c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7c-0.2,0-0.3,0-0.5,0h-9.4V263h9.4c0.8,0.1,1.6-0.4,1.7-1.3s-0.4-1.6-1.3-1.7
			c-0.2,0-0.3,0-0.5,0h-9.4L2037.7,254.6z M2202.3,220.2c0-0.8-0.7-1.5-1.5-1.5l0,0H2175c-0.8-0.1-1.6,0.4-1.7,1.3
			c-0.1,0.8,0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2201.5,221.7,2202.2,221,2202.3,220.2L2202.3,220.2z M2085.3,269.6
			c0-0.8-0.7-1.5-1.5-1.5l0,0H2058c-0.8-0.1-1.6,0.4-1.7,1.3s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7
			C2084.5,271,2085.2,270.4,2085.3,269.6L2085.3,269.6z M2085.3,261.3c0-0.8-0.7-1.5-1.5-1.5l0,0H2058c-0.8-0.1-1.6,0.4-1.7,1.3
			s0.4,1.6,1.3,1.7c0.2,0,0.3,0,0.5,0h25.7C2084.5,262.8,2085.2,262.1,2085.3,261.3L2085.3,261.3z"
        />
        <path
          class="st0"
          d="M1363.8,181c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.2l5.9,0.3c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3
			L1363.8,181L1363.8,181z"
        />
        <path
          class="st0"
          d="M1363.8,195.1c-0.1,0-0.2-0.2-0.2-0.3s0.2-0.2,0.3-0.2l5.9,0.3c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3l0,0
			L1363.8,195.1L1363.8,195.1z"
        />
        <path
          class="st0"
          d="M1364.4,183.9c-0.1,0-0.2-0.2-0.2-0.3s0.1-0.1,0.2-0.2l5.3-2.6c0.1-0.1,0.3,0,0.4,0.1s0,0.3-0.1,0.4l-5.3,2.6
			C1364.5,183.9,1364.4,184,1364.4,183.9z"
        />
        <path
          class="st0"
          d="M1364.4,198c-0.1,0-0.2-0.2-0.2-0.3s0.1-0.1,0.2-0.2l5.3-2.6c0.1-0.1,0.3,0,0.4,0.1s0,0.3-0.1,0.4l0,0
			l-5.3,2.6C1364.6,198,1364.5,198,1364.4,198z"
        />
        <path
          class="st0"
          d="M1366.5,212.7c-0.3-0.1-0.6-0.4-0.6-0.8l-0.7-34c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8l0,0l0.7,34
			c0,0.5-0.4,0.8-0.8,0.8C1366.7,212.8,1366.6,212.8,1366.5,212.7L1366.5,212.7z"
        />
        <ellipse class="st0" cx="1365.8" cy="175.6" rx="3" ry="3" />
        <path
          class="st0"
          d="M960.1,99.3c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.2L966,99c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3
			L960.1,99.3L960.1,99.3z"
        />
        <path
          class="st0"
          d="M960.2,113.4c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0.2-0.2,0.3-0.2l5.9,0.3c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3
			L960.2,113.4L960.2,113.4z"
        />
        <path
          class="st0"
          d="M960.7,102.3c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0.1-0.1,0.2-0.2l5.3-2.6c0.1-0.1,0.3,0,0.4,0.1
			c0.1,0.1,0,0.3-0.1,0.4l-5.3,2.6C960.9,102.3,960.8,102.3,960.7,102.3z"
        />
        <path
          class="st0"
          d="M960.8,116.4c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0.1-0.1,0.2-0.2l5.3-2.6c0.1-0.1,0.3,0,0.4,0.1
			c0.1,0.1,0,0.3-0.1,0.4l0,0l-5.3,2.6C960.9,116.4,960.9,116.4,960.8,116.4z"
        />
        <path
          class="st0"
          d="M962.9,131.1c-0.3-0.1-0.6-0.4-0.6-0.8l-0.7-34c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8l0.7,34
			c0,0.5-0.4,0.8-0.8,0.8C963,131.2,963,131.1,962.9,131.1L962.9,131.1z"
        />
        <ellipse class="st0" cx="962" cy="94.1" rx="3" ry="3" />
        <path
          class="st0"
          d="M1740.2,168.2c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.2l5.9,0.3c0.2,0,0.3,0.1,0.3,0.3
			s-0.1,0.3-0.3,0.3L1740.2,168.2L1740.2,168.2z"
        />
        <path
          class="st0"
          d="M1740.3,182.3c-0.1,0-0.2-0.2-0.2-0.3s0.2-0.2,0.3-0.2l0,0l5.9,0.3c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3
			l0,0L1740.3,182.3L1740.3,182.3z"
        />
        <path
          class="st0"
          d="M1740.8,171.1c-0.1,0-0.2-0.2-0.2-0.3s0.1-0.1,0.2-0.2l5.3-2.6c0.1-0.1,0.3,0,0.4,0.1s0,0.3-0.1,0.4l-5.4,2.6
			C1741,171.1,1740.9,171.1,1740.8,171.1z"
        />
        <path
          class="st0"
          d="M1740.9,185.2c-0.1,0-0.2-0.2-0.2-0.3s0.1-0.1,0.2-0.2l5.3-2.6c0.1-0.1,0.3,0,0.4,0.1s0,0.3-0.1,0.4l0,0
			l-5.3,2.6C1741.1,185.2,1741,185.2,1740.9,185.2z"
        />
        <path
          class="st0"
          d="M1743,199.9c-0.3-0.1-0.6-0.4-0.6-0.8l-0.7-34c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8l0.7,34
			c0,0.5-0.4,0.8-0.8,0.8C1743.2,200,1743.1,200,1743,199.9L1743,199.9z"
        />
        <ellipse class="st0" cx="1742.3" cy="162.9" rx="3" ry="3" />
        <path
          class="st0"
          d="M2009.9,124.9c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.2l5.9,0.3c0.2,0,0.3,0.1,0.3,0.3
			s-0.1,0.3-0.3,0.3L2009.9,124.9L2009.9,124.9z"
        />
        <path
          class="st0"
          d="M2010,139c-0.1,0-0.2-0.2-0.2-0.3s0.2-0.2,0.3-0.2l0,0l5.9,0.3c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3
			L2010,139L2010,139z"
        />
        <path
          class="st0"
          d="M2010.6,127.8c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0.1-0.1,0.2-0.2l5.3-2.6c0.1-0.1,0.3,0,0.4,0.1s0,0.3-0.1,0.4
			l0,0l-5.3,2.6C2010.7,127.9,2010.6,127.9,2010.6,127.8z"
        />
        <path
          class="st0"
          d="M2010.6,141.9c-0.1,0-0.2-0.2-0.2-0.3s0.1-0.1,0.2-0.2l5.3-2.6c0.1-0.1,0.3,0,0.4,0.1s0,0.3-0.1,0.4l0,0
			l-5.3,2.6C2010.8,141.9,2010.7,142,2010.6,141.9z"
        />
        <path
          class="st0"
          d="M2012.7,156.6c-0.3-0.1-0.6-0.4-0.6-0.8l-0.7-34c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8l0.7,34
			c0,0.5-0.4,0.8-0.8,0.8C2012.9,156.7,2012.8,156.7,2012.7,156.6L2012.7,156.6z"
        />
        <ellipse class="st0" cx="2011.9" cy="119.6" rx="3" ry="3" />
        <path
          class="st0"
          d="M585.3,170.1c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.2l5.9,0.3c0.2,0,0.3,0.1,0.3,0.3
			s-0.1,0.3-0.3,0.3L585.3,170.1L585.3,170.1z"
        />
        <path
          class="st0"
          d="M585.4,184.2c-0.1,0-0.2-0.2-0.2-0.3s0.2-0.2,0.3-0.2l0,0l5.9,0.3c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3
			L585.4,184.2L585.4,184.2z"
        />
        <path
          class="st0"
          d="M585.9,173.1c-0.1,0-0.2-0.2-0.2-0.3s0.1-0.1,0.2-0.2l5.3-2.6c0.1-0.1,0.3,0,0.4,0.1c0.1,0.1,0,0.3-0.1,0.4
			l0,0l-5.3,2.6C586.1,173.1,586,173.1,585.9,173.1z"
        />
        <path
          class="st0"
          d="M586,187.2c-0.1,0-0.2-0.2-0.2-0.3s0.1-0.1,0.2-0.2l5.3-2.6c0.1-0.1,0.3,0,0.4,0.1c0.1,0.1,0,0.3-0.1,0.4
			l-5.3,2.6C586.2,187.2,586.1,187.2,586,187.2z"
        />
        <path
          class="st0"
          d="M588.1,201.9c-0.3-0.1-0.6-0.4-0.6-0.8l-0.7-34c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8l0.7,34
			c0,0.5-0.4,0.8-0.8,0.8C588.2,202,588.2,202,588.1,201.9L588.1,201.9z"
        />
        <ellipse class="st0" cx="587.4" cy="164.9" rx="3" ry="3" />
        <path
          class="st0"
          d="M2362.6,209.2h41.2c0.8,0,1.5,0.7,1.5,1.5l0,0v9.2h43.3c0.8,0,1.5,0.7,1.5,1.5l0,0v78.9h14.4
			c0.8,0,1.5,0.7,1.5,1.5l0,0v26.6c0,0.8-0.7,1.5-1.5,1.5l0,0h-101.9c-0.8,0-1.5-0.7-1.5-1.5l0,0V210.7
			C2361.1,209.9,2361.7,209.2,2362.6,209.2L2362.6,209.2z"
        />
        <path
          class="st2"
          d="M2394,212.2h-5.2v88.2h5.2V212.2z M2402.2,212.2h-5.2v88.2h5.2V212.2z M2424.5,230.5h22.5V223h-22.6
			L2424.5,230.5z M2428.8,296.8H2410v3.6h18.8V296.8z M2385.8,212.2h-5.2v88.2h5.2V212.2z M2364.1,212.2V327h5.1V212.2H2364.1z
			 M2377.5,212.2h-5.2V327h2.4v-25.1c0-0.8,0.7-1.5,1.5-1.5l0,0h1.3V212.2z"
        />
        <path
          class="st1"
          d="M2408.5,293.8h12.9V223h-16.1v77.4h1.7v-5.1C2406.9,294.5,2407.6,293.8,2408.5,293.8
			C2408.4,293.8,2408.4,293.8,2408.5,293.8z M2377.7,303.4V327h85.2v-23.6H2377.7z M2431.8,300.4h15.2v-66.9h-22.6v60.2h5.9
			c0.8,0,1.5,0.7,1.5,1.5l0,0L2431.8,300.4z"
        />
        <path
          class="st0"
          d="M2361,210.7v117.8c0,0.8,0.7,1.5,1.5,1.5l0,0h101.9c0.8,0,1.5-0.7,1.5-1.5l0,0v-26.6c0-0.8-0.7-1.5-1.5-1.5
			l0,0H2450v-78.9c0-0.8-0.7-1.5-1.5-1.5l0,0h-43.3v-9.2c0-0.8-0.7-1.5-1.5-1.5l0,0h-41.2C2361.7,209.2,2361,209.9,2361,210.7
			L2361,210.7z M2385.7,300.4h-5.2v-88.2h5.2V300.4z M2393.9,300.4h-5.2v-88.2h5.2V300.4z M2402.2,300.4h-5.2v-88.2h5.2V300.4z
			 M2421.4,293.8h-12.9c-0.8,0-1.5,0.7-1.5,1.5l0,0v5.1h-1.7V223h16.1L2421.4,293.8z M2428.8,296.8v3.6H2410v-3.6H2428.8z
			 M2447,230.5h-22.6V223h22.6V230.5z M2447,300.4h-15.2v-5.1c0-0.8-0.7-1.5-1.5-1.5l0,0h-5.9v-60.2h22.6L2447,300.4L2447,300.4z
			 M2377.7,303.4h85.2V327h-85.2V303.4z M2372.2,212.2h5.2v88.2h-1.3c-0.8,0-1.5,0.7-1.5,1.5l0,0V327h-2.4V212.2z M2364.1,212.2h5.1
			V327h-5.1V212.2z"
        />
        <path
          class="st0"
          d="M150.1,280.1c0-0.6-0.4-1-1-1h-13.3v-24.4c0-0.6-0.4-1-1-1h-0.1l-47,5.1c-0.5,0.1-0.9,0.5-0.9,1v3.2h-6v-9.8
			c0-0.6-0.4-1-1-1H58.4v-6.6c0-0.6-0.4-1-1-1h-9.3v-34.4c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1v34.4h-24v-15.2c0-0.6-0.4-1-1-1
			s-1,0.4-1,1v15.2H1c-0.6,0-1,0.4-1,1v83.1c0,0.6,0.4,1,1,1h40c0.1,0,0.2,0,0.2,0h16.9c0.1,0,0.2,0,0.2,0h3.9c0.1,0,0.2,0,0.2,0
			h72.4c0.1,0,0.2,0,0.2,0h6.6c0.1,0,0.2,0,0.2,0h7.2c0.6,0,1-0.4,1-1L150.1,280.1L150.1,280.1z"
        />
        <path
          class="st0"
          d="M165.9,209.2h-41.2c-0.8,0-1.5,0.7-1.5,1.5l0,0v9.2H79.9c-0.8,0-1.5,0.7-1.5,1.5l0,0v78.9H64
			c-0.8,0-1.5,0.7-1.5,1.5v26.6c0,0.8,0.7,1.5,1.5,1.5h101.9c0.8,0,1.5-0.7,1.5-1.5V210.7C167.4,209.9,166.7,209.2,165.9,209.2
			L165.9,209.2z"
        />
        <path
          class="st2"
          d="M134.5,300.4h5.2v-88.2h-5.2V300.4z M126.2,300.4h5.2v-88.2h-5.2V300.4z M104,223H81.4v7.5H104V223z
			 M99.7,300.4h18.8v-3.6H99.7V300.4z M142.7,300.4h5.2v-88.2h-5.2V300.4z M159.2,212.3V327h5.1V212.2L159.2,212.3z M151,300.4h1.3
			c0.8,0,1.5,0.7,1.5,1.5V327h2.4V212.2H151V300.4z"
        />
        <path
          class="st1"
          d="M121.6,295.3v5.1h1.7V223h-16.1v70.8H120C120.8,293.8,121.5,294.4,121.6,295.3
			C121.5,295.3,121.5,295.3,121.6,295.3z M65.6,303.4V327h85.2v-23.6H65.6z M96.6,295.3c0-0.8,0.7-1.5,1.5-1.5h5.8v-60.2H81.4v66.8
			h15.2V295.3z"
        />
        <path
          class="st0"
          d="M165.9,209.2h-41.2c-0.8,0-1.5,0.7-1.5,1.5l0,0v9.2H79.9c-0.8,0-1.5,0.7-1.5,1.5l0,0v78.9H64
			c-0.8,0-1.5,0.7-1.5,1.5v26.6c0,0.8,0.7,1.5,1.5,1.5h101.9c0.8,0,1.5-0.7,1.5-1.5V210.7C167.4,209.9,166.7,209.2,165.9,209.2
			L165.9,209.2z M142.7,212.2h5.2v88.2h-5.2V212.2z M134.5,212.2h5.2v88.2h-5.2V212.2z M126.2,212.2h5.2v88.2h-5.2V212.2z
			 M107.1,223h16.1v77.4h-1.7v-5.1c0-0.8-0.7-1.5-1.5-1.5h-12.9V223z M118.5,296.8v3.6H99.7v-3.6H118.5z M81.4,223H104v7.5H81.4V223
			z M81.4,233.6H104v60.2h-5.8c-0.8,0-1.5,0.7-1.5,1.5v5.1H81.4V233.6z M150.8,327H65.6v-23.6h85.2L150.8,327L150.8,327z M156.2,327
			h-2.4v-25.1c0-0.8-0.7-1.5-1.5-1.5H151v-88.2h5.2V327z M164.4,327h-5.1V212.2h5.1V327z"
        />
        <path
          class="st0"
          d="M2548.8,221.8h-10.3V164c0-0.7-0.6-1.3-1.3-1.3l0,0h-52.8c-0.7,0-1.3,0.6-1.3,1.3l0,0v19.3h-27
			c-0.7,0-1.3,0.6-1.3,1.3l0,0v144.2c0,0.7,0.6,1.3,1.3,1.3l0,0h27.8c0.2,0.1,0.3,0.1,0.5,0.1h22.2c0.1,0,0.3,0,0.4-0.1h22.6
			c0.1,0,0.2,0,0.3,0h18.9c0.7,0,1.3-0.6,1.3-1.3l0,0V223C2550.1,222.3,2549.5,221.8,2548.8,221.8z"
        />
        <path
          class="st2"
          d="M2528.4,282h-13.9v4.3h13.9V282z M2528.4,275.1h-13.9v4.3h13.9V275.1z M2528.4,261.4h-13.9v4.3h13.9V261.4z
			 M2528.4,254.5h-13.9v4.3h13.9V254.5z M2514.5,300h13.9v-4.3h-13.9V300z M2528.4,165.2h-42.6v8.1h42.6V165.2z M2536,165.2h-5V300
			h5V165.2z M2528.4,247.6h-13.9v4.4h13.9V247.6z M2507.2,183.2h21.2v-3.9h-42.6v3.8h20.9C2506.8,183.1,2507,183.2,2507.2,183.2
			L2507.2,183.2z M2528.4,288.8h-13.9v4.3h13.9V288.8z M2528.4,268.2h-13.9v4.3h13.9V268.2z M2512,185.8h-32.8V300h32.8V185.8z
			 M2506.7,295.8h-22.2c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3l0,0h22.2c0.7,0,1.3,0.6,1.3,1.3
			C2507.9,295.2,2507.4,295.8,2506.7,295.8z M2506.7,288.9h-22.2c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3l0,0h22.2
			c0.7,0,1.3,0.6,1.3,1.3C2507.9,288.4,2507.4,288.9,2506.7,288.9z M2506.7,282h-22.2c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
			l0,0h22.2c0.7,0,1.3,0.6,1.3,1.3C2507.9,281.5,2507.4,282,2506.7,282z M2506.7,275.2h-22.2c-0.7,0-1.3-0.6-1.3-1.3
			c0-0.7,0.6-1.3,1.3-1.3l0,0h22.2c0.7,0,1.3,0.6,1.3,1.3C2507.9,274.6,2507.4,275.2,2506.7,275.2z M2506.7,268.3h-22.2
			c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3l0,0h22.2c0.7,0,1.3,0.6,1.3,1.3C2507.9,267.7,2507.4,268.3,2506.7,268.3z
			 M2506.7,261.4h-22.2c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3l0,0h22.2c0.7,0,1.3,0.6,1.3,1.3
			C2507.9,260.8,2507.4,261.4,2506.7,261.4z M2506.7,254.5h-22.2c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3l0,0h22.2
			c0.7,0,1.3,0.6,1.3,1.3C2508,254,2507.4,254.5,2506.7,254.5L2506.7,254.5z M2506.7,247.6h-22.2c-0.7,0-1.3-0.6-1.3-1.3
			s0.6-1.3,1.3-1.3l0,0h22.2c0.7,0,1.3,0.6,1.3,1.3C2508,247.1,2507.4,247.6,2506.7,247.6L2506.7,247.6z M2483.7,240.9v-9.6
			c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v9.6c0,0.7-0.6,1.3-1.3,1.3S2483.7,241.6,2483.7,240.9z M2490.8,240.9v-9.6
			c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v9.6c0,0.7-0.6,1.3-1.3,1.3S2490.8,241.6,2490.8,240.9z M2497.8,240.9v-9.6
			c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v9.6c0,0.7-0.6,1.3-1.3,1.3S2497.8,241.6,2497.8,240.9z M2504.9,240.9v-9.6
			c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v9.6c0,0.7-0.6,1.3-1.3,1.3S2504.9,241.6,2504.9,240.9z M2506.6,227h-22.2
			c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3l0,0h22.2c0.7,0,1.3,0.6,1.3,1.3C2507.9,226.4,2507.3,227,2506.6,227L2506.6,227z
			 M2506.6,220.1h-22.2c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3l0,0h22.2c0.7,0,1.3,0.6,1.3,1.3
			C2507.9,219.6,2507.3,220.1,2506.6,220.1L2506.6,220.1z M2506.6,213.3h-22.2c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3l0,0h22.2
			c0.7,0,1.3,0.6,1.3,1.3C2507.9,212.7,2507.3,213.3,2506.6,213.3L2506.6,213.3z M2506.6,206.4h-22.2c-0.7,0-1.3-0.6-1.3-1.3
			s0.6-1.3,1.3-1.3l0,0h22.2c0.7,0,1.3,0.6,1.3,1.3C2507.9,205.8,2507.3,206.4,2506.6,206.4L2506.6,206.4z M2506.6,199.5h-22.2
			c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3l0,0h22.2c0.7,0,1.3,0.6,1.3,1.3C2507.9,198.9,2507.3,199.5,2506.6,199.5L2506.6,199.5z
			 M2506.6,192.6h-22.2c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3l0,0h22.2c0.7,0,1.3,0.6,1.3,1.3
			C2507.9,192,2507.3,192.6,2506.6,192.6L2506.6,192.6z M2528.4,192.7h-13.9v4.4h13.9V192.7z M2528.4,240.8h-13.9v4.3h13.9V240.8z
			 M2466.9,302.6v4.3h34.3c0.7,0,1.3,0.6,1.3,1.3l0,0v19.3h45v-25L2466.9,302.6z M2470.7,193v107h5.9V185.8h-19.1v5.9h12
			C2470.2,191.7,2470.7,192.3,2470.7,193L2470.7,193z M2528.4,199.6h-13.9v4.3h13.9V199.6z M2528.4,227.1h-13.9v4.3h13.9V227.1z
			 M2528.4,206.5h-13.9v4.3h13.9V206.5z M2528.4,234h-13.9v4.3h13.9V234z M2528.4,213.3h-13.9v4.3h13.9V213.3z M2528.4,220.2h-13.9
			v4.3h13.9V220.2z"
        />
        <path
          class="st1"
          d="M2538.5,224.3V300h9v-75.7H2538.5z M2464.4,301.3c0-0.7,0.6-1.3,1.3-1.3l0,0h2.5V194.2h-10.7v112.7h6.9V301.3
			z M2457.5,327.5h42.5v-18.1h-42.5V327.5z M2485.8,176.8h42.6v-0.9h-42.6V176.8z M2514.5,190.1h13.9v-4.3h-13.9V190.1z"
        />
        <path
          class="st0"
          d="M2548.8,221.8h-10.3V164c0-0.7-0.6-1.3-1.3-1.3l0,0h-52.8c-0.7,0-1.3,0.6-1.3,1.3l0,0v19.3h-27
			c-0.7,0-1.3,0.6-1.3,1.3l0,0v144.2c0,0.7,0.6,1.3,1.3,1.3l0,0h27.8c0.2,0.1,0.3,0.1,0.5,0.1h22.2c0.1,0,0.3,0,0.4-0.1h22.6
			c0.1,0,0.2,0,0.3,0h18.9c0.7,0,1.3-0.6,1.3-1.3l0,0V223C2550.1,222.3,2549.5,221.8,2548.8,221.8z M2530.9,165.2h5V300h-5V165.2z
			 M2485.8,165.2h42.6v8.1h-42.6V165.2z M2485.8,175.9h42.6v0.9h-42.6V175.9z M2485.8,179.3h42.6v3.9h-21.2
			c-0.2-0.1-0.3-0.1-0.5-0.1h-20.9L2485.8,179.3L2485.8,179.3z M2514.5,293.2v-4.3h13.9v4.3H2514.5z M2528.4,295.8v4.2h-13.9v-4.3
			L2528.4,295.8z M2514.5,286.3V282h13.9v4.3H2514.5z M2514.5,279.5v-4.3h13.9v4.3H2514.5z M2514.5,272.6v-4.3h13.9v4.3H2514.5z
			 M2514.5,265.7v-4.3h13.9v4.3H2514.5z M2514.5,258.9v-4.3h13.9v4.3H2514.5z M2514.5,252v-4.3h13.9v4.3H2514.5z M2514.5,245.1v-4.3
			h13.9v4.3H2514.5z M2514.5,238.3V234h13.9v4.3H2514.5z M2514.5,231.4v-4.3h13.9v4.3H2514.5z M2514.5,224.5v-4.3h13.9v4.3H2514.5z
			 M2514.5,217.6v-4.3h13.9v4.3H2514.5z M2514.5,210.8v-4.3h13.9v4.3H2514.5z M2514.5,203.9v-4.3h13.9v4.3H2514.5z M2514.5,197v-4.4
			h13.9v4.4H2514.5z M2514.5,190.1v-4.3h13.9v4.3H2514.5z M2479.2,185.8h32.8V300h-32.8V185.8z M2457.5,185.8h19.1V300h-5.9V192.9
			c0-0.7-0.6-1.3-1.3-1.3l0,0h-12L2457.5,185.8z M2457.5,194.2h10.7V300h-2.5c-0.7,0-1.3,0.6-1.3,1.3l0,0v5.6h-6.9V194.2z
			 M2500,327.5h-42.5v-18.1h42.5V327.5z M2547.6,327.5h-45v-19.3c0-0.7-0.6-1.3-1.3-1.3l0,0H2467v-4.3h80.6V327.5z M2547.6,300h-9
			v-75.7h9V300z M2506.7,224.4h-22.2c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3l0,0h22.2c0.7,0,1.3-0.6,1.3-1.3
			S2507.4,224.4,2506.7,224.4L2506.7,224.4z M2507.5,240.9v-9.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3l0,0v9.6
			c0,0.7,0.6,1.3,1.3,1.3S2507.5,241.6,2507.5,240.9z M2506.7,217.6h-22.2c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3l0,0h22.2
			c0.7,0,1.3-0.6,1.3-1.3C2508,218.1,2507.4,217.6,2506.7,217.6L2506.7,217.6z M2500.4,240.9v-9.6c0-0.7-0.6-1.3-1.3-1.3
			s-1.3,0.6-1.3,1.3l0,0v9.6c0,0.7,0.6,1.3,1.3,1.3S2500.4,241.6,2500.4,240.9z M2506.7,190h-22.2c-0.7,0-1.3,0.6-1.3,1.3
			s0.6,1.3,1.3,1.3l0,0h22.2c0.7,0,1.3-0.6,1.3-1.3S2507.4,190,2506.7,190L2506.7,190z M2493.3,240.9v-9.6c0-0.7-0.6-1.3-1.3-1.3
			s-1.3,0.6-1.3,1.3l0,0v9.6c0,0.7,0.6,1.3,1.3,1.3S2493.3,241.6,2493.3,240.9z M2506.7,210.7h-22.2c-0.7,0-1.3,0.6-1.3,1.3
			s0.6,1.3,1.3,1.3l0,0h22.2c0.7,0,1.3-0.6,1.3-1.3S2507.4,210.7,2506.7,210.7L2506.7,210.7z M2506.7,196.9h-22.2
			c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3l0,0h22.2c0.7,0,1.3-0.6,1.3-1.3S2507.4,196.9,2506.7,196.9L2506.7,196.9z M2506.7,203.8
			h-22.2c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3l0,0h22.2c0.7,0,1.3-0.6,1.3-1.3C2508,204.4,2507.4,203.8,2506.7,203.8
			L2506.7,203.8z M2506.7,265.7h-22.2c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h22.2c0.7,0,1.3-0.6,1.3-1.3
			C2508,266.3,2507.4,265.7,2506.7,265.7L2506.7,265.7z M2506.7,272.6h-22.2c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h22.2
			c0.7,0,1.3-0.6,1.3-1.3S2507.4,272.6,2506.7,272.6L2506.7,272.6z M2506.7,279.5h-22.2c-0.7,0-1.3,0.6-1.3,1.3
			c0,0.7,0.6,1.3,1.3,1.3h22.2c0.7,0,1.3-0.6,1.3-1.3C2508,280,2507.4,279.5,2506.7,279.5L2506.7,279.5z M2506.7,286.4h-22.2
			c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h22.2c0.7,0,1.3-0.6,1.3-1.3C2508,286.9,2507.4,286.4,2506.7,286.4L2506.7,286.4z
			 M2506.7,293.2h-22.2c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h22.2c0.7,0,1.3-0.6,1.3-1.3
			C2508,293.8,2507.4,293.2,2506.7,293.2L2506.7,293.2z M2506.7,245.1h-22.2c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3l0,0h22.2
			c0.7,0,1.3-0.6,1.3-1.3C2508,245.7,2507.4,245.1,2506.7,245.1L2506.7,245.1z M2486.3,240.9v-9.6c0-0.7-0.6-1.3-1.3-1.3
			s-1.3,0.6-1.3,1.3l0,0v9.6c0,0.7,0.6,1.3,1.3,1.3S2486.3,241.6,2486.3,240.9z M2506.6,252h-22.2c-0.7,0-1.3,0.6-1.3,1.3
			s0.6,1.3,1.3,1.3l0,0h22.2c0.7,0,1.3-0.6,1.3-1.3C2507.9,252.6,2507.4,252,2506.6,252C2506.7,252,2506.7,252,2506.6,252z
			 M2506.6,258.9h-22.2c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h22.2c0.7,0,1.3-0.6,1.3-1.3
			C2507.9,259.4,2507.4,258.9,2506.6,258.9L2506.6,258.9z"
        />
        <rect y="326.6" class="st0" width="2616.8" height="5.1" />
      </g>
    </g>
    <g id="Flag1">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <polygon
                      id="SVGID_1_"
                      points="591.5,189.6 658.5,189.8 642,177.6 658.8,165.5 591.5,165.3 								"
                    />
                  </defs>
                  <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                  </clipPath>
                  <g class="st6">
                    <path
                      id="flag2_1_"
                      class="st7"
                      d="M591.5,189.6c8.1,0,8.4-2.9,16.5-2.9c7.7,0,8,2.9,16.5,2.9c8.7,0,8.7-2.9,16.5-2.9
									c8.1,0,8.1,2.9,16.5,2.9c8.5,0,8.7-2.9,16.5-2.9c8.6,0,8.3,2.9,16.5,2.9c8.7,0,8.6-2.9,16.5-2.9s9,2.9,16.5,2.9l-0.1-22
									c0,0-7.8-2.4-16.5-2.4c-7.9,0-8.7,2.3-16.5,2.3s-7.4-2.4-16.5-2.4s-9.1,2.3-16.5,2.3c-8,0-8.1-2.4-16.5-2.4
									c-7.3,0-7.5,2.3-16.5,2.3c-8.7,0-8.9-2.4-16.5-2.4c-8.5,0-8.3,2.3-16.5,2.3L591.5,189.6L591.5,189.6z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Flag2">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <polygon
                      id="SVGID_3_"
                      points="965.9,118.6 1032.9,118.8 1016.4,106.6 1033.2,94.5 965.9,94.3 								"
                    />
                  </defs>
                  <clipPath id="SVGID_4_">
                    <use xlink:href="#SVGID_3_" style="overflow:visible;" />
                  </clipPath>
                  <g class="st8">
                    <path
                      id="flag2_5_"
                      class="st9"
                      d="M965.9,118.6c8.1,0,8.4-2.9,16.5-2.9c7.7,0,8,2.9,16.5,2.9c8.7,0,8.7-2.9,16.5-2.9
									c8.1,0,8.1,2.9,16.5,2.9c8.5,0,8.7-2.9,16.5-2.9c8.6,0,8.3,2.9,16.5,2.9c8.7,0,8.6-2.9,16.5-2.9s9,2.9,16.5,2.9l-0.1-22
									c0,0-7.8-2.4-16.5-2.4c-7.9,0-8.7,2.3-16.5,2.3s-7.4-2.4-16.5-2.4s-9.1,2.3-16.5,2.3c-8,0-8.1-2.4-16.5-2.4
									c-7.3,0-7.5,2.3-16.5,2.3c-8.7,0-8.9-2.4-16.5-2.4c-8.5,0-8.3,2.3-16.5,2.3L965.9,118.6L965.9,118.6z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Flag3">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <polygon
                      id="SVGID_5_"
                      points="1368.7,200.2 1435.7,200.4 1419.2,188.2 1436,176.1 1368.7,175.9 								"
                    />
                  </defs>
                  <clipPath id="SVGID_6_">
                    <use xlink:href="#SVGID_5_" style="overflow:visible;" />
                  </clipPath>
                  <g class="st10">
                    <path
                      id="flag2_2_"
                      class="st11"
                      d="M1368.7,200.2c8.1,0,8.4-2.9,16.5-2.9c7.7,0,8,2.9,16.5,2.9c8.7,0,8.7-2.9,16.5-2.9
									c8.1,0,8.1,2.9,16.5,2.9c8.5,0,8.7-2.9,16.5-2.9c8.6,0,8.3,2.9,16.5,2.9c8.7,0,8.6-2.9,16.5-2.9s9,2.9,16.5,2.9l-0.1-22
									c0,0-7.8-2.4-16.5-2.4c-7.9,0-8.7,2.3-16.5,2.3s-7.4-2.4-16.5-2.4s-9.1,2.3-16.5,2.3c-8,0-8.1-2.4-16.5-2.4
									c-7.3,0-7.5,2.3-16.5,2.3c-8.7,0-8.9-2.4-16.5-2.4c-8.5,0-8.3,2.3-16.5,2.3L1368.7,200.2L1368.7,200.2z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Flag4">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <polygon
                      id="SVGID_7_"
                      points="2015.2,144.5 2082.2,144.7 2065.7,132.6 2082.4,120.4 2015.1,120.3 								"
                    />
                  </defs>
                  <clipPath id="SVGID_8_">
                    <use xlink:href="#SVGID_7_" style="overflow:visible;" />
                  </clipPath>
                  <g class="st12">
                    <path
                      id="flag2_3_"
                      class="st13"
                      d="M2015.2,144.5c8.1,0,8.4-2.9,16.5-2.9c7.7,0,8,2.9,16.5,2.9c8.7,0,8.7-2.9,16.5-2.9
									c8.1,0,8.1,2.9,16.5,2.9c8.5,0,8.7-2.9,16.5-2.9c8.6,0,8.3,2.9,16.5,2.9c8.7,0,8.6-2.9,16.5-2.9s9,2.9,16.5,2.9l-0.1-22
									c0,0-7.8-2.4-16.5-2.4c-7.9,0-8.7,2.3-16.5,2.3s-7.4-2.4-16.5-2.4s-9.1,2.3-16.5,2.3c-8,0-8.1-2.4-16.5-2.4
									c-7.3,0-7.5,2.3-16.5,2.3c-8.7,0-8.9-2.4-16.5-2.4c-8.5,0-8.3,2.3-16.5,2.3L2015.2,144.5L2015.2,144.5z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Flag5">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <defs>
                    <polygon
                      id="SVGID_9_"
                      points="1745.3,187.5 1812.3,187.7 1795.8,175.5 1812.6,163.4 1745.3,163.2 								"
                    />
                  </defs>
                  <clipPath id="SVGID_10_">
                    <use xlink:href="#SVGID_9_" style="overflow:visible;" />
                  </clipPath>
                  <g class="st14">
                    <path
                      id="flag2_4_"
                      class="st13"
                      d="M1745.3,187.5c8.1,0,8.4-2.9,16.5-2.9c7.7,0,8,2.9,16.5,2.9c8.7,0,8.7-2.9,16.5-2.9
									c8.1,0,8.1,2.9,16.5,2.9c8.5,0,8.7-2.9,16.5-2.9c8.6,0,8.3,2.9,16.5,2.9c8.7,0,8.6-2.9,16.5-2.9s9,2.9,16.5,2.9l-0.1-22
									c0,0-7.8-2.4-16.5-2.4c-7.9,0-8.7,2.3-16.5,2.3s-7.4-2.4-16.5-2.4s-9.1,2.3-16.5,2.3c-8,0-8.1-2.4-16.5-2.4
									c-7.3,0-7.5,2.3-16.5,2.3c-8.7,0-8.9-2.4-16.5-2.4c-8.5,0-8.3,2.3-16.5,2.3L1745.3,187.5L1745.3,187.5z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import anime from "animejs";

export default {
  mounted() {
    anime({
      targets: ["#flag2_1_", "#flag2_2_", "#flag2_3_", "#flag2_4_", "#flag2_5_"],
      keyframes: [{ translateX: -65 }],
      duration: 1500,
      easing: "linear",
      direction: "normal",
      loop: true
    });
  }
};
</script>

<style type="text/css">
.st0 {
  fill: #4285f4;
}
.st1 {
  fill: #4285f4;
}
.st2 {
  fill: #f5f5f5;
}
.st3 {
  fill: #f4f5f5;
}
.st4 {
  fill: #ffffff;
}
.st5 {
  fill: #4285f4;
  stroke: #4285f4;
  stroke-miterlimit: 10;
}
.st6 {
  clip-path: url(#SVGID_2_);
}
.st7 {
  fill: #f7b633;
}
.st8 {
  clip-path: url(#SVGID_4_);
}
.st9 {
  fill: #d13e42;
}
.st10 {
  clip-path: url(#SVGID_6_);
}
.st11 {
  fill: #e7773e;
}
.st12 {
  clip-path: url(#SVGID_8_);
}
.st13 {
  fill: #4285f4;
}
.st14 {
  clip-path: url(#SVGID_10_);
}
</style>
